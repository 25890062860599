import { useNavigate, useParams } from 'react-router-dom';
import { DetailTable, Loader } from '../../components';
import { formatTimestamp } from '../../assets/constants';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { selectTemplate, removeTemplate } from '../../store/templateSlice';
import { useState } from 'react';
import { Modal, Button } from '@application-production-system/react';
import { ServiceWrapper } from '../../services/DocServiceWrapper';
import { DocumentService, TemplateDeleteForm, DocmosisResponse, TemplateTestForm, TemplateGetForm } from '../../api/doc';

import { testTemplate } from '../../api/override';
import { IdPageParams, ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';

function TemplateDetailsPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { id } = useParams<IdPageParams>();
    const template = useAppSelector((state) => selectTemplate(state, id));

    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);

    const clickDeleteTemplate = () => {
        setConfirmDeleteVisible(true);
    }

    const confirmDelete = () => {
        const request: TemplateDeleteForm = {
            templateName: template.name,
            isSystemTemplate: !!template.systemTemplate
        }
        ServiceWrapper<DocmosisResponse>(DocumentService.deleteTemplateV2, [request], (_) => {
            dispatch(removeTemplate(template.name));
            navigate(ROUTES.templates.build());
        })
    }

    const clickTestTemplate = () => {
        // const request: TemplateTestForm = {
        //     templateName: template.name,
        //     systemTemplate: !!template.systemTemplate,
        // };
        testTemplate(template.name!, !!template.systemTemplate)
        .then((response) => {
            const fileName = template.name!
                .replace(/\.docx?$/, "")
                .replaceAll(" ", "_")
                + "_error_report.pdf";
            downloadBlob(fileName, new Blob([response.data]));
        })
        .catch((reason) => {
            console.error(reason);
        });
    }

    const downloadBlob = (fileName: string, blob: Blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }

    const downloadAuthURL = (fileName: string, url: string) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }

    const clickDownloadTemplate = () => {
        const request: TemplateGetForm = {
            templateName: template.name!,
            isSystemTemplate: template.systemTemplate,
        };
        DocumentService.downloadTemplateUrlv2(template.name!, !!template.systemTemplate)
        .then((response) => {
            if (!!response.authenticatedURL) {
                downloadAuthURL(template.name!, response.authenticatedURL);
            }
        })
        .catch((reason) => {
            console.error(reason);
        });
    }

    return !template ? <Loader/> : (
        <Page title={{page: "Template Details", icon: ['far', 'page']}}>
            <div>
                <Modal
                    title="Confirm Delete"
                    status="danger"
                    visible={confirmDeleteVisible}
                    actions={["Delete", "Cancel"]}
                    actionReducer={action => {action === "Delete" && confirmDelete()}}
                    onClose={() => setConfirmDeleteVisible(false)}
                >
                    Are you sure you want to delete {template.name}?
                </Modal>

                <div style={{maxWidth: "800px"}}>
                    <h2>Template Details</h2>
                    <DetailTable details={{
                        "Name": template.name,
                        "Description": template.templateDescription,
                        "Modification Date": formatTimestamp(template.lastModifiedISO8601!),
                        "Size": (template.sizeInBytes == undefined ? "Unknown" : template.sizeInBytes + " bytes"),
                        "System Template": template.systemTemplate ? "Yes" : "No",
                        "Dev Mode": template.templateDevMode ? "Yes" : "No",
                        "MD5": template.md5,
                        "Status": ((template.templateHasErrors as unknown as string) === "true")
                            ? <span style={{color: "red"}}>Has errors</span>
                            : <span style={{color: "green"}}>Valid template</span>,
                        "Field Prefix": template.templatePlainTextFieldPrefix,
                        "Field Suffix": template.templatePlainTextFieldSuffix,
                    }}/>
                    <div style={{display: "flex"}}>
                        <div style={{marginLeft: "auto", marginTop: "10px"}}>
                            <Button
                                title="Download"
                                icon={['far', 'cloud-download']}
                                iconPosition="left"
                                onClick={clickDownloadTemplate}
                            />
                        </div>
                        <div style={{marginLeft: "10px", marginTop: "10px"}}>
                            <Button
                                title="Generate Error Report"
                                icon={['far', 'cube']}
                                iconPosition="left"
                                onClick={clickTestTemplate}
                            />
                        </div>
                        <div style={{marginLeft: "10px", marginTop: "10px"}}>
                            <Button
                                title="Delete"
                                variant="danger"
                                icon={['far', 'times-circle']}
                                iconPosition="left"
                                onClick={clickDeleteTemplate}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
}

export default TemplateDetailsPage;
