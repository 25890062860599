/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateInspectionRequest = {
    data?: Record<string, {
        valueType?: 'ARRAY' | 'OBJECT' | 'STRING' | 'NUMBER' | 'TRUE' | 'FALSE' | 'NULL';
    }>;
    tags?: {
        valueType?: UpdateInspectionRequest.valueType;
    };
    sourceId?: string;
};

export namespace UpdateInspectionRequest {

    export enum valueType {
        ARRAY = 'ARRAY',
        OBJECT = 'OBJECT',
        STRING = 'STRING',
        NUMBER = 'NUMBER',
        TRUE = 'TRUE',
        FALSE = 'FALSE',
        NULL = 'NULL',
    }


}

