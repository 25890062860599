import { Icon } from "@application-production-system/react";
import styles from './OpenDrawerButton.module.scss';
import { AppDropdownNav } from "./AppDropdownNav";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectNavCollapsed, setNavCollapsed } from "../../../store/sessionSlice";
// import { useAppDispatch, AppActions } from "../../redux";

export function OpenDrawerButton() {
    const dispatch = useAppDispatch();

    const isNavCollapsed = useAppSelector(selectNavCollapsed);

    const toggleDropdown = () => {
        dispatch(setNavCollapsed(!isNavCollapsed));
    }

    return (
        <div>
            <Icon
                className={styles.burger_button}
                onClick={toggleDropdown}
                icon={['fas', 'bars']}
                color="var(--neutral-90)"
                size="lg"
            />
            <AppDropdownNav />
        </div>
    );
}
