/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RenderRequest = {
    templateName?: string;
    outputName?: string;
    outputFormat?: string;
    sourceId?: string;
    requestId?: string;
    storeTo?: string;
    mailBodyText?: string;
    mailSubject?: string;
    pdfWatermark?: string;
    areasToExclude?: Array<string>;
    devMode?: boolean;
    systemTemplate?: boolean;
    data?: Record<string, {
        valueType?: 'ARRAY' | 'OBJECT' | 'STRING' | 'NUMBER' | 'TRUE' | 'FALSE' | 'NULL';
    }>;
    tags?: {
        valueType?: RenderRequest.valueType;
    };
    mailNoZipAttachments?: boolean;
    stylesInText?: boolean;
};

export namespace RenderRequest {

    export enum valueType {
        ARRAY = 'ARRAY',
        OBJECT = 'OBJECT',
        STRING = 'STRING',
        NUMBER = 'NUMBER',
        TRUE = 'TRUE',
        FALSE = 'FALSE',
        NULL = 'NULL',
    }


}

