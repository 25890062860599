/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SendgridEvent = {
    email?: string;
    timestamp?: number;
    smtpId?: string;
    event?: string;
    useragent?: string;
    ip?: string;
    category?: {
        valueType?: SendgridEvent.valueType;
    };
    sgEventId?: string;
    sgMessageId?: string;
    reason?: string;
    status?: string;
    response?: string;
    attempt?: string;
    tls?: string;
    url?: string;
    urlOffset?: string;
    type?: string;
    sgMachineOpen?: boolean;
    bounceClassification?: string;
    asmGroupId?: number;
    uniqueArgs?: Record<string, {
        valueType?: 'ARRAY' | 'OBJECT' | 'STRING' | 'NUMBER' | 'TRUE' | 'FALSE' | 'NULL';
    }>;
};

export namespace SendgridEvent {

    export enum valueType {
        ARRAY = 'ARRAY',
        OBJECT = 'OBJECT',
        STRING = 'STRING',
        NUMBER = 'NUMBER',
        TRUE = 'TRUE',
        FALSE = 'FALSE',
        NULL = 'NULL',
    }


}

