import { AuditLogEntry } from '../../api/doc';

export interface CachedEntry {
    entry: AuditLogEntry;
    formattedDate: string;
}

export const sortByOptions = {
    "timestamp": "Timestamp",
    "detail.EMAIL": "Email",
    "detail.EMAIL_FROM": "Email From",
    "detail.EMAIL_TO": "Email To",
    "detail.FILE_NAME": "File Name",
    "detail.NAME": "Name"
}

export const sortDirectionOptions = {
    "desc": "Descending",
    "asc": "Ascending",
}

export interface AuditLogFilterForm {
    email: string;
    emailFrom: string;
    emailTo: string;
    inspectionId: string;
    name: string;
    user: string;
    authUser: string;
    sortBy: keyof typeof sortByOptions;
    sortOrder: keyof typeof sortDirectionOptions;
}

