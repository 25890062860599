import styles from './DropdownNav.module.scss';
import DropdownNavContext from './DropdownNavContext'
import { useContext } from 'react';
import { IconProp, Icon } from '../Icon/Icon';
import useClasses from '../../utility/useClasses';

interface NavbarLinkProps {
    icon: IconProp;
    title: string;
    to?: string;
    separator?: boolean;
    onClick?: () => void;
}

function NavbarLink({icon, title, to, onClick, separator}: NavbarLinkProps) {
    const { location, navigate } = useContext(DropdownNavContext);

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
        else if (to) {
            navigate(to);
        }
    }
    
    const isActive = to === location.pathname
        || location.pathname?.startsWith(to + '/');

    const classes = useClasses(styles, {
        link: true,
        active: isActive,
        separator: separator,
    });

    return (
        <div className={classes} onClick={handleClick}>
            <Icon icon={icon} />
            {title}
        </div>
    );
}

export default NavbarLink;
