import { FormEvent, useEffect, useReducer, useState } from "react";
import { Validator } from ".";
import useForceUpdate from "../../hooks/useForceUpdate";
import Container from "../Card/Container/Container";
import Loader from "../Loader/Loader";
import styles from './SchemaForm.module.scss';
import { SchemaFormContext } from './SchemaFormContext';

export interface SchemaFormProps<T extends object> {
    children: React.ReactNode;
    depends?: any[];
    onSubmit?: () => void;
    onError?: () => void;
    data: T,
    setData: (data: T) => void;
    title?: string;
}

interface Field {
    required: boolean;
    validators: Validator<any>[];
    touched: boolean;
    error: string;
}

interface FormStuff<T> {
    formData: T;
    validateAll: boolean;
    formHasError: boolean;
}


export function SchemaForm<T extends object>({children, depends,
    onSubmit=()=>{}, onError=()=>{}, data, setData, title}:
    SchemaFormProps<T>)
{

    const forceUpdate = useForceUpdate();

    const [fields, setFields] = useState<{[key: string]: Field}>(
        {} as {[key in keyof T]: Field});

    const getFieldError = (field: Field, value: any) => {
        for (let i = 0; i < field.validators.length; i++) {
            const error = field.validators[i](value);
            if (error) {
                return error;
            }
        }
        return '';
    };

    const validateAll = () => {
        let isValid = true;
        Object.entries(fields).forEach(([key, field]) => {
            if (!field.touched) {
                return;
            }
            const value = data[key as keyof T];
            field.error = value ? getFieldError(field, value)
                : (field.required ? 'This field is required' : '');
            if (field.error) {
                isValid = false;
            }
            fields[key] = field;
        });
        forceUpdate();
        return isValid;
    }

    const validateSelf = (key: string) => {
        const field = fields[key];
        if (!field.touched) {
            return;
        }
        const value = data[key as keyof T];
        field.touched = true;
        field.error = value ? getFieldError(field, value) : (field.required ? 'This field is required' : '');
        fields[key] = field;
        forceUpdate();
        return !field.error;
    }

    const context = {
        register: (key: string, required: boolean, validators: Validator<any>[]) =>
        {
            console.log("register");

            const field = fields[key];
            if (field) {
                fields[key] = {
                    required: required,
                    touched: field.touched,
                    error: field.error,
                    validators: validators,
                };
                // validateSelf(key);
            }
            else {
                fields[key] = {
                    required: required,
                    touched: false,
                    error: "",
                    validators: validators,
                };
            }
        },

        getValue: (key: keyof T) => {
            return data[key];
        },

        setValue: (key: string, value: any) => {
            fields[key].touched = true;
            setData({...data, [key]: value});
        },

        getError: (key: string) => {
            const field = fields[key];
            return field ? fields[key].error : '';
        },

        onBlur: (key: string) => {
            fields[key].touched = true;
            validateAll();
        },

        validateSelf,
    };

    const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        Object.keys(fields).forEach(key => {
            fields[key].touched = true;
        });
        validateAll() ? onSubmit() : onError();
    };

    const createContainerHeader = () => {
        return (
            <div style={{ display: 'flex', width: '100%' }}>
                <h3 style={{ flex: 1 }} className={styles['vertical-center']}>
                    {title}
                </h3>
                {depends?.includes(undefined) &&
                    <Loader className={styles['vertical-cetner']} />}
            </div>
        );
    };

    const formContext = (
        <SchemaFormContext.Provider value={context as any}>
            <form onSubmit={onFormSubmit}>
                {children}
            </form>
        </SchemaFormContext.Provider>
    );

    return !title ? formContext
        : <Container title={createContainerHeader()}>{formContext}</Container>;
}

