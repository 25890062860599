import { useEffect, useRef, useState } from 'react';
import styles from './Avatar.module.scss';

type AvatarType = 'photo' | 'text' | undefined;

interface AvatarProps {
    size?: 's' | 'm' | 'l' | 'xl';
    children?: string;
    src?: string;
}

function Avatar({ size = 's', children, src }: AvatarProps) {
    const avatarType: AvatarType = src ? 'photo' : children ? 'text' : undefined;
    if (children && src) {
        console.error('Avatar cannot have both src and children');
    }

    const avatarRef = useRef<SVGSVGElement>(null);
    const textRef = useRef<SVGTextElement>(null);
    const [avatarTitle, setAvatarTitle] = useState(children);
    const [shouldRenderTitle, setShouldRenderTitle] = useState(false);

    useEffect(() => {
        const shouldClipText = !!avatarRef.current && !!textRef.current;
        if (shouldClipText) {
            const { width } = avatarRef.current.getBoundingClientRect();

            const textWidth = textRef.current.getComputedTextLength();
            const textContent = textRef.current.textContent;

            if (!!textContent?.length) {
                const characterWidth = textWidth / textContent.length;
                const maxCharacters = Math.floor(width / characterWidth);
                const clippedText = textContent.substring(0, maxCharacters);
                setAvatarTitle(clippedText);
                setShouldRenderTitle(true);
            }
        }
    }, [avatarRef, textRef]);

    return (
        <svg className={`${styles['avatar']} ${styles[`avatar-${size}`]}`} ref={avatarRef}>
            <defs>
                <linearGradient id="avatar-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="var(--primary-default)" />
                    <stop offset="100%" stopColor="rgba(106, 173, 0, 0)" />
                </linearGradient>
            </defs>

            <g visibility={avatarType === 'text' ? 'visible' : 'hidden'}>
                <circle
                    cx="50%"
                    cy="50%"
                    r="50%"
                    className={styles['outline']}
                    stroke="url(#avatar-gradient)"
                    strokeWidth="inherit"
                />

                <circle cx="50%" cy="50%" r="50%" className={styles['circle']} />

                <text
                    className={styles['text']}
                    x="50%"
                    y="50%"
                    dominantBaseline="central"
                    textAnchor="middle"
                    visibility={shouldRenderTitle ? 'visible' : 'hidden'}
                    ref={textRef}
                >
                    {avatarTitle}
                </text>
            </g>

            <g visibility={avatarType === 'photo' ? 'visible' : 'hidden'}>
                <image href={src} className={styles['image']} width="100%" height="100%" />
                <circle
                    cx="50%"
                    cy="50%"
                    r="50%"
                    className={styles['outline']}
                    stroke="url(#avatar-gradient)"
                    strokeWidth="inherit"
                />
            </g>
        </svg>
    );
}

export default Avatar;
