import { CSSProperties } from 'react';
import styles from './Skeleton.module.scss';

interface SkeletonProps {
    width?: string;
    height?: string;
}

function Skeleton({ width, height }: SkeletonProps) {
    const style: CSSProperties = {
        width: width ? `${width}rem` : '100%',
        height: (height || 1) + 'rem',
    };
    return <div className={styles['skeleton']} style={style} />;
}

export default Skeleton;
