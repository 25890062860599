const perentieColors = {
    'neutral-10': '#fff',
    'variant-border': '#7b61ff',
    'neutral-60': '#9e9e9e',
    'neutral-20': '#f5f5f5',
    'neutral-80': '#616161',
    'neutral-70': '#757575',
    'neutral-30': '#ededed',
    'neutral-40': '#e0e0e0',
    'neutral-90': '#424242',
    'neutral-50': '#c2c2c2',
    'neutral-100': '#0a0a0a',
    'neutral-10-at-80': 'rgba(255, 255, 255, 0.8)',
    'neutral-90-at-30': 'rgba(66, 66, 66, 0.3)',
    'neutral-10-at-94': 'rgba(255, 255, 255, 0.94)',
    'neutral-10-at-50': 'rgba(255, 255, 255, 0.5)',
    'neutral-80-at-60': 'rgba(97, 97, 97, 0.6)',
    'neutral-70-at-16': 'rgba(117, 117, 117, 0.16)',
    'neutral-100-at-4': 'rgba(0, 0, 0, 0.04)',
    'neutral-transparent': 'rgba(255, 255, 255, 0)',
    'primary-default': '#6aad00',
    'primary-hover': '#66a00b',
    'primary-focus': 'rgba(136, 200, 35, 0.2)',
    'primary-active': '#57870b',
    'primary-light': '#9ddf46',
    'primary-dark': '#367d00',
    'primary-border': '#bce778',
    'primary-very-light': '#c9e7a1',
    'primary-visited': '#362ac0',
    'primary-surface': '#fafff2',
    'secondary-default': '#6c757d',
    'secondary-light': '#9aa4ac',
    'secondary-border': '#cbd1d7',
    'secondary-surface': '#f0f4f8',
    'secondary-hover': '#535a61',
    'secondary-active': '#414951',
    'secondary-dark': '#414951',
    'danger-default': '#e00000',
    'danger-surface': '#ffecec',
    'danger-border': '#ffd7d7',
    'secondary-focus': 'rgba(108, 117, 125, 0.2)',
    'danger-hover': '#a20000',
    'danger-active': '#630000',
    'danger-focus': 'rgba(224, 0, 0, 0.2)',
    'warning-surface': '#fff2e7',
    'warning-border': '#ffca99',
    'warning-hover': '#d16603',
    'warning-active': '#653508',
    'success-default': '#008243',
    'success-surface': '#ebf5f0',
    'success-border': '#b3dac7',
    'success-hover': '#006836',
    'success-active': '#005029',
    'success-focus': 'rgba(0, 130, 67, 0.2)',
    'info-default': '#0b5cd7',
    'info-surface': '#ecf2fc',
    'info-border': '#b6cff3',
    'info-hover': '#044dba',
    'info-active': '#043c8f',
    'table-light-edit-row-background-row-hover': '#fff',
    'table-light-normal-cell-background-active': 'rgba(26, 122, 187, 0.12)',
    'table-light-normal-cell-background-hover': 'rgba(26, 122, 187, 0.06)',
    'table-dark-normal-cell-background-active': 'rgba(26, 122, 187, 0.1)',
    'systemic-logo-green-text': '#44b549',
    'state-colours-info': '#5a90e2',
    'state-colours-low': '#fdd64d',
    'state-colours-warning': '#ff9c54',
    'state-colours-high': '#ff601c',
    'warning-default': '#ea780e',
    'state-colours-severe': '#eb294c',
    'state-colours-critical': '#e01a91',
    'state-colours-ok': '#58ca93',
    'notifications-badge-red': '#eb4d3d',
    'i-os-keyboard-icons': '#50555c',
    'i-os-keyboard-background': '#d1d3d9',
    'i-os-keyboard-dark-keys-background': '#abb0bc',
    'i-os-light-mode-sheet-background': '#f2f2f6',
    'i-os-light-mode-panel-background': 'rgba(245, 245, 245, 0.94)',
    'i-os-light-mode-perentie-panel-background': 'rgba(246, 252, 239, 0.94)',
    'i-os-system-colours-green': '#34c759',
    'i-os-system-colours-blue': '#007aff',
    'main-menu-dark-background': '#242728',
    'main-menu-dark-item-background': '#242728',
    'main-menu-dark-item-hover-background': '#383d3f',
    'main-menu-dark-item-focused-border': 'rgba(87, 101, 105, 0.2)',
    'main-menu-dark-item-active-background': '#354145',
    'main-menu-dark-header-background': '#191b1c',
    'top-bar-dark-background': '#0c3357',
    'perentie-logo-head': '#fdfdfd',
    'perentie-logo-text': '#b6d44a',
    'perentie-logo-dark-green': '#91b349',
};

const defaultColors = {
    'variant-border': '#7b61ff',
    'neutral-60': '#9e9e9e',
    'neutral-70': '#757575',
    'neutral-50': '#c2c2c2',
    'neutral-10': '#fff',
    'neutral-30': '#ededed',
    'neutral-40': '#e0e0e0',
    'neutral-20': '#f5f5f5',
    'neutral-80': '#616161',
    'neutral-90': '#424242',
    'neutral-10-at-80': '#fffc',
    'neutral-100': '#0a0a0a',
    'neutral-90-at-30': '#4242424c',
    'neutral-10-at-94': '#fffffff0',
    'neutral-10-at-50': '#ffffff80',
    'neutral-80-at-60': '#61616199',
    'neutral-100-at-4': '#0000000a',
    'neutral-70-at-16': '#75757528',
    'neutral-10-at-0-transparent': '#fff0',
    'neutral-100-at-30': '#0000004c',
    'neutral-100-at-90': '#000000e6',
    'neutral-10-at-25': '#ffffff40',
    'primary-light': '#5fa9ee',
    'primary-default': '#1a7abb',
    'primary-dark': '#004e8b',
    'primary-surface': '#f3fafe',
    'primary-border': '#a6d2f4',
    'primary-hover': '#0666a7',
    'primary-focus': '#1a7abb33',
    'primary-active': '#0b4872',
    'primary-visited': '#362ac0',
    'secondary-default': '#6c757d',
    'secondary-light': '#9aa4ac',
    'secondary-dark': '#414951',
    'secondary-hover': '#535a61',
    'secondary-surface': '#f0f4f8',
    'secondary-border': '#cbd1d7',
    'secondary-active': '#414951',
    'secondary-focus': '#6c757d33',
    'danger-default': '#e00000',
    'danger-hover': '#a20000',
    'danger-surface': '#ffecec',
    'danger-active': '#630000',
    'danger-focus': '#e0000033',
    'warning-default': '#ea780e',
    'warning-surface': '#fff2e7',
    'warning-border': '#ffca99',
    'warning-hover': '#d16603',
    'warning-active': '#653508',
    'warning-focus': '#ea780e33',
    'danger-border': '#ffd7d7',
    'success-default': '#008243',
    'success-surface': '#ebf5f0',
    'success-border': '#b3dac7',
    'success-hover': '#006836',
    'success-active': '#005029',
    'success-focus': '#00824333',
    'info-default': '#0b5cd7',
    'info-border': '#b6cff3',
    'info-hover': '#044dba',
    'info-surface': '#ecf2fc',
    'info-active': '#043c8f',
    'info-focus': '#0b5cd733',
    'table-light-edit-row-background-row-hover': '#fff',
    'table-light-normal-cell-background-active': '#1a7abb1e',
    'table-light-normal-cell-background-hover': '#1a7abb0f',
    'table-light-normal-header-background-default': '#e1e9ee',
    'table-light-normal-header-sort-icon-inactive': '#adc8da',
    'table-dark-normal-cell-background-active': '#1a7abb19',
    'systemic-logo-green-text': '#44b549',
    'state-colours-ok': '#58ca93',
    'state-colours-info': '#5a90e2',
    'state-colours-low': '#fdd64d',
    'state-colours-warning': '#ff9c54',
    'state-colours-high': '#ff601c',
    'state-colours-severe': '#eb294c',
    'state-colours-critical': '#e01a91',
    'notifications-badge-red': '#eb4d3d',
    'main-menu-dark-background': '#242728',
    'main-menu-dark-item-background': '#242728',
    'main-menu-dark-item-hover-background': '#383d3f',
    'main-menu-dark-item-focused-border': '#57656933',
    'main-menu-dark-item-active-background': '#354145',
    'main-menu-dark-header-background': '#191b1c',
    'main-menu-icons-orange': '#fdb600',
    'main-menu-icons-lime-popper': '#05ff00',
    'main-menu-icons-cyan': '#4ed5e8',
    'top-bar-dark-background': '#0c3357',
    'main-menu-icons-magenta': '#eb00ff',
    'horz-menu-level-2-default-background': '#deeefa',
    'horz-menu-level-2-hover-background': '#d1e6f4',
    'horz-menu-level-2-focused-outline': '#035b974c',
    'horz-menu-level-2-focused-background': '#d1e6f4',
    'horz-menu-level-2-active-background': '#1a7abb',
};

export type ColorConfig = typeof defaultColors;
export type ColorNames = keyof ColorConfig;
export type GenericColorConfig = { [key in string]: string };

export { perentieColors };
export default defaultColors;
