import { createForm, CardContainer } from '@application-production-system/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminService, ConsolePartnerUpdateRequest } from '../../api/console';
import { handleError } from '../../assets/constants';
import { CancelSubmitRow } from '../../components';
import { ROUTES } from '../../routes';

interface CreatePartnerForm {
    partnerId: string;
    partnerCode: string;
    clientId: string;
    secret: string;
    endpoint: string;
    primary: boolean;
}

const Form = createForm<CreatePartnerForm>();

function CreateIntegrationPage() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState<CreatePartnerForm>({} as CreatePartnerForm);

    const onSubmit = () => {
        const request: ConsolePartnerUpdateRequest = { partner: formData };
        AdminService.createPartner(request)
            .then(response => {
                navigate(ROUTES.partnerDetails.build({id: response?.partner?.id}));
            })
            .catch(handleError);
    }

    return (
        <div style={{maxWidth: "600px"}}>
            <CardContainer title="Create Partner">
                <Form.Form onSubmit={onSubmit} data={formData} setData={setFormData}>
                    <Form.Entry required for="partnerId"   label="Partner Id" />
                    <Form.Entry required for="partnerCode" label="Partner Code" />
                    <Form.Entry required for="clientId"    label="Client Id" />
                    <Form.Entry required for="secret"      label="Secret" />
                    <Form.Entry required for="endpoint"    label="Endpoint" />
                    <Form.Checkbox       for="primary"     label="Primary" />
                    <CancelSubmitRow />
                </Form.Form>
            </CardContainer>
        </div>
    );
}

export default CreateIntegrationPage;
