interface SystemicLogoProps {
    width?: number;
    height?: number;
}

function SystemicLogo({ width = 14.285, height = 19.581 }: SystemicLogoProps) {
    const originalHeight = 19.581;
    const originalWidth = 14.285;
    const computedScale = Math.max(
        width / originalWidth,
        height / originalHeight
    );

    return (
        <div
            style={{
                height: `${originalHeight * computedScale}px`,
                width: `${originalWidth * computedScale}px`,
            }}
        >
            <svg version="1.1" fill="none">
                <defs>
                    <linearGradient
                        id="gradient-green"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="0%"
                    >
                        <stop
                            offset="0%"
                            style={{
                                stopColor: 'rgb(151,201,63)',
                                stopOpacity: 1,
                            }}
                        />

                        <stop
                            offset="100%"
                            style={{
                                stopColor: 'rgb(63,180,73)',
                                stopOpacity: 1,
                            }}
                        />
                    </linearGradient>

                    <linearGradient
                        id="gradient-blue"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="0%"
                    >
                        <stop
                            offset="0%"
                            style={{
                                stopColor: 'rgb(7,117,187)',
                                stopOpacity: 1,
                            }}
                        />
                        <stop
                            offset="100%"
                            style={{
                                stopColor: 'rgb(37,165,222)',
                                stopOpacity: 1,
                            }}
                        />
                    </linearGradient>

                    <clipPath>
                        <path d="m-117.5-37.725v150.92h470v-150.92z" />
                    </clipPath>
                    <clipPath id="clipPath885">
                        <path d="m-117.5-37.725v150.92h470v-150.92z" />
                    </clipPath>
                </defs>
                <g
                    transform={`scale(${computedScale}) translate(-42.836 -103.37)`}
                    width={width}
                    height={height}
                >
                    <g transform="translate(-79.248 26.773)">
                        <g transform="matrix(.35278 0 0 -.35278 114.18 99.317)">
                            <g
                                clipPath="url(#clipPath885)"
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth=".285"
                            >
                                <path
                                    d="m41.835 9-19.335 19.335 4.905 4.92 14.43-14.445 10.727 10.731c1.2102 1.1637 1.2075 3.2451-0.0016 4.4043l-8.01 8.01 4.905 4.92 8.0224-8.0207c3.9063-3.7546 3.9025-10.465-6.5e-4 -14.222z"
                                    fill="url(#gradient-blue)"
                                />
                                <path
                                    fill="url(#gradient-green)"
                                    d="m32.713 43.757c-1.2102-1.1564-1.2109-3.241 0-4.3984l8.0267-8.0083-4.92-4.92-8.0146 8.0204c-3.9139 3.7539-3.9107 10.464 0 14.222l15.635 15.633 19.35-19.335-4.92-4.92-14.43 14.43z"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default SystemicLogo;
