import styles from './Label.module.scss';

interface LabelProps {
    text: string;
    required?: boolean;
}

function Label({text, required=false}: LabelProps) {
    return (
        <label className={styles["label"]}>
            {text}
            {required && 
                <span style={{color: "grey"}}>*</span>
            }
        </label>
    );
}

export default Label;
