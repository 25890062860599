import { CSSProperties, useContext, useState } from 'react';
import { animated, AnimatedProps } from 'react-spring';
import SidebarContext from '../../SidebarContext';
import Icon from '../../../Icon/Icon';
import styles from './NormalLink.module.scss';
import { LinkProps } from '../Link';

function NormalLink({ title, icon, iconSize="sm", activeIcon, to, onClick }: LinkProps) {
    const { navigator, location, animationProgress, width, minWidth } = useContext(SidebarContext);

    const isActive = to === location.pathname || location.pathname?.startsWith(to + '/');

    const [isHovered, setIsHovered] = useState(false);
    const shouldShowActiveIcon = isActive || isHovered;

    const animatedIconStyle = {
        transform: animationProgress
            .to({
                range: [100, 0],
                output: [0, 50],
            })
            .to(number => `translateX(-${number}%)`),
        left: animationProgress
            .to({
                range: [100, 0],
                // 18px = 8px link padding + 10px icon padding
                output: [18, minWidth - 18],
            })
            .to(number => `${number / 2}px`),
    } as AnimatedProps<CSSProperties>;

    const animatedTitleStyle = {
        opacity: animationProgress.to({
            range: [50, 100],
            output: [0, 1],
        }),
        left: animationProgress
            .to({
                range: [100, 0],
                // link padding + left icon padding + icon + right icon padding
                output: [10 + 8 + 16 + 8, minWidth],
            })
            .to(number => `${number}px`)
    } as AnimatedProps<CSSProperties>;

    const onClickHandler = () => {
        if (to) {
            navigator(to);
        }
        if (onClick) {
            onClick();
        }
    };

    return (
        <div
            className={`${styles['link']} ${isActive && styles['selected']}`}
            onClick={onClickHandler}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <animated.div className={styles['icon-frame']} style={animatedIconStyle}>
                <i className={styles['icon']}>{icon && <Icon icon={icon} size={iconSize}/>}</i>
            </animated.div>

            <animated.div className={styles['title-frame']} style={animatedTitleStyle}>
                <span className={styles['title']}>{title}</span>
            </animated.div>

            <i className={styles['active-icon']}>{activeIcon && shouldShowActiveIcon && <Icon icon={activeIcon} />}</i>
        </div>
    );
}

export default NormalLink;
