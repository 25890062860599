import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '.'

import { IconProp } from '@application-production-system/react';

import { OpenAPI as docOpenApi, UserDetails } from '../api/doc';
import { OpenAPI as consoleOpenApi } from '../api/console';

export interface PageTitle {
    page: string;
    subPage?: string;
    icon?: IconProp;
}

interface SessionState {
    token: string;
    loggedIn: boolean;
    sidebarCollapsed: boolean,
    navCollapsed: boolean,
    productCode?: string;
    user?: UserDetails,
    loginError?: string;
    pageTitle: PageTitle;
}

const initialState: SessionState = {
    pageTitle: {page: "PIM Dashboard"},
    token: '',
    sidebarCollapsed: false,
    user: undefined,
    navCollapsed: true,
    loggedIn: false,
    productCode: undefined,
    loginError: undefined,
}

export interface LoginDetails {
    token: string;
    user?: UserDetails;
    productCode?: string;
}

const setCookie = (name: string, value: string) => {
    let expire = new Date().getTime() + 60 * 60 * 1000;
    document.cookie = name + "=" + value + ";expire=" + expire;
}

const clearCookie = (name: string) => {
    document.cookie = name + "=";
}

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        logout: (state) => {
            state.loggedIn = false;
            state.token = '';
            state.productCode = undefined;
            docOpenApi.HEADERS = {};
            consoleOpenApi.HEADERS = {};
            clearCookie("token");
            clearCookie("productCode");
        },

        changeProduct: (state, action: PayloadAction<string>) => {
            state.productCode = action.payload;
            const headers = {
                'Authorization': 'Bearer ' + state.token,
                'Doc-Product-Code': state.productCode
            };
            docOpenApi.HEADERS = headers;
            consoleOpenApi.HEADERS = headers;
        },
        
        login: (state, action: PayloadAction<LoginDetails>) => {
            state.loggedIn = true;
            state.token = action.payload.token;
            state.productCode = action.payload.productCode;
            const headers = {
                'Authorization': 'Bearer ' + state.token,
                // 'Doc-Product-Code': state.productCode
            };
            docOpenApi.HEADERS = headers;
            consoleOpenApi.HEADERS = headers;
            setCookie("token", state.token);
            if (state.productCode) {
                setCookie("productCode", state.productCode);
            }
        },

        setUser: (state, action: PayloadAction<UserDetails>) => {
            if (!state.productCode && action.payload.productCode) {
                state.productCode = action.payload.productCode;
                setCookie("productCode", state.productCode!);
            }
            state.user = action.payload;
        },

        setSidebarCollapsed: (state, action: PayloadAction<boolean>) => {
            state.sidebarCollapsed = action.payload;
        },

        setNavCollapsed: (state, action: PayloadAction<boolean>) => {
            state.navCollapsed = action.payload;
        },

        setPageTitle: (state, action: PayloadAction<PageTitle>) => {
            state.pageTitle = action.payload;
        },
    }
})

export const { logout, changeProduct, login, setUser, setSidebarCollapsed, setNavCollapsed, setPageTitle } = sessionSlice.actions;

export const selectLoggedIn = (state: RootState) => state.session.loggedIn;
export const selectToken = (state: RootState) => state.session.token;
export const selectUser = (state: RootState) => state.session.user;
export const selectProductCode = (state: RootState) => state.session.productCode;
export const selectLoginError = (state: RootState) => state.session.loginError;
export const selectSidebarCollapsed = (state: RootState) => state.session.sidebarCollapsed;
export const selectNavCollapsed = (state: RootState) => state.session.navCollapsed;
export const selectPageTitle = (state: RootState) => state.session.pageTitle;

export default sessionSlice.reducer;
