import {
    OpenAPI,
    TemplateGetForm,
    TemplateUploadForm,
    TemplateTestForm,
    TemplateListForm,
    CancelablePromise, TemplatesResponse, CreateUserForm, DocAccessKeyResponse
} from '../doc';
import axios from 'axios';
import { AxiosPromise } from 'axios';
import {request as __request} from "../doc/core/request";

export const downloadTemplate = (formData: TemplateGetForm): AxiosPromise => {
    return axios({
        headers: {
            ...OpenAPI.HEADERS,
            "accept": "application/octet-stream",
            "Content-Type": "multipart/form-data",
        },
        url: OpenAPI.BASE + '/api/docsvc/v2/templates/getTemplate',
        method: 'POST',
        params: formData,
        responseType: 'blob'
    });
}

export const testTemplate = (templateName: string, systemTemplate: boolean): AxiosPromise => {
    return axios({
        headers: {
            ...OpenAPI.HEADERS,
            "accept": "application/octet-stream",
            "Content-Type": "multipart/form-data",
        },
        url: OpenAPI.BASE + '/api/docsvc/v2/templates/testTemplate',
        method: 'GET',
        params: {templateName, systemTemplate},
        responseType: 'blob'
    });
}

export const createUserV1 = (data: CreateUserForm): Promise<Response> => {
    const params: string[] = [];
    Object.entries(data).forEach(([k, v]) => {
        const encodedKey = encodeURIComponent(k);
        const encodedValue = encodeURIComponent(v);
        params.push(encodedKey + "=" + encodedValue);
    });
    const formBody = params.join("&");

    return fetch(OpenAPI.BASE + '/api/docsvc/v1/users/createUser', {
        headers: {
            ...OpenAPI.HEADERS,
            "accept": "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "POST",
        body: formBody,
    });
}

export const uploadTemplate = (formData: TemplateUploadForm): AxiosPromise => {
    return axios({
        headers: {
            ...OpenAPI.HEADERS,
            "accept": "application/octet-stream",
            "Content-Type": "multipart/form-data",
        },
        url: OpenAPI.BASE + '/api/docsvc/v1/templates/uploadTemplate',
        method: 'POST',
        data: formData,
        responseType: 'blob'
    });
}

const encodeParams = (params: {[key: string]: any}) => {
    const encodedParamList: string[] = [];
    Object.entries(params).forEach(([key, value], _) => {
        const encoded = encodeURIComponent(key) + "=" + encodeURIComponent(value);
        encodedParamList.push(encoded);
    });
    return encodedParamList.join("&");
}

export const resetPassword = (
        formData: {
            email: string;
            resetPasswordViaUrl: boolean;
        },
) => {
    return axios({
        headers: {
            ...OpenAPI.HEADERS,
            "accept": "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
        },
        url: OpenAPI.BASE + '/api/docsvc/v1/users/forgotPassword',
        method: 'POST',
        data: encodeParams(formData)
    });
}
