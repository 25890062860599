/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConsoleInspectionResponse } from '../models/ConsoleInspectionResponse';
import type { ConsoleInspectionSearchResponse } from '../models/ConsoleInspectionSearchResponse';
import type { ConsoleInspectionSummaryResponse } from '../models/ConsoleInspectionSummaryResponse';
import type { ConsoleIntegrationRequest } from '../models/ConsoleIntegrationRequest';
import type { ConsoleIntegrationResponse } from '../models/ConsoleIntegrationResponse';
import type { ConsoleIntegrationSearchResponse } from '../models/ConsoleIntegrationSearchResponse';
import type { ConsolePartnerResponse } from '../models/ConsolePartnerResponse';
import type { ConsolePartnerSearchResponse } from '../models/ConsolePartnerSearchResponse';
import type { ConsolePartnerUpdateRequest } from '../models/ConsolePartnerUpdateRequest';
import type { ConsoleSvcResponse } from '../models/ConsoleSvcResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminService {

    /**
     * Assign an Inspection
     * Mark an inspection as assigned. Will no longer show as available
     * @param officeId
     * @param inspectionId
     * @returns ConsoleInspectionResponse The newly assigned event
     * @throws ApiError
     */
    public static asssign(
        officeId: string,
        inspectionId: string,
    ): CancelablePromise<ConsoleInspectionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/consolesvc/v1/admin/inspections/{office-id}/{inspection-id}/assign',
            path: {
                'office-id': officeId,
                'inspection-id': inspectionId,
            },
            errors: {
                400: `The inspection is already assigned`,
                403: `The inspection is for a different office`,
                404: `The inspection does not exist`,
            },
        });
    }

    /**
     * Delete an Inspection
     * Remove an Inspection from the system
     * @param officeId
     * @param inspectionId
     * @returns ConsoleSvcResponse
     * @throws ApiError
     */
    public static delete(
        officeId: string,
        inspectionId: string,
    ): CancelablePromise<ConsoleSvcResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/consolesvc/v1/admin/inspections/{office-id}/{inspection-id}',
            path: {
                'office-id': officeId,
                'inspection-id': inspectionId,
            },
            errors: {
                400: `The inspection is was not assigned`,
                403: `The inspection is for a different office`,
                404: `The inspection does not exist`,
            },
        });
    }

    /**
     * Get a summary of inspection events
     * @returns ConsoleInspectionSummaryResponse The inspection events summary
     * @throws ApiError
     */
    public static getSummary(): CancelablePromise<ConsoleInspectionSummaryResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/consolesvc/v1/admin/inspections/summary',
        });
    }

    /**
     * Resync Inspections
     * This will delete all inspections for the office
     * @param officeId
     * @returns ConsoleSvcResponse
     * @throws ApiError
     */
    public static resync(
        officeId: string,
    ): CancelablePromise<ConsoleSvcResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/consolesvc/v1/admin/inspections/{office-id}/resync',
            path: {
                'office-id': officeId,
            },
        });
    }

    /**
     * Search Inspections
     * Search Inspection Events
     * @param state
     * @returns ConsoleInspectionSearchResponse The inspection events
     * @throws ApiError
     */
    public static search(
        state?: 'UNASSIGNED' | 'ASSIGNED',
    ): CancelablePromise<ConsoleInspectionSearchResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/consolesvc/v1/admin/inspections',
            query: {
                'state': state,
            },
        });
    }

    /**
     * Search Inspections
     * Search Inspection Events
     * @param officeId
     * @param state
     * @returns ConsoleInspectionSearchResponse The inspection events
     * @throws ApiError
     */
    public static search1(
        officeId: string,
        state?: 'UNASSIGNED' | 'ASSIGNED',
    ): CancelablePromise<ConsoleInspectionSearchResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/consolesvc/v1/admin/inspections/{office-id}',
            path: {
                'office-id': officeId,
            },
            query: {
                'state': state,
            },
        });
    }

    /**
     * Unassign an Inspection
     * Mark an inspection as not assigned, this will show as available
     * @param officeId
     * @param inspectionId
     * @returns ConsoleSvcResponse
     * @throws ApiError
     */
    public static unassign(
        officeId: string,
        inspectionId: string,
    ): CancelablePromise<ConsoleSvcResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/consolesvc/v1/admin/inspections/{office-id}/{inspection-id}/unassign',
            path: {
                'office-id': officeId,
                'inspection-id': inspectionId,
            },
            errors: {
                400: `The inspection is was not assigned`,
                403: `The inspection is for a different office`,
                404: `The inspection does not exist`,
            },
        });
    }

    /**
     * Get an Integration
     * @param id
     * @returns ConsoleIntegrationResponse The integration
     * @throws ApiError
     */
    public static getIntegrationById(
        id: string,
    ): CancelablePromise<ConsoleIntegrationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/consolesvc/v1/admin/integrations/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `If the Integration does not exist.`,
            },
        });
    }

    /**
     * Update an Integration
     * @param id
     * @param requestBody
     * @returns ConsoleIntegrationResponse The integration
     * @throws ApiError
     */
    public static updateIntegration(
        id: string,
        requestBody?: ConsoleIntegrationRequest,
    ): CancelablePromise<ConsoleIntegrationResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/consolesvc/v1/admin/integrations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `If the Integration does not exist.`,
            },
        });
    }

    /**
     * Deletes an Integration
     * This does not delete associated inspections
     * @param id
     * @returns ConsoleSvcResponse
     * @throws ApiError
     */
    public static deleteIntegration(
        id: string,
    ): CancelablePromise<ConsoleSvcResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/consolesvc/v1/admin/integrations/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `If the Integration does not exist.`,
            },
        });
    }

    /**
     * Get Integrations
     * Get the activated client integrations
     * @returns ConsoleIntegrationSearchResponse The integrations
     * @throws ApiError
     */
    public static findIntegrations(): CancelablePromise<ConsoleIntegrationSearchResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/consolesvc/v1/admin/integrations',
        });
    }

    /**
     * Find Partners
     * This is the PIM Partner Setup
     * @returns ConsolePartnerSearchResponse
     * @throws ApiError
     */
    public static findPartners(): CancelablePromise<ConsolePartnerSearchResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/consolesvc/v1/admin/partners',
        });
    }

    /**
     * Creates a partner
     * @param requestBody
     * @returns ConsolePartnerResponse The new Partner
     * @throws ApiError
     */
    public static createPartner(
        requestBody?: ConsolePartnerUpdateRequest,
    ): CancelablePromise<ConsolePartnerResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/consolesvc/v1/admin/partners',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `If the partner exists.`,
            },
        });
    }

    /**
     * Update a partner
     * @param id
     * @param requestBody
     * @returns ConsolePartnerResponse The updated Partner
     * @throws ApiError
     */
    public static updatePartner(
        id: string,
        requestBody?: ConsolePartnerUpdateRequest,
    ): CancelablePromise<ConsolePartnerResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/consolesvc/v1/admin/partners/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `If the partner does not exist`,
            },
        });
    }

    /**
     * Deletes a partner
     * @param id
     * @returns ConsoleSvcResponse
     * @throws ApiError
     */
    public static deletePartner(
        id: string,
    ): CancelablePromise<ConsoleSvcResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/consolesvc/v1/admin/partners/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `If the partner does not exist.`,
            },
        });
    }

}
