import { SpringConfig } from 'react-spring';

const defaultAnimationConfig = {
    mass: 1,
    tension: 210,
    friction: 20,
    precision: 0.01,
    velocity: 0.01,
    clamp: true,
} as SpringConfig;

export default defaultAnimationConfig;
