import { Table, Row, Cell, Button, HeaderRow, HeaderCell } from '@application-production-system/react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AdminService, ConsolePartner } from '../../api/console';
import { ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';

function PartnersPage() {
    const [partners, setPartners] = useState<ConsolePartner[]>([]);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState("Loading...");

    const navigate = useNavigate();

    useEffect(()=> {
        AdminService.findPartners()
        .then(response => {
            setSuccess(true);
            setMessage(response.partners!.length
                ? "" : "No partners to display.");
            setPartners(response.partners!);
        })
        .catch(error => {
            setSuccess(false);
            setMessage(error.status == 403
                ? "You do not have permission to view partners."
                : "Something went wrong."
            );
        })
    }, [])

    const createPartnersTable = () => {
        return !partners.length ? message : (
            <Table border data={partners} divider={false}>
                <>
                <HeaderRow>
                    <HeaderCell><b>Partner ID</b></HeaderCell>
                    <HeaderCell><b>Client ID</b></HeaderCell>
                    <HeaderCell><b>Primary</b></HeaderCell>
                </HeaderRow>
                {partners.map((partner: ConsolePartner, idx: number) => {
                    return (
                        <Row
                            key={idx}
                            onClick={() => {
                                navigate(ROUTES.partnerDetails.build({id: partner.id}), {state: {partner: partner}});
                            }}
                            divider={true}
                            striped={idx % 2 === 1}
                        >
                            <Cell>{ partner.partnerId }</Cell>
                            <Cell>{ partner.clientId }</Cell>
                            <Cell>{
                                partner.primary
                                    ? <span style={{color: "green"}}>Yes</span>
                                    : <span style={{color: "red"}}>No</span>
                            }</Cell>
                        </Row>
                    );
                })}
                </>
            </Table>
        )
    }

    return (
        <Page title={{page: "Partners", icon: ['far', 'beer']}}>
            <div style={{overflowX: "hidden"}}>
                <div style={{display: "flex"}}>
                    <h2>Partners</h2>
                    <div style={{marginLeft: "auto", marginTop: "auto", marginBottom: "10px"}}>
                        <Button
                            disabled={!success}
                            title="New Partner"
                            icon={['far', 'plus-circle']}
                            iconPosition="left"
                            onClick={() => {
                                navigate(ROUTES.createPartner.build());
                            }}
                        />
                    </div>
                </div>
                <div style={{overflowX: "scroll"}}>
                    <div style={{minWidth: "500px"}}>
                        {createPartnersTable()}
                    </div>
                </div>
            </div>
        </Page>
    );
}

export default PartnersPage;
