import { isEmail, createForm, CardContainer } from '@application-production-system/react';
import { UsersService, CreateUserDetails } from '../../api/doc';
import { useNavigate } from 'react-router-dom';
import { roles, defaultRole, handleError } from '../../assets/constants';
import { CancelSubmitRow } from '../../components';
import { useAppDispatch } from '../../hooks';
import { createUser } from '../../store/userSlice';
import { ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';
import { useState } from 'react';

interface CreateUserForm {
    email: string;
    userId: string;
    firstName: string;
    lastName: string;
    password: string;
    role: keyof typeof roles;
}

const Form = createForm<CreateUserForm>();

function CreateUserPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [formData, setFormData] = useState<CreateUserForm>({role: defaultRole} as CreateUserForm);

    const onSubmit = () => {
        const request: CreateUserDetails = {
            ...formData,
            userId: formData.userId ? formData.userId : formData.email
        }

        UsersService.createUser(request)
            .then((response) => {
                dispatch(createUser(response.user));
                navigate(ROUTES.userDetails.build({id: response.user!.id}));
            })
            .catch(handleError);
    }

    return (
        <Page title={{page: "Users", subPage: "Create User", icon: ['far', 'user']}}>
            <div style={{maxWidth: "600px"}}>
            <CardContainer title="Create User">
                    <Form.Form onSubmit={onSubmit} data={formData} setData={setFormData}>
                        <Form.Entry  required for="firstName" label="First Name" />
                        <Form.Entry  required for="lastName"  label="Last Name" />
                        <Form.Entry  required for="email"     label="Email" validate={isEmail} />
                        <Form.Entry           for="userId"    label="Login (Leave blank to use email)" />
                        <Form.Entry  required for="password"  label="Password" />
                        <Form.Select required for="role"      label="Role" options={roles} />
                        <CancelSubmitRow />
                    </Form.Form>
                </CardContainer>
            </div>
        </Page>
    );
}

export default CreateUserPage;
