import { CSSProperties, useEffect, useId, useRef, useState } from 'react';
import { animated, AnimatedProps, useSpring } from 'react-spring';
import useThemeAnimation from '../../hooks/useThemeAnimation';
import styles from './Switch.module.scss';

interface SwitchProps {
    size?: 's' | 'sm';
    label?: string;
    disabled?: boolean;
    checked?: boolean;
    onChange?: (checked: boolean) => void;
}

function Switch({ size = 's', label, disabled, checked, onChange }: SwitchProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    const switchId = useId();
    const [shouldAnimate, animationConfig] = useThemeAnimation();
    const [locallyChecked, setLocallyChecked] = useState<boolean>(false);
    useEffect(() => {
        setLocallyChecked(!!checked);
    }, [checked]);

    const [lowerAnimationBounds, setLowerAnimationBounds] = useState<number>(0);
    const [upperAnimationBounds, setUpperAnimationBounds] = useState<number>(0);
    const [shouldShow, setShouldShow] = useState<boolean>(false);

    useEffect(() => {
        const shouldUpdateAnimationBounds = inputRef.current;
        if (shouldUpdateAnimationBounds) {
            const borderRadius = parseFloat(getComputedStyle(inputRef.current).borderRadius);
            const pipSize = parseFloat(getComputedStyle(inputRef.current).backgroundSize);
            const switchWidth = inputRef.current.clientWidth;

            setLowerAnimationBounds((borderRadius - pipSize) / 4);
            setUpperAnimationBounds(switchWidth - (borderRadius - pipSize) / 4 - pipSize);
        }
    }, [inputRef]);

    const animatedCheckboxStyle = useSpring({
        to: {
            backgroundColor: locallyChecked ? 'var(--primary-hover)' : 'var(--neutral-40)',
            visibility: shouldShow ? 'visible' : 'hidden',
            backgroundPositionX: locallyChecked ? `${upperAnimationBounds}px` : `${lowerAnimationBounds}px`,
        } as AnimatedProps<CSSProperties>,
        // required to allow the pip to settle into its correct initial position
        onRest: () => {
            setShouldShow(true);
        },
        config: animationConfig,
        immediate: !shouldShow || !shouldAnimate,
    });

    return (
        <div className={`${styles['switch']} ${disabled && styles[`switch-disabled`]}`}>
            <animated.input
                id={switchId}
                ref={inputRef}
                type="checkbox"
                onClick={() => {
                    setLocallyChecked(!locallyChecked);
                }}
                checked={locallyChecked}
                className={`${styles['checkbox']} ${styles[`checkbox-${size}`]}`}
                disabled={disabled}
                style={animatedCheckboxStyle}
                onChange={event => {
                    onChange && onChange(event.target.checked);
                }}
            />

            {label && (
                <label className={`${styles['label']} ${styles[`label-${size}`]}`} htmlFor={switchId}>
                    {label}
                </label>
            )}
        </div>
    );
}

export default Switch;
