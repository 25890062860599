import { Button, createForm, CardContainer } from '@application-production-system/react';
import { useAppSelector } from '../../hooks';
import { selectUserOptions } from '../../store/userSlice';
import styles from './AuditLogPage.module.scss';
import { AuditLogFilterForm, sortByOptions, sortDirectionOptions } from './types';

const Form = createForm<AuditLogFilterForm>();

const doesNotContainComma = (value: string) => {
    if (value == undefined) {
        console.log("UNDEFINED");
    }
    if (value.includes(",")) {
        return "Value cannot contain a comma";
    }
}

interface AuditLogFormProps {
    onSubmit: () => void;
    formData: AuditLogFilterForm;
    setFormData: (data: AuditLogFilterForm) => void;
    loading: boolean;
}

export function AuditLogForm({onSubmit, formData, setFormData, loading}: AuditLogFormProps) {

    const userOptions = useAppSelector(selectUserOptions);

    return (
        <CardContainer title="Filter">
            <Form.Form onSubmit={onSubmit} data={formData} setData={setFormData}>
                <div className={styles["form-container"]}>
                    <Form.Entry for="email" label="Email" validate={doesNotContainComma} />
                    <Form.Entry for="emailFrom" label="Email From" validate={doesNotContainComma} />
                    <Form.Entry for="emailTo" label="Email To" validate={doesNotContainComma} />
                    <Form.Entry for="inspectionId" label="Inspection ID" validate={doesNotContainComma} />
                    <Form.Entry for="name" label="Name" validate={doesNotContainComma} />
                    <Form.Select for="user" label="User" validate={doesNotContainComma}
                        options={userOptions} />
                    <Form.Select for="authUser" label="Auth User" validate={doesNotContainComma}
                        options={userOptions} />
                    <Form.Select for="sortBy" label="Sort By" validate={doesNotContainComma}
                        options={sortByOptions}/>
                    <Form.Select for="sortOrder" label="Sort Order" validate={doesNotContainComma}
                        options={sortDirectionOptions}/>
                </div>
                <div className={styles["middle-div-container"]}>
                    <div style={{marginTop: 10}}>
                        <Button
                            disabled={loading}
                            title={loading ? "Loading..." : "Apply"}
                            type="submit"
                        />
                    </div>
                </div>
            </Form.Form>
        </CardContainer>
    );
}
