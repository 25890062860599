import { useContext } from 'react';
import GroupContext from '../components/Sidebar/Group/GroupContext';
import SidebarContext from '../components/Sidebar/SidebarContext';

function useLinkFormatting() {
    const sidebarContext = useContext(SidebarContext);
    const sectionContext = { provider: 'sidebar' };
    const groupContext = useContext(GroupContext);
    // console.info(groupContext);
    // console.info(sidebarContext);

    const shouldApplyGroupStyles = !!sidebarContext.provider && !!groupContext.provider && !!sectionContext.provider;
    const shouldApplySectionStyles = !!sidebarContext.provider && !!sectionContext.provider && !groupContext.provider;
    const shouldApplyNormalStyles = !!sidebarContext.provider && !sectionContext.provider && !groupContext.provider;

    return [shouldApplyNormalStyles, shouldApplySectionStyles, shouldApplyGroupStyles];
}

export default useLinkFormatting;
