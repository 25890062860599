import styles from './Spinner.module.scss';
import { animated, AnimatedProps, useSpring } from 'react-spring';
import useThemeAnimation from '../../hooks/useThemeAnimation';
import { CSSProperties, useId } from 'react';

interface SpinnerProps {
    size?: 's' | 'm';
    type?: 'normal';
}

function Spinner({ size = 's', type = 'normal' }: SpinnerProps) {
    const [shouldAnimate, animationConfig] = useThemeAnimation();
    const maskId = useId();

    const animatedSpinnerStyle = useSpring({
        from: {
            rotate: '-90deg',
        } as AnimatedProps<CSSProperties>,
        to: {
            rotate: '270deg',
        } as AnimatedProps<CSSProperties>,
        config: { ...animationConfig, duration: 1000 },
        loop: true,
        immediate: !shouldAnimate,
    });

    return (
        <svg className={`${styles['spinner']} ${styles[`spinner-${size}`]}`}>
            <defs>
                <mask id={maskId}>
                    <circle cx="50%" cy="50%" r="50%" stroke="white" fill="none" className={styles['mask']} />
                </mask>
            </defs>
            <g mask={`url(#${maskId})`}>
                <circle cx="50%" cy="50%" r="50%" fill="none" stroke="var(--primary-hover)" strokeWidth="inherit" />
                <animated.circle
                    className={styles['spinner-circle']}
                    style={animatedSpinnerStyle}
                    cx="50%"
                    cy="50%"
                    r="50%"
                    fill="none"
                    stroke="var(--neutral-40)"
                    strokeWidth="inherit"
                    pathLength="100"
                    strokeDasharray="100"
                />
            </g>
        </svg>
    );
}

export default Spinner;
