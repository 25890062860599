import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Icon from '../Icon/Icon';
import styles from './Breadcrumbs.module.scss';

export type HomeCrumb = IconProp | string;

export interface Crumb {
    title: string;
    onClick: () => void;
}

export interface BreadcrumbProps {
    homeCrumb?: HomeCrumb;
    crumbs: Crumb[];
    separator?: string;
    onClickHome?: () => void;
}

export default function Breadcrumbs({
        crumbs,
        homeCrumb,
        separator='/',
        onClickHome=() => {}
    }: BreadcrumbProps
) {
    const createBreadcrumbList = () => {
        const breadcrumbList: JSX.Element[] = [];
        let key = 0;
        if (homeCrumb) {
            breadcrumbList.push(
                <span key={key++} className={styles["separator"]}>{separator}</span>
            );
        }
        for (let i = 0; i < crumbs.length - 1; i++) {
            breadcrumbList.push(
                <a
                    key={key++}
                    className={styles["link"]}
                    onClick={crumbs[i].onClick}
                >
                    {crumbs[i].title}
                </a>
            );
            breadcrumbList.push(
                <span key={key++} className={styles["separator"]}>{separator}</span>
            );
        }
        breadcrumbList.push(
            <span key={key++} className={styles["link-final"]}>
                {crumbs[crumbs.length - 1].title}
            </span>
        );

        return breadcrumbList;
    }

    return (
        <div className={styles.breadcrumbs}>
            {typeof homeCrumb == "object" &&
                <Icon
                    size="sm"
                    icon={homeCrumb}
                    className={styles["home-icon"]}
                    onClick={onClickHome}
                />
            }
            {typeof homeCrumb == "string" &&
                <a
                    className={styles["link"]}
                    onClick={onClickHome}
                >
                    {homeCrumb}
                </a>
            }
            {createBreadcrumbList()}
        </div>
    );

}
