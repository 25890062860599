import styles from './HeaderRow.module.scss';
import useClasses from '../../../utility/useClasses';
import { CSSProperties } from 'react';

export interface HeaderRowProps {
    children?: React.ReactNode;
    className?: string;
    divider?: boolean;
    style?: CSSProperties;
    striped?: boolean;
}

function HeaderRow({ children, divider, style, striped }: HeaderRowProps) {
    const classes = useClasses(styles, {
        row: true,
        striped: striped,
        divider: divider,
    });

    return (
        <tr className={classes} style={style}>
            {children}
        </tr>
    );
}

export default HeaderRow;
