import { isEmail, createForm, CardContainer } from '@application-production-system/react';
import { useNavigate } from 'react-router-dom';
import { AdminService } from '../../api/doc';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { createOrganisation } from '../../store/organisationSlice';
import { selectPlanOptions } from '../../store/planSlice';
import { handleError } from '../../assets/constants';
import { CancelSubmitRow } from '../../components';
import { ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';
import { useState } from 'react';
import { COUNTRIES, COUNTRY_OPTIONS } from '../../model/countries';

interface CreateOrganisationForm {
    name: string;
    address1: string;
    suburb: string;
    postcode: string;
    phone: string;
    region: string;
    plan: string;
    email: string;
    color: string;
    state: string;
    productOwner: boolean;
}

const Form = createForm<CreateOrganisationForm>();

function CreateOrganisationPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const planOptions = useAppSelector(selectPlanOptions);

    const [formData, setFormData] = useState<CreateOrganisationForm>({
        region: "Australia",
        state: "WA"
    } as CreateOrganisationForm)

    const onSubmit = () => {
        AdminService.createOrganisation(formData)
        .then(response => {
            dispatch(createOrganisation(response.organisation));
            navigate(ROUTES.organisationDetails.build({id: response.organisation?.id}));
        })
        .catch(handleError);
    }

    const stateOptions = (COUNTRIES as any)[formData.region];
    const stateRequired = Object.keys(stateOptions).length > 0;

    return (
        <Page title={{page: "Organisations", subPage: "Create Organisation", icon: ["far", "flag"]}}>
            <div style={{maxWidth: "600px"}}>
                <CardContainer title="Create Organisation">
                <Form.Form onSubmit={onSubmit} data={formData} setData={setFormData}>
                    <Form.Entry  required for="name"         label="Name" />
                    <Form.Entry  required for="email"        label="Email" validate={isEmail} />
                    <Form.Entry           for="address1"     label="Address" />
                    <Form.Entry           for="suburb"       label="Suburb" />
                    <Form.Entry           for="postcode"     label="Postcode" />
                    <Form.Select required for="region"       label="Region" options={COUNTRY_OPTIONS} />
                    <Form.Select required={stateRequired}
                                          for="state"        label="State" options={stateOptions} />
                    <Form.Entry           for="phone"        label="Phone" />
                    <Form.Entry           for="color"        label="Colour" />
                    <Form.Select required for="plan"         label="Plan" options={planOptions} />
                    <Form.Checkbox        for="productOwner" label="Product Owner" />
                    <CancelSubmitRow />
                </Form.Form>
                </CardContainer>
            </div>
        </Page>
    );
}

export default CreateOrganisationPage;
