import { useState, useEffect } from 'react';
import { StatisticsService, RenderStats, UsersStatsResponse } from '../../api/doc';
import { ServiceWrapper } from '../../services/DocServiceWrapper';
import { DetailTable, Loader } from '../../components';
import { useParams } from 'react-router-dom';
import { IdPageParams } from '../../routes';
import { Page } from '../../components/Page/Page';

function UserDetailsPage() {
    const [ currentStats, setCurrentStats ] = useState<RenderStats|undefined>(undefined);
    const [ previousStats, setPreviousStats ] = useState<RenderStats|undefined>(undefined);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        setLoading(true)
        refreshStats();
    }, []);

    const { id } = useParams<IdPageParams>();

    const refreshStats = () => {
        setLoading(true)
        ServiceWrapper<UsersStatsResponse>(StatisticsService.getUserStats, [id], (response) => {
            setLoading(false);
            if (response.userStats?.current?.renderStats) {
                setCurrentStats(response.userStats.current.renderStats);
            }
            if (response.userStats?.previous?.renderStats) {
                setPreviousStats(response.userStats.previous.renderStats);
            }
        })
    }

    return loading ? <Loader/> : (
        <Page title={{page: "User Details", subPage: "Stats", icon: ["far", "user"]}}>
            <div>
                <div style={{maxWidth: "800px"}}>
                    <h2>Current Usage Stats</h2>
                    { !currentStats ? "No current stats available" :
                        <DetailTable details={{
                            "Total Render Size": currentStats.documentBytes + " bytes",
                            "Rendered Documents": currentStats.documentCount,
                            "Emailed Documents": currentStats.emailedDocumentCount,
                            "Page Count": currentStats.pageCount,
                            "Failed Renders": currentStats.failedRenderCount,
                            "Client Errors": currentStats.clientErrorCount,
                            "Server Errors": currentStats.serverErrorCount,
                        }}/>
                    }
                    <h2>Previous Usage Stats</h2>
                    { !previousStats ? "No current stats available" :
                        <DetailTable details={{
                            "Total Render Size": previousStats.documentBytes + " bytes",
                            "Rendered Documents": previousStats.documentCount,
                            "Emailed Documents": previousStats.emailedDocumentCount,
                            "Page Count": previousStats.pageCount,
                            "Failed Renders": previousStats.failedRenderCount,
                            "Client Errors": previousStats.clientErrorCount,
                            "Server Errors": previousStats.serverErrorCount,
                        }}/>
                    }
                </div>
            </div>
        </Page>
    );
}

export default UserDetailsPage;
