import { CSSProperties, useEffect, useRef } from 'react';
import useClasses from '../../../utility/useClasses';
import { InputProps } from '..';
import styles from './Select.module.scss';
import Label from '../Label/Label';
import Icon from '../../Icon/Icon';

export interface SelectProps extends InputProps<string|undefined> {
    options?: Partial<Record<string, string>>;
    style?: CSSProperties;
}

function Select({
        label,
        options,
        style,
        error,
        onChange=() => {},
        value,
        ...props
    }: SelectProps
) {
    const classes = useClasses(styles, {
        select: true,
        error: error
    })

    return (
        <div className={styles["col-stack"]} style={style}>
            { label && <Label text={label} required={props.required}/> }
            <div className={styles["row"]}>
                <select
                    {...props}
                    value={value || ""}
                    className={classes}
                    onChange={e => {onChange(e.target.value ? e.target.value : undefined)}}
                >
                    {(!props.required || Object.keys(options || {}).length == 0) &&
                        <option key={-1} value={""}>None</option>
                    }
                    {options &&
                        Object.entries(options).map(([key, value], i) => {
                            return <option key={i} value={key}>{value}</option>
                        })
                    }
                </select>
                <div className={styles["icon-container"]}>
                    <Icon size="sm" className={styles["icon"]}
                          icon={['far', 'angle-down']} />
                </div>
            </div>
            <label className={styles["error-message"]}>
                {error}
            </label>
        </div>
    );
}

export default Select;
