import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { UsersService, UserDetails } from '../api/doc';
import type { RootState } from '.'

interface UserState {
    users: UserDetails[];
    status: number;
    lookup: {[id: string]: UserDetails};
}

const initialState: UserState = {
    lookup: {},
    status: 0,
    users: []
}

interface UpdateUserPayload {
    id?: string;
    newDetails?: UserDetails;
}

export const refreshUsers = createAsyncThunk(
    'user/fetch',
    async (_) => {
        let status = 200;
        const response = await UsersService.findUsers(undefined,undefined,undefined, undefined,'ALL')
            .catch(error => {
                status = error.status;
                return {users: undefined};
            });
        return {
            status: status,
            users: response.users,
        }
    }
)

const updateUserInList = (users: UserDetails[], payload: UpdateUserPayload) => {
    if (payload.id == undefined || payload.newDetails == undefined) {
        return users;
    }
    for (let i = 0; i < users.length; i++) {
        if (users[i].userId === payload.id) {
            users[i] = payload.newDetails;
        }
    }
    return users;
}

const updateUserInLookup = (lookup: {[key: string]: UserDetails}, payload: UpdateUserPayload) => {
    if (payload.id == undefined || payload.newDetails == undefined) {
        return lookup;
    }
    const newLookup = Object.fromEntries(
        Object.entries(lookup)
        .filter((entry, _) => entry[0] !== payload.id)
    );
    newLookup[payload.newDetails.id!] = payload.newDetails;
    return newLookup;
}

const removeUserFromList = (users: UserDetails[], id: string) => {
    return users.filter(user => user.id !== id);
}

const removeUserFromLookup = (lookup: {[key: string]: UserDetails}, id: string) => {
    return Object.fromEntries(
        Object.entries(lookup)
        .filter((entry, _) => entry[0] !== id)
    );
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        createUser: (state, action: PayloadAction<UserDetails|undefined>) => {
            if (action.payload) {
                state.users.push(action.payload);
                state.lookup = {...state.lookup, [action.payload.id!]: action.payload};
            }
        },

        updateUser: (state, action: PayloadAction<UpdateUserPayload>) => {
            state.users = [...updateUserInList(state.users, action.payload)];
            state.lookup = {...updateUserInLookup(state.lookup, action.payload)};
        },

        removeUser: (state, action: PayloadAction<string|undefined>) => {
            if (action.payload != undefined) {
                state.users = removeUserFromList(state.users, action.payload);
                state.lookup = removeUserFromLookup(state.lookup, action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(refreshUsers.fulfilled, (state, action) => {
            state.users = action.payload.users || [];
            state.status = action.payload.status;
            state.lookup = {};
            state.users.forEach((user: UserDetails, _) => {
                state.lookup[user.id!] = user;
            });
        });
    }
});

export const { createUser, updateUser, removeUser } = userSlice.actions;

export const formatUser = (user: UserDetails) => {
    return user.firstName! + " " + user.lastName!;
}

export const selectUser = (state: RootState, id?: string) => state.user.lookup[id!];

export const selectUserStatus = (state: RootState) => state.user.status;

export const selectUsers = (state: RootState) => state.user.users;

export const findUserByEmail = (state: RootState, email: string) => {
    if (!email) {
        return;
    }
    for (let i = 0; i < state.user.users.length; i++) {
        if (state.user.users[i].email === email) {
            return state.user.users[i];
        }
    }
}

export const findUserByUserId = (state: RootState, userId: string) => {
    if (!userId) {
        return;
    }
    for (let i = 0; i < state.user.users.length; i++) {
        if (state.user.users[i].userId === userId) {
            return state.user.users[i];
        }
    }
}

export const selectFormatUser = (state: RootState, id?: string) => {
    if (!id) {
        return undefined;
    }
    const user = state.user.lookup[id];
    if (!user) {
        return undefined;
    }
    return formatUser(user);
}

export const selectUserOptions = (state: RootState) => {
    const userOptions: {[key: string]: string} = {};
    state.user.users.forEach((user, _) => {
        userOptions[user.id!] = formatUser(user);
    });
    return userOptions;
};


export default userSlice.reducer;
