import React from "react"; 
import './Switch.scss';

interface SwitchProps {
  label?: string,
  onChange?: (label: any) => void
};

export function AkSwitch({label, onChange}: SwitchProps)  { 
  return ( 
    <div className="container"> 
      {label}{" "} 
      <div className="toggle-switch" onChange={onChange}> 
        <input type="checkbox" className="checkbox" 
               name={label} id={label} /> 
        <label className="label" htmlFor={label}> 
          <span className="inner" /> 
          <span className="switch" /> 
        </label> 
      </div> 
    </div> 
  ); 
}; 
  
export default AkSwitch;