import { Button, Table, Row, Cell, HeaderRow, HeaderCell } from '@application-production-system/react';
import { useNavigate, useParams } from 'react-router-dom';
import { DetailTable, Loader } from '../../components';
import { useAppSelector } from '../../hooks';
import { selectProduct } from '../../store/productSlice';
import { PlanDetails } from '../../api/doc';
import { IdPageParams, ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';

function ProductDetailsPage() {
    const navigate = useNavigate();

    const { id } = useParams<IdPageParams>();
    const product = useAppSelector((state) => selectProduct(state, id));

    const createPlansTable = (plans: PlanDetails[] | undefined) => {
        return (!plans || !plans.length) ? "No plans to display." : (
            <Table border data={plans} divider={false}>
            <>
                <HeaderRow>
                    <HeaderCell><b>Name</b></HeaderCell>
                    <HeaderCell><b>Code</b></HeaderCell>
                    <HeaderCell><b>Status</b></HeaderCell>
                </HeaderRow>
                {plans.map((plan: PlanDetails, idx: number) => {
                    return (
                        <Row
                            key={idx}
                            onClick={() => {navigate(ROUTES.planDetails.build({id: plan.code}))}}
                            divider={true}
                            striped={idx % 2 === 1}
                        >
                            <Cell>{ plan.name }</Cell>
                            <Cell>{ plan.code }</Cell>
                            <Cell>{
                                plan!.active
                                    ? <span style={{color: "green"}}>Active</span>
                                    : <span style={{color: "red"}}>Inactive</span>
                            }</Cell>
                        </Row>
                    );
                })}
                </>
            </Table>
        )
    }

    return !product ? <Loader/> : (
        <Page title={{page: "Products Details", icon: ['far', 'clipboard']}}>
            <div style={{maxWidth: "800px"}}>
                <h2>Product Details</h2>
                <DetailTable details={{
                    "ID": product.id,
                    "Name": product.name,
                    "Code": product.code,
                    "Colour": product.color,
                    "Logo": product.logoName,
                    "Dashboard URL": product.dashboardURL,
                    "Email From": product.emailFrom,
                    "Link Domain": product.linkDomain,
                    "Email Status": product.emailEnabled
                            ? <span style={{color: "green"}}>Enabled</span>
                            : <span style={{color: "red"}}>Disabled</span>,
                    "Cloud Storage": product.cloudStorageEnabled
                            ? <span style={{color: "green"}}>Enabled</span>
                            : <span style={{color: "red"}}>Disabled</span>,
                    "Status": product.active
                            ? <span style={{color: "green"}}>Active</span>
                            : <span style={{color: "red"}}>Inactive</span>,
                    "Password Reset URL": product.passwordResetURL,
                    "Password Reset Email Subject": (
                        <pre style={{whiteSpace: "pre-wrap"}}>
                            {product.passwordResetEmailSubject}
                        </pre>
                    ),
                    "Password Reset Email Body": (
                        <pre style={{whiteSpace: "pre-wrap"}}>
                            {product.passwordResetEmailBody}
                        </pre>
                    )
                }}/>
                <div style={{display: "flex"}}>
                    <div style={{marginLeft: "auto", marginTop: 10}}>
                        <Button
                            title="Edit"
                            icon={['far', 'edit']}
                            iconPosition="left"
                            onClick={() => {
                                navigate(ROUTES.editProduct.build({id: id}));
                            }}
                        />
                    </div>
                </div>
                <h2>Product Plans</h2>
                {createPlansTable(product.plans)}
            </div>
        </Page>
    );
}

export default ProductDetailsPage;
