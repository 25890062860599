import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
export type { IconProp, IconName, SizeProp } from '@fortawesome/fontawesome-svg-core';

export interface IconProps extends FontAwesomeIconProps {}

export function Icon({ ...props }: IconProps) {
    return <FontAwesomeIcon {...props} />;
}

export default Icon;
