import { CSSProperties, MouseEventHandler, useContext, useState } from 'react';
import Icon from '../../../Icon/Icon';
import { AnimatedProps, animated } from 'react-spring';
import SidebarContext from '../../SidebarContext';
import { LinkProps } from '../Link';
import styles from './GroupLink.module.scss';

type GroupLinkProps = Omit<LinkProps, 'icon'>;

function GroupLink({ title, activeIcon, to, onClick }: GroupLinkProps) {
    const { navigator, location, animationProgress, width } = useContext(SidebarContext);
    const [isHovered, setIsHovered] = useState(false);
    const isActive = to === location.pathname || location.pathname?.startsWith(to + '/');

    const shouldShowActiveIcon = isActive || isHovered;

    const animatedTitleStyle = {
        opacity: animationProgress.to({
            range: [50, 100],
            output: [0, 1],
        }),
        left: animationProgress
            .to({
                range: [100, 0],
                output: [30, width],
            })
            .to(number => `${number}px`),
    } as AnimatedProps<CSSProperties>;

    const onClickHandler: MouseEventHandler<HTMLDivElement> = event => {
        if (to) {
            navigator(to);
        }
        if (onClick) {
            onClick();
        }
        event.stopPropagation();
    };

    return (
        <div
            className={styles['group-link']}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={onClickHandler}
        >
            <animated.div className={styles['title-frame']} style={animatedTitleStyle}>
                <span className={styles['title']}>{title}</span>
            </animated.div>

            <animated.div className={styles['arrow-frame']}>
                <i className={styles['arrow']}>{activeIcon && shouldShowActiveIcon && <Icon icon={activeIcon}/>}</i>
            </animated.div>
        </div>
    );
}

export default GroupLink;
