/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditLogResponse } from '../models/AuditLogResponse';
import type { AuditLogsResponse } from '../models/AuditLogsResponse';
import type { EmailLogResponse } from '../models/EmailLogResponse';
import type { EmailLogsResponse } from '../models/EmailLogsResponse';
import type { MonthlyStatsResponse } from '../models/MonthlyStatsResponse';
import type { RenderLogResponse } from '../models/RenderLogResponse';
import type { RenderLogsResponse } from '../models/RenderLogsResponse';
import type { UsersStatsResponse } from '../models/UsersStatsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StatisticsService {

    /**
     * Get audit log entries
     * @param page The page number
     * @param pageSize The number of records per page
     * @param age The max age using java Period format e.g PT5H
     * @param authUser The userId/email of the user performing audit action
     * @param user The user affected by the action
     * @param detail Comma sep lists of attributes e.g EMAIL=blah@blah.com
     * @param sort Comma sep sort fields e.g timestamp desc, detail.EMAIL asc
     * @returns AuditLogsResponse The audit log entries
     * @throws ApiError
     */
    public static findAuditLogEntries(
        page?: number,
        pageSize?: number,
        age?: string,
        authUser?: string,
        user?: string,
        detail?: string,
        sort?: string,
    ): CancelablePromise<AuditLogsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/stats/auditlog',
            query: {
                'page': page,
                'pageSize': pageSize,
                'age': age,
                'authUser': authUser,
                'user': user,
                'detail': detail,
                'sort': sort,
            },
        });
    }

    /**
     * Get audit log entry
     * @param id
     * @returns AuditLogResponse The audit log entry
     * @throws ApiError
     */
    public static getAuditLogEntry(
        id: string,
    ): CancelablePromise<AuditLogResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/stats/auditlog/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get email log entries
     * @param page
     * @param pageSize
     * @param age
     * @param emailTo
     * @returns EmailLogsResponse The email log entries
     * @throws ApiError
     */
    public static findEmailLogEntries(
        page?: number,
        pageSize?: number,
        age?: string,
        emailTo?: string,
    ): CancelablePromise<EmailLogsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/stats/emaillog',
            query: {
                'page': page,
                'pageSize': pageSize,
                'age': age,
                'emailTo': emailTo,
            },
        });
    }

    /**
     * Get email log entries
     * @param id
     * @returns EmailLogResponse The email log entry
     * @throws ApiError
     */
    public static getEmailLogEntry(
        id: string,
    ): CancelablePromise<EmailLogResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/stats/emaillog/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get statistics for the logged in user
     * @param deviceId
     * @returns MonthlyStatsResponse The user statistics
     * @throws ApiError
     */
    public static getMonthlyUserStats(
        deviceId?: string,
    ): CancelablePromise<MonthlyStatsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/stats/usermonthly',
            query: {
                'deviceId': deviceId,
            },
        });
    }

    /**
     * Get statistics for current organisation
     * @param deviceId
     * @returns MonthlyStatsResponse The org statistics
     * @throws ApiError
     */
    public static getOrganisationStats(
        deviceId?: string,
    ): CancelablePromise<MonthlyStatsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/stats/orgmonthly',
            query: {
                'deviceId': deviceId,
            },
            errors: {
                404: `If org not found`,
            },
        });
    }

    /**
     * Get statistics for current organisation
     * @param deviceId
     * @returns UsersStatsResponse The org statistics
     * @throws ApiError
     */
    public static getOrganisationStats1(
        deviceId?: string,
    ): CancelablePromise<UsersStatsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/stats/orgsummary',
            query: {
                'deviceId': deviceId,
            },
            errors: {
                404: `If org not found`,
            },
        });
    }

    /**
     * Get statistics for current product
     * @returns MonthlyStatsResponse The org statistics
     * @throws ApiError
     */
    public static geProductStats(): CancelablePromise<MonthlyStatsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/stats/productmonthly',
            errors: {
                404: `If product not found`,
            },
        });
    }

    /**
     * Get render log entries
     * @param page
     * @param pageSize
     * @param age
     * @returns RenderLogsResponse The render log entries
     * @throws ApiError
     */
    public static findRenderLogEntries(
        page?: number,
        pageSize?: number,
        age?: string,
    ): CancelablePromise<RenderLogsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/stats/renderlog',
            query: {
                'page': page,
                'pageSize': pageSize,
                'age': age,
            },
        });
    }

    /**
     * Get render log entry
     * @param id
     * @returns RenderLogResponse The render log entry
     * @throws ApiError
     */
    public static getRenderLogEntry(
        id: string,
    ): CancelablePromise<RenderLogResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/stats/renderlog/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get statistics for the logged in user
     * @param deviceId
     * @returns UsersStatsResponse The user statistics
     * @throws ApiError
     */
    public static getCurrentUserStats(
        deviceId?: string,
    ): CancelablePromise<UsersStatsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/stats/usersummary',
            query: {
                'deviceId': deviceId,
            },
        });
    }

    /**
     * Get statistics for the specified user
     * @param userId
     * @param deviceId
     * @returns UsersStatsResponse The user statistics
     * @throws ApiError
     */
    public static getUserStats(
        userId: string,
        deviceId?: string,
    ): CancelablePromise<UsersStatsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/stats/usersummary/{userId}',
            path: {
                'userId': userId,
            },
            query: {
                'deviceId': deviceId,
            },
            errors: {
                404: `If user not found`,
            },
        });
    }

}
