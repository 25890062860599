import { useContext, useEffect } from "react";
import { KeysMatching, useValidator, Validator } from "..";
import Checkbox, { CheckboxProps } from "../../Form/Checkbox/Checkbox";
import SchemaFormContext from '../SchemaFormContext';

export interface SchemaCheckboxProps<T extends object> extends Omit<CheckboxProps, "value" | "error"> {
    for: KeysMatching<T, boolean | undefined>;
    validate?: Validator<string> | Validator<string>[];
};

export function SchemaCheckbox<T extends object>({"for": key, validate, ...props}: SchemaCheckboxProps<T>)
{
    const context = useContext(SchemaFormContext);
    const value = context.getValue(key);
    const error = context.getError(key)

    const validator = useValidator(validate || [], []);

    useEffect(() => {
        context.register(key, !!props.required, validator);
    }, [key, props.required, validator]);

    useEffect(() => {
        if (error) {
            context.validateSelf(key);
        }
    }, [value]);

    const onBlur = () => {
        context.onBlur(key);
        props.onBlur && props.onBlur();
    }

    const onChange = (value?: boolean) => {
        context.setValue(key, value);
        props.onChange && props.onChange(!!value);
    }

    return (
        <Checkbox
            {...props}
            error={error}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
        />
    );
}


