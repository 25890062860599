/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthenticatedURLResponse } from '../models/AuthenticatedURLResponse';
import type { CreateOrganisationDetails } from '../models/CreateOrganisationDetails';
import type { CreatePlanRequest } from '../models/CreatePlanRequest';
import type { CreateProductRequest } from '../models/CreateProductRequest';
import type { DocSvcResponse } from '../models/DocSvcResponse';
import type { LogoUploadForm } from '../models/LogoUploadForm';
import type { OrganisationResponse } from '../models/OrganisationResponse';
import type { OrganisationsResponse } from '../models/OrganisationsResponse';
import type { PlanResponse } from '../models/PlanResponse';
import type { PlansResponse } from '../models/PlansResponse';
import type { ProductResponse } from '../models/ProductResponse';
import type { ProductsResponse } from '../models/ProductsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminService {

    /**
     * Find Organisations
     * @param name
     * @param email
     * @returns OrganisationsResponse Find organisations that match the filter
     * @throws ApiError
     */
    public static findOrganisations(
        name?: string,
        email?: string,
    ): CancelablePromise<OrganisationsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/orgs',
            query: {
                'name': name,
                'email': email,
            },
        });
    }

    /**
     * Create a new Organisation
     * @param requestBody
     * @returns OrganisationResponse The new organisation
     * @throws ApiError
     */
    public static createOrganisation(
        requestBody?: CreateOrganisationDetails,
    ): CancelablePromise<OrganisationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/orgs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `If the fields are not valid`,
            },
        });
    }

    /**
     * Download the logo for the organisation
     * @param orgId
     * @returns any The logo was uploaded
     * @throws ApiError
     */
    public static downloadLogo(
        orgId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/orgs/{orgId}/logo',
            path: {
                'orgId': orgId,
            },
            errors: {
                400: `Incorrect parameters for logo get`,
                403: `Access denied`,
                500: `Failed to download the logo`,
            },
        });
    }

    /**
     * Upload a new logo for the organisation
     * @param orgId
     * @param formData
     * @returns OrganisationResponse The logo was uploaded
     * @throws ApiError
     */
    public static uploadLogo(
        orgId: string,
        formData?: LogoUploadForm,
    ): CancelablePromise<OrganisationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/orgs/{orgId}/logo',
            path: {
                'orgId': orgId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Incorrect parameters for logo upload`,
                403: `Access denied`,
                500: `Failed to upload the logo`,
            },
        });
    }

    /**
     * Delete the logo for the organisation
     * @param orgId
     * @returns DocSvcResponse The logo was uploaded
     * @throws ApiError
     */
    public static deleteLogo(
        orgId: string,
    ): CancelablePromise<DocSvcResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/docsvc/v1/orgs/{orgId}/logo',
            path: {
                'orgId': orgId,
            },
            errors: {
                400: `Incorrect parameters for logo delete`,
                403: `Access denied`,
                500: `Failed to delete the logo`,
            },
        });
    }

    /**
     * Delete the organisation
     * @param orgId
     * @returns DocSvcResponse The org was deleted
     * @throws ApiError
     */
    public static deleteOrganisation(
        orgId: string,
    ): CancelablePromise<DocSvcResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/docsvc/v1/orgs/{orgId}',
            path: {
                'orgId': orgId,
            },
            errors: {
                400: `Incorrect parameters for org delete`,
                403: `Access denied`,
                500: `Failed to delete the org`,
            },
        });
    }

    /**
     * Download the logo for the organisation
     * @param orgId
     * @returns any The logo was uploaded
     * @throws ApiError
     */
    public static downloadLogoUrl(
        orgId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/orgs/{orgId}/logo_url',
            path: {
                'orgId': orgId,
            },
            errors: {
                400: `Incorrect parameters for logo get`,
                403: `Access denied`,
                500: `Failed to download the logo`,
            },
        });
    }

    /**
     * Get Organisation by id or email
     * @param orgId
     * @returns OrganisationResponse Gets the organisation with the specified id
     * @throws ApiError
     */
    public static getOrganisation(
        orgId: string,
    ): CancelablePromise<OrganisationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/orgs/{orgId}',
            path: {
                'orgId': orgId,
            },
            errors: {
                404: `If the Organisation does not exist.`,
            },
        });
    }

    /**
     * Updates an organisation
     * @param orgId
     * @param requestBody
     * @returns OrganisationResponse The new organisation
     * @throws ApiError
     */
    public static updateOrganisation(
        orgId: string,
        requestBody?: CreateOrganisationDetails,
    ): CancelablePromise<OrganisationResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/docsvc/v1/orgs/{orgId}',
            path: {
                'orgId': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `If the fields are not valid`,
            },
        });
    }

    /**
     * Find All Plans
     * @returns PlansResponse Find all plans
     * @throws ApiError
     */
    public static findPlans(): CancelablePromise<PlansResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/plans',
        });
    }

    /**
     * Create a new Plan
     * @param requestBody
     * @returns PlanResponse The new plan
     * @throws ApiError
     */
    public static createPlan(
        requestBody?: CreatePlanRequest,
    ): CancelablePromise<PlanResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/plans',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `If the fields are not valid`,
            },
        });
    }

    /**
     * Get Plan by id or code
     * @param id
     * @returns PlanResponse Gets the Plan with the specified id/code
     * @throws ApiError
     */
    public static getPlan(
        id: string,
    ): CancelablePromise<PlanResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/plans/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `If the Plan does not exist.`,
            },
        });
    }

    /**
     * Updates a plan
     * @param id
     * @param requestBody
     * @returns PlanResponse The updated plan
     * @throws ApiError
     */
    public static updatePlan(
        id: string,
        requestBody?: CreatePlanRequest,
    ): CancelablePromise<PlanResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/docsvc/v1/plans/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `If the fields are not valid`,
            },
        });
    }

    /**
     * Find All Products, that the user has access to view
     * @returns ProductsResponse Find all products
     * @throws ApiError
     */
    public static findProducts(): CancelablePromise<ProductsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/products',
        });
    }

    /**
     * Create a new Product
     * @param requestBody
     * @returns ProductResponse The new product
     * @throws ApiError
     */
    public static createProduct(
        requestBody?: CreateProductRequest,
    ): CancelablePromise<ProductResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/products',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `If the fields are not valid`,
            },
        });
    }

    /**
     * Download the logo for the product
     * @param id
     * @returns any The logo was uploaded
     * @throws ApiError
     */
    public static downloadLogo1(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/products/{id}/logo',
            path: {
                'id': id,
            },
            errors: {
                400: `Incorrect parameters for logo get`,
                403: `Access denied`,
                500: `Failed to download the logo`,
            },
        });
    }

    /**
     * Upload a new logo for the product
     * @param id
     * @param formData
     * @returns OrganisationResponse The logo was uploaded
     * @throws ApiError
     */
    public static uploadLogo1(
        id: string,
        formData?: LogoUploadForm,
    ): CancelablePromise<OrganisationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/products/{id}/logo',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Incorrect parameters for logo upload`,
                403: `Access denied`,
                500: `Failed to upload the logo`,
            },
        });
    }

    /**
     * Delete the logo for the organisation
     * @param id
     * @returns DocSvcResponse The logo was uploaded
     * @throws ApiError
     */
    public static deleteLogo1(
        id: string,
    ): CancelablePromise<DocSvcResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/docsvc/v1/products/{id}/logo',
            path: {
                'id': id,
            },
            errors: {
                400: `Incorrect parameters for logo delete`,
                403: `Access denied`,
                500: `Failed to delete the logo`,
            },
        });
    }

    /**
     * Get a download URL for the product
     * @param id
     * @returns AuthenticatedURLResponse The logo was uploaded
     * @throws ApiError
     */
    public static downloadLogoUrl1(
        id: string,
    ): CancelablePromise<AuthenticatedURLResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/products/{id}/logo_url',
            path: {
                'id': id,
            },
            errors: {
                400: `Incorrect parameters for logo get`,
                403: `Access denied`,
                500: `Failed to download the logo`,
            },
        });
    }

    /**
     * Get Product by id or code
     * @param id
     * @returns ProductResponse Gets the Product with the specified id/code
     * @throws ApiError
     */
    public static getProduct(
        id: string,
    ): CancelablePromise<ProductResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/products/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `If the Product does not exist.`,
            },
        });
    }

    /**
     * Updates a product
     * @param id
     * @param requestBody
     * @returns ProductResponse The updated product
     * @throws ApiError
     */
    public static updateProduct(
        id: string,
        requestBody?: CreateProductRequest,
    ): CancelablePromise<ProductResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/docsvc/v1/products/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `If the fields are not valid`,
            },
        });
    }

}
