/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvatarUploadForm } from '../models/AvatarUploadForm';
import type { CreateUserDetails } from '../models/CreateUserDetails';
import type { CreateUserForm } from '../models/CreateUserForm';
import type { DocAccessKeyResponse } from '../models/DocAccessKeyResponse';
import type { DocSvcResponse } from '../models/DocSvcResponse';
import type { UserDetailsV1Response } from '../models/UserDetailsV1Response';
import type { UserResponse } from '../models/UserResponse';
import type { UsersResponse } from '../models/UsersResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * Change the password for the current user
     * @param formData
     * @returns DocSvcResponse Password Changed
     * @throws ApiError
     */
    public static changePasswordV1(
        formData?: {
            password?: string;
        },
    ): CancelablePromise<DocSvcResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/users/changePassword',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                403: `Access denied`,
            },
        });
    }

    /**
     * Create a user and organisation
     * @param formData
     * @returns DocAccessKeyResponse The access key for the newly created user
     * @throws ApiError
     */
    public static createUserV1(
        formData?: CreateUserForm,
    ): CancelablePromise<DocAccessKeyResponse> {
        console.log("here 2");
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/users/createUser',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                400: `Invalid Parameters`,
                403: `Access denied`,
            },
        });
    }

    /**
     * Disable the current specified user
     * @returns DocSvcResponse Disables the user
     * @throws ApiError
     */
    public static disableUserV1(): CancelablePromise<DocSvcResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/users/disableUser',
            errors: {
                403: `Access denied`,
            },
        });
    }

    /**
     * Enable the current specified user
     * @returns DocSvcResponse The status message
     * @throws ApiError
     */
    public static enableUserV1(): CancelablePromise<DocSvcResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/users/enableUser',
            errors: {
                403: `Access denied`,
            },
        });
    }

    /**
     * Gets the details for the current user
     * @param includeStats
     * @returns UserDetailsV1Response The user details
     * @throws ApiError
     */
    public static getCurrentUserDetailsV1(
        includeStats?: boolean,
    ): CancelablePromise<UserDetailsV1Response> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/users/userDetails',
            query: {
                'includeStats': includeStats,
            },
        });
    }

    /**
     * Resets a users password and emails a link or the new password
     * @param formData
     * @returns DocAccessKeyResponse The status message
     * @throws ApiError
     */
    public static resetPassword(
        formData?: {
            email: string;
            resetPasswordViaUrl: boolean;
        },
    ): CancelablePromise<DocAccessKeyResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/users/forgotPassword',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                403: `Access denied`,
                404: `The user does not exist`,
                500: `Failed to reset the password`,
            },
        });
    }

    /**
     * Updates a User
     * @param requestBody
     * @returns UserResponse The new user
     * @throws ApiError
     */
    public static updateUser(
        requestBody?: CreateUserDetails,
    ): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/users/updateUser',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `If the fields are not valid`,
            },
        });
    }

    /**
     * Find Users
     * @param userId
     * @param firstName
     * @param lastName
     * @param email
     * @param organisationId Or admin can set to ALL
     * @param productCode Or super user can set to ALL
     * @returns UsersResponse Find organisations that match the filter
     * @throws ApiError
     */
    public static findUsers(
        userId?: string,
        firstName?: string,
        lastName?: string,
        email?: string,
        organisationId?: string,
        productCode?: string,
    ): CancelablePromise<UsersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v2/users',
            query: {
                'userId': userId,
                'firstName': firstName,
                'lastName': lastName,
                'email': email,
                'organisationId': organisationId,
                'productCode': productCode,
            },
            errors: {
                400: `If request is invalid`,
            },
        });
    }

    /**
     * Create a new User
     * @param requestBody
     * @returns UserResponse The new user
     * @throws ApiError
     */
    public static createUser(
        requestBody?: CreateUserDetails,
    ): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v2/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `If the fields are not valid`,
            },
        });
    }

    /**
     * Download the avatar for the user
     * @param userId
     * @returns any The avatar data
     * @throws ApiError
     */
    public static downloadAvatar(
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v2/users/{userId}/avatar',
            path: {
                'userId': userId,
            },
            errors: {
                400: `Incorrect parameters for avatar get`,
                403: `Access denied`,
                500: `Failed to download the avatar`,
            },
        });
    }

    /**
     * Upload a new avatar for the user
     * @param userId
     * @param formData
     * @returns UserResponse The avatar was uploaded
     * @throws ApiError
     */
    public static uploadAvatar(
        userId: string,
        formData?: AvatarUploadForm,
    ): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v2/users/{userId}/avatar',
            path: {
                'userId': userId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Incorrect parameters for avatar upload`,
                403: `Access denied`,
                500: `Failed to upload the avatar`,
            },
        });
    }

    /**
     * Delete the avatar for the user
     * @param userId
     * @returns DocSvcResponse The avatar was deleted
     * @throws ApiError
     */
    public static deleteAvatar(
        userId: string,
    ): CancelablePromise<DocSvcResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/docsvc/v2/users/{userId}/avatar',
            path: {
                'userId': userId,
            },
            errors: {
                400: `Incorrect parameters for avatar delete`,
                403: `Access denied`,
                500: `Failed to delete the avatar`,
            },
        });
    }

    /**
     * Delete the user
     * @param userId
     * @returns DocSvcResponse The user was deleted
     * @throws ApiError
     */
    public static deleteUser(
        userId: string,
    ): CancelablePromise<DocSvcResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/docsvc/v2/users/{userId}',
            path: {
                'userId': userId,
            },
            errors: {
                400: `Incorrect parameters for user delete`,
                403: `Access denied`,
                500: `Failed to delete the user`,
            },
        });
    }

    /**
     * Disable the specified user
     * @param userId
     * @returns UserResponse Disables the user
     * @throws ApiError
     */
    public static disableUser(
        userId: string,
    ): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v2/users/{userId}/disable',
            path: {
                'userId': userId,
            },
            errors: {
                404: `If the User does not exist.`,
            },
        });
    }

    /**
     * Download the avatar for the user
     * @param userId
     * @returns any The avatar was uploaded
     * @throws ApiError
     */
    public static downloadAvatarUrl(
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v2/users/{userId}/avatar_url',
            path: {
                'userId': userId,
            },
            errors: {
                400: `Incorrect parameters for avatar get`,
                403: `Access denied`,
                500: `Failed to download the avatar`,
            },
        });
    }

    /**
     * Enable the specified user
     * @param userId
     * @returns UserResponse Enables the user
     * @throws ApiError
     */
    public static enableUser(
        userId: string,
    ): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v2/users/{userId}/enable',
            path: {
                'userId': userId,
            },
            errors: {
                404: `If the User does not exist.`,
            },
        });
    }

    /**
     * Regenerates a user's access key
     * @param userId
     * @returns DocAccessKeyResponse Regenerates and gets the access key of the user with the specified id
     * @throws ApiError
     */
    public static regenerateUserAccessKey(
        userId: string,
    ): CancelablePromise<DocAccessKeyResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v2/users/{userId}/accessKey/generate',
            path: {
                'userId': userId,
            },
            errors: {
                404: `If the User does not exist.`,
            },
        });
    }

    /**
     * Get User by id or email
     * @param userId
     * @returns UserResponse Gets the user with the specified id
     * @throws ApiError
     */
    public static getUser(
        userId: string,
    ): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v2/users/{userId}',
            path: {
                'userId': userId,
            },
            errors: {
                404: `If the User does not exist.`,
            },
        });
    }

    /**
     * Get User Access Key by id or email
     * @param userId
     * @returns DocAccessKeyResponse Gets the access key of the user with the specified id
     * @throws ApiError
     */
    public static getUserAccessKey(
        userId: string,
    ): CancelablePromise<DocAccessKeyResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v2/users/{userId}/accessKey',
            path: {
                'userId': userId,
            },
            errors: {
                404: `If the User does not exist.`,
            },
        });
    }

    /**
     * Updates a User
     * @param id
     * @param requestBody
     * @returns UserResponse The new user
     * @throws ApiError
     */
    public static updateUser1(
        id: string,
        requestBody?: CreateUserDetails,
    ): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/docsvc/v2/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `If the fields are not valid`,
            },
        });
    }

}
