import { Button } from '@application-production-system/react';
import { IoCreate } from 'react-icons/io5';
import { ConsoleIntegration } from '../../api/console';
import { useLocation, useNavigate } from 'react-router-dom';
import { DetailTable } from '../../components';
import { ROUTES } from '../../routes';

interface LocationState {
    integration: ConsoleIntegration;
}

function IntegrationDetailsPage() {
    const location = useLocation();
    const navigate = useNavigate();

    const { integration } = location.state as LocationState;

    return (
        <div style={{maxWidth: "800px"}}>
            <h2>Integration Details</h2>
            <DetailTable details={{
                "ID": integration!.id,
                "Integration ID": integration!.integrationId,
                "Partner ID": integration!.partnerId,
                "Office ID": integration!.officeId,
                "Status": integration!.verified
                        ? <span style={{color: "green"}}>Verified</span>
                        : <span style={{color: "red"}}>Unverified</span>,
                "API Key": <div style={{overflowWrap: "anywhere", fontFamily: "monospace"}}>{integration!.apiKey}</div>
            }}/>
            <div style={{display: "flex"}}>
                <div style={{marginLeft: "auto", marginTop: "10px"}}>
                    <Button
                        title="Edit"
                        icon={['far', 'edit']}
                        iconPosition="left"
                        onClick={() => {
                            navigate(ROUTES.editIntegration.build({id: integration.id}));
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default IntegrationDetailsPage;
