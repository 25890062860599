import { Table, Row, Cell, HeaderRow, HeaderCell, Button } from '@application-production-system/react';
import { useNavigate } from 'react-router-dom';
import { MonthlyStatsResponse, OrganisationDetails, StatisticsService } from '../../api/doc';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { useEffect, useState } from 'react';
import { selectOrganisations, selectOrganisationStatus, refreshOrganisations } from '../../store/organisationSlice';
import { getErrorMessage, handleError } from '../../assets/constants';
import { ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';
import { AkSwitch } from '../../components/Switch-ak/Switch';

const OrganisationsPage = () => {
    const organisations = useAppSelector(selectOrganisations);
    const status = useAppSelector(selectOrganisationStatus);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const [orgs, setOrgs] = useState(organisations);
    const [allOrgs, setAllOrgs] = useState(organisations);
    const [products, setProduct] = useState<any>();
    const [month, setMonth] = useState('');
    const [checked, setChecked] = useState(false);

    function getCurrentMonth(month: number) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        
        return monthNames[month-1];
    }

    function compare(a: any, b:any) {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ){
          return -1;
        }
        if ( a.name.toLowerCase() > b.name.toLowerCase() ){
          return 1;
        }
        return 0;
    }

    const handleSearch = (event:any) => {
        const search = event.target.value;
        if (search === '') {
            if (checked) {
                setOrgs(allOrgs);
            }
            else {
                setOrgs(allOrgs.filter((org) => org.enabled === true));
            }
        }
        else {
            setOrgs(allOrgs.filter((org) => org?.name?.toLowerCase().includes(search.toLowerCase())));
        }
    }

    useEffect(() => {
        StatisticsService.geProductStats()
        .then(response => {
            const products = response;
            setProduct(products);
        })
        .catch(handleError)
    },[])

    useEffect(() => {
        const orgs = organisations.map((o) => {
            let orgDocs = products?.organisations;
            const docDetails = orgDocs?.find((doc: { organisation: { id: string | undefined; }; }) => o.id === doc.organisation.id);
            if (!docDetails?.stats) {
                setMonth(getCurrentMonth(docDetails?.stats[0].month));
            }
            let totalReports = 0;
            let totalPages = 0;
            docDetails?.stats?.forEach((month: { renderStats: { documentCount: number; pageCount: number; }; }) => {
                totalReports += month.renderStats.documentCount;
                totalPages += month.renderStats.pageCount;
            })
            
            return {
                ...o,
                reports: docDetails?.stats[0]?.renderStats.documentCount ?? 0,
                pages: docDetails?.stats[0]?.renderStats.pageCount ?? 0,
                totalReports: totalReports ?? 'Loading...',
                totalPages: totalPages ?? 'Loading...',
            }
        });
        orgs.sort(compare);
        setAllOrgs(orgs);
        setOrgs(orgs.filter((org) => org.enabled === true));
    }, [products])
    
    useEffect(() => {
        dispatch(refreshOrganisations());
    }, [])

    const createOrganisationsTable = () => {
        return status !== 200 ? getErrorMessage(status) : (!organisations.length ? "No organisations to display." : (
            <>
            <input style={{width: '25%', height: '25px', border: '1px solid black', borderRadius: '5px', marginBottom: '10px', paddingLeft: '10px'}} id="search" type="text" placeholder='Search Organisations' onChange={handleSearch} />
            <Table border data={orgs} onSort={setOrgs} divider={false}>
                <>
                <HeaderRow>
                    <HeaderCell sortKey='enabled'><b>Status</b></HeaderCell>
                    <HeaderCell sortKey="name"><b>Name</b></HeaderCell>
                    <HeaderCell sortKey="reports"><b>Reports/Pages</b></HeaderCell>
                    <HeaderCell sortKey="totalReports"><b>Total Reports/Pages</b></HeaderCell>
                    <HeaderCell sortKey="plan"><b>Plan</b></HeaderCell>
                    <HeaderCell sortKey="email"><b>Email</b></HeaderCell>
                </HeaderRow>
                {orgs.map((organisation: OrganisationDetails, idx: number) => {
                    return (
                        <Row
                            key={idx}
                            onClick={() => {
                                navigate(ROUTES.organisationDetails.build({id: organisation.id}))
                            }}
                            divider={true}
                            striped={idx % 2 === 1}
                        >
                            <Cell style={{color: organisation.enabled ? "green" : "red"}}>{ organisation.enabled ? "Active": "Disabled" }</Cell>
                            <Cell>{ organisation.name }</Cell>
                            <Cell>{ organisation.reports === 0 ? 'NA' : organisation.reports + " / " + organisation.pages }</Cell>
                            <Cell>{ organisation.totalReports === 0 ? 'NA' : organisation.totalReports + " / " + organisation.totalPages }</Cell>
                            <Cell>{ organisation.plan }</Cell>
                            <Cell>{ organisation.email }</Cell>
                        </Row>
                    );
                })}
                </>
            </Table>
            </>
        ))
    }

    const showOrgLength = () => {
        return orgs.length;
    }

    const showActivePlans = () => {
        let PIM01 = 0;
        let PIM03 = 0;
        orgs.forEach(o => {
            if (o.plan === "PIM01" && o.enabled) {
                PIM01++;
            }
            else if (o.plan === "PIM03" && o.enabled) {
                PIM03++;
            }
        });
        return "Total Active: PIM01 (" + PIM01 + ") - PIM03 (" + PIM03 + ")";
    }

    const filterOrgs = (e: any) => {
        const checked = e?.nativeEvent?.target?.checked;
        setChecked(checked);
        if (checked) {
            setOrgs(allOrgs);
            return;
        }
        orgs.sort(compare);
        setOrgs(orgs.filter((org) => org.enabled === true));
        return;
    }

    return (
        <Page title={{page: "Organisations", icon: ["far", "flag"]}}>
            <div style={{overflowX: "hidden"}}>
                <div style={{display: "flex"}}>
                    <h2>Total Organisations ({showOrgLength()}) - {showActivePlans()}</h2>
                    <div style={{marginLeft: "auto", marginTop: "auto", marginBottom: "10px", display: "flex", gap: 10}}>
                        <AkSwitch label='Show Disabled' onChange={filterOrgs}/>
                        <Button
                            disabled={status !== 200}
                            title="New User and Organisation"
                            icon={['far', 'plus-circle']}
                            iconPosition="left"
                            onClick={() => {
                                navigate(ROUTES.createOrgAndUserPage.build());
                            }}
                        />
                        <Button
                            disabled={status !== 200}
                            title="New Organisation"
                            icon={['far', 'plus-circle']}
                            iconPosition="left"
                            onClick={() => {
                                navigate(ROUTES.createOrganisation.build());
                            }}
                        />
                    </div>
                </div>
                <div style={{overflowX: "scroll"}}>
                    <div style={{minWidth: "500px"}}>
                        {createOrganisationsTable()}
                    </div>
                </div>
            </div>
        </Page>
    );
}

export default OrganisationsPage;
