import { useContext } from 'react';
import FormContext from '../FormContext';

interface LoadingContextProps {
    children: (loading: boolean) => JSX.Element;
}

export default function({ children }: LoadingContextProps) {
    const context = useContext(FormContext);
    return children(context.loading());
}
