import { createForm, CardContainer } from '@application-production-system/react';
import { useNavigate, useParams } from 'react-router-dom';
import { AdminService } from '../../api/doc';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { selectProduct, updateProduct } from '../../store/productSlice';
import { handleError } from '../../assets/constants';
import { CancelSubmitRow } from '../../components';
import { IdPageParams, ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';
import { useState } from 'react';

interface LocationState {
    productCode: string;
}

interface EditProductForm {
    name: string;
    code: string;
    color: string;
    active: boolean;
    emailFrom: string;
    emailEnabled: boolean;
    logoName: string;
    cloudStorageEnabled: boolean;
    dashboardURL: string;
    linkDomain: string;
    passwordResetURL: string;
    passwordResetEmailSubject: string;
    passwordResetEmailBody: string;
}

const Form = createForm<EditProductForm>();

function EditProductPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { id } = useParams<IdPageParams>();
    const product = useAppSelector((state) => selectProduct(state, id));

    const [formData, setFormData] = useState<EditProductForm>(product as EditProductForm);

    const onSubmit = () => {
        AdminService.updateProduct(product.id!, formData)
            .then(response => {
                dispatch(updateProduct({
                    productCode: product.code,
                    newDetails: response.product
                }));
                navigate(ROUTES.productDetails.build({id: id}));
            })
            .catch(handleError);
    }

    return (
        <Page title={{page: "Products Details", subPage: "Edit Product Details", icon: ['far', 'shopping-bag']}}>
            <div style={{maxWidth: "600px"}}>
                <CardContainer title="Edit Product">
                    <Form.Form onSubmit={onSubmit} data={formData} setData={setFormData}>
                        <Form.Entry required for="name" label="Name" />
                        <Form.Entry required for="code" label="Code" />
                        <Form.Entry required for="color" label="Colour" />
                        <Form.Entry for="logoName" label="Logo" disabled={true} />
                        <Form.Entry for="dashboardURL" label="Dashboard URL" />
                        <Form.Entry required for="emailFrom" label="Email From" />
                        <Form.Entry for="linkDomain" label="Link Domain" />
                        <Form.Entry for="passwordResetURL" label="Password Reset URL" />
                        <Form.Entry for="passwordResetEmailSubject" label="Password Reset Email Subject" />
                        <Form.TextArea for="passwordResetEmailBody" label="Password Reset Email Body" />
                        <Form.Checkbox for="emailEnabled" label="Email Enabled" />
                        <Form.Checkbox for="cloudStorageEnabled" label="Cloud Storage Enabled" />
                        <Form.Checkbox for="active" label="Active" />

                        <CancelSubmitRow title="Save Changes" />
                    </Form.Form>
                </CardContainer>
            </div>
        </Page>
    );
}

export default EditProductPage;
