import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AdminService, ProductDetails } from '../api/doc';
import type { RootState } from '.'

interface ProductState {
    products: ProductDetails[];
    status: number;
    lookup: {[productCode: string]: ProductDetails};
}

const initialState: ProductState = {
    status: 0,
    lookup: {},
    products: []
}

interface UpdateProductPayload {
    productCode?: string;
    newDetails?: ProductDetails;
}

export const refreshProducts = createAsyncThunk(
    'product/fetch',
    async (_) => {
        let status = 200;
        const response = await AdminService.findProducts()
            .catch(error => {
                status = error.status;
                return {products: undefined};
            });
        console.log(response.products);
        return {
            status: status,
            products: response.products,
        }
    }
)

const updateProductInList = (products: ProductDetails[], payload: UpdateProductPayload) => {
    if (payload.productCode == undefined || payload.newDetails == undefined) {
        return products;
    }
    for (let i = 0; i < products.length; i++) {
        if (products[i].code === payload.productCode) {
            products[i] = payload.newDetails;
        }
    }
    return products;
}

const updateProductInLookup = (lookup: {[key: string]: ProductDetails}, payload: UpdateProductPayload) => {
    if (payload.productCode == undefined || payload.newDetails == undefined) {
        return lookup;
    }
    const newLookup = Object.fromEntries(
        Object.entries(lookup)
        .filter((entry, _) => entry[0] !== payload.productCode)
    );
    newLookup[payload.newDetails.code!] = payload.newDetails;
    return newLookup;
}

const removeProductFromList = (products: ProductDetails[], productCode: string) => {
    return products.filter(product => product.code !== productCode);
}

const removeProductFromLookup = (lookup: {[key: string]: ProductDetails}, productCode: string) => {
    return Object.fromEntries(
        Object.entries(lookup)
        .filter((entry, _) => entry[0] !== productCode)
    );
}

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        createProduct: (state, action: PayloadAction<ProductDetails|undefined>) => {
            if (action.payload) {
                state.products.push(action.payload);
                state.lookup = {...state.lookup, [action.payload.code!]: action.payload};
            }
        },

        updateProduct: (state, action: PayloadAction<UpdateProductPayload>) => {
            state.products = updateProductInList(state.products, action.payload);
            state.lookup = updateProductInLookup(state.lookup, action.payload);
        },

        removeProduct: (state, action: PayloadAction<string|undefined>) => {
            if (action.payload != undefined) {
                state.products = removeProductFromList(state.products, action.payload);
                state.lookup = removeProductFromLookup(state.lookup, action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(refreshProducts.fulfilled, (state, action) => {
            state.products = action.payload.products || [];
            state.status = action.payload.status;
            state.lookup = {};
            state.products.forEach((product: ProductDetails, _) => {
                state.lookup[product.code!] = product;
            });
        });
    }
});

export const { createProduct, updateProduct, removeProduct } = productSlice.actions;

export const selectProduct = (state: RootState, productCode?: string) => state.product.lookup[productCode!];

export const selectProducts = (state: RootState) => state.product.products;

export const selectProductStatus = (state: RootState) => state.product.status;

export const formatProduct = (product: ProductDetails) =>
    "[" + product.code! + "] " + product.name!

export const selectFormatProduct = (state: RootState, productId?: string) => {
    if (!productId) {
        return undefined;
    }
    const product = state.product.lookup[productId];
    if (!product) {
        return undefined;
    }
    return formatProduct(product);
}

export const selectProductOptions = (state: RootState) => {
    console.log("SELECT PRODUCT OPTIONS");
    const productOptions: any = {};
    state.product.products.forEach((product) => {
        productOptions[product.code!] = formatProduct(product);
    });
    return productOptions;
};

export default productSlice.reducer;
