import { useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from './store'
import { PageTitle, selectPageTitle, setPageTitle } from './store/sessionSlice';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export function usePageTitle(title?: PageTitle) {
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (title) {
            dispatch(setPageTitle(title));
        }
    }, []);
}
