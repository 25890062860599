import { SchemaForm } from "./SchemaForm";
import { SchemaEntry } from './Components/Entry';
import { SchemaCheckbox } from './Components/Checkbox';
import { useEffect, useRef, useState } from "react";
import { SchemaSelect, selectBuilder } from "./Components/Select";
import { SchemaFileUpload } from "./Components/FileUpload";
import { SchemaTextArea } from "./Components/TextArea";

// export type KeysMatching<T,V> = keyof { [ P in keyof T as T[P] extends V ? P : never ] : P };
export type KeysMatching<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T];

export type Validator<T> = (value: T) => string | undefined;

export const Schema = {
    Form: SchemaForm,
    Entry: SchemaEntry
}

export const createForm = <T extends Record<string, any>>() => {
    return {
        Form: SchemaForm<T>,
        Entry: SchemaEntry<T>,
        TextArea: SchemaTextArea<T>,
        Select: selectBuilder<T>(),
        Checkbox: SchemaCheckbox<T>,
        FileUpload: SchemaFileUpload<T>
    };
}

function useDidUpdateEffect(fn: () => void, deps: React.DependencyList[]) {
    const didMountRef = useRef(false);
    useEffect(() => {
        if (didMountRef.current) { 
            return fn();
        }
        didMountRef.current = true;
    }, deps);
}

export function useValidator<T>(callbacks: Validator<T> | Validator<T>[], deps: React.DependencyList[]) {
    const [_callbacks, _setCallbacks] = useState<Validator<T>[]>
        (([] as Validator<T>[]).concat(callbacks));

    useDidUpdateEffect(() => {
        _setCallbacks(([] as Validator<T>[]).concat(callbacks));
    }, deps);

    return _callbacks;
}

