import { createContext } from 'react';
import defaultColors, { ColorConfig } from '../../theme/color';
import defaultSpacing, { SpacingConfig } from '../../theme/spacing';
import { SpringConfig } from 'react-spring';
import defaultAnimationConfig from '../../theme/animation';

const ThemeContext = createContext({
    animated: false,
    animationConfig: defaultAnimationConfig as SpringConfig,
    colors: defaultColors as ColorConfig,
    spacing: defaultSpacing,
    setColors: (_colors: ColorConfig) => {},
    setSpacing: (_spacing: SpacingConfig) => {},
});

export default ThemeContext;
