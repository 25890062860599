const defaultSpacing = {
    xs: '4px',
    s: '8px',
    m: '16px',
    l: '24px',
    xl: '32px',
    xxl: '48px',
};

export type SpacingConfig = typeof defaultSpacing;
export type GenericSpacingConfig = { [key in string]: string };

export default defaultSpacing;
