import { Table, Row, Cell, HeaderRow, HeaderCell, Button } from '@application-production-system/react';
import { useNavigate } from 'react-router-dom';
import { ProductDetails } from '../../api/doc';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { selectProducts, selectProductStatus, refreshProducts } from '../../store/productSlice';
import { useEffect } from 'react';
import { getErrorMessage } from '../../assets/constants';
import { ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';

export default function ProductsPage() {
    const products = useAppSelector(selectProducts);
    const navigate = useNavigate();

    const status = useAppSelector(selectProductStatus);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(refreshProducts());
    }, [])

    const createProductsTable = () => {
        return status !== 200 ? getErrorMessage(status) : (!products.length ? "No products to display." : (
            <Table border data={products} divider={false} >
            <>
                <HeaderRow>
                    <HeaderCell><b>Name</b></HeaderCell>
                    <HeaderCell><b>Code</b></HeaderCell>
                    <HeaderCell><b>Status</b></HeaderCell>
                </HeaderRow>
                {products.map((product: ProductDetails, idx: number) => {
                    return <Row
                        key={idx}
                        onClick={() => {
                            navigate(ROUTES.productDetails.build({id: product.code}));
                        }}
                        divider={true}
                        striped={idx % 2 === 1}
                    >
                        <Cell>{ product.name }</Cell>
                        <Cell>{ product.code }</Cell>
                        <Cell>{
                            product.active
                                ? <span style={{color: "green"}}>Active</span>
                                : <span style={{color: "red"}}>Inactive</span>
                        }</Cell>
                    </Row>
                })}
            </>
            </Table>
        ))
    }

    return (
        <Page title={{page: "Products", icon: ['far', 'shopping-bag']}}>
            <div style={{overflowX: "hidden"}}>
                <div style={{display: "flex"}}>
                    <h2>Products</h2>
                    <div style={{marginLeft: "auto", marginTop: "auto", marginBottom: "10px"}}>
                        <Button
                            disabled={status !== 200}
                            title="New Product"
                            icon={['far', 'plus-circle']}
                            iconPosition="left"
                            onClick={() => {
                                navigate(ROUTES.createProduct.build());
                            }}
                        />
                    </div>
                </div>
                <div style={{overflowX: "scroll"}}>
                    <div style={{minWidth: "500px"}}>
                        {createProductsTable()}
                    </div>
                </div>
            </div>
        </Page>
    );
}
