import { Validator } from '.';

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const isEmail: Validator<string> = (value: string) => {
    console.log("CHECKING");
    const x = emailRegex.test(value);
    console.log("DONE");
    if (!x) {
        return "This field must be a valid email";
    }
}

const alphaNumericRegex = /^[A-Za-z0-9]*$/;
export const isAlphaNumeric: Validator<string> = (value: string) => {
    if (!alphaNumericRegex.test(value)) {
        return "This field must only contain letters and numbers";
    }
}

const alphaRegex = /^[A-Za-z]*$/;
export const isAlpha: Validator<string> = (value: string) => {
    if (!alphaRegex.test(value)) {
        return "This field must only contain letters";
    }
}

export const isRegex = (regex: RegExp, errorMessage: string): Validator<string> => {
    return (value: string) => {
        if (!regex.test(value)) {
            return errorMessage;
        }
    }
}

export const isLengthBetween = (min: number, max: number): Validator<string> => {
    return (value: string) => {
        if (value.length < min) {
            return "This field cannot have a length less than " + min;
        }
        if (value.length > max) {
            return "This field cannot have a length greater than " + max;;
        }
    }
}

export const isBetween = (min: number, max: number): Validator<number> => {
    return (value: number) => {
        if (value < min) {
            return "This field cannot be less than " + min;
        }
        if (value > max) {
            return "This field cannot be greater than " + max;
        }
    }
}

const SizeUnits = {
    B:      1,
    KB:  1000,
    KiB: 1024,
    MB:  1000 ** 2,
    MiB: 1024 ** 2,
    GB:  1000 ** 3,
    GiB: 1024 ** 3
}

export const maxFileSize = (max: number, unit: keyof typeof SizeUnits): Validator<File> => {
    return (file: File) => {
        if (file.size > max * SizeUnits[unit]) {
            return "File exceeds max file size of " + max + unit;
        }
    }
}


