import { Table, Row, Cell, HeaderRow, HeaderCell, Button } from '@application-production-system/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { selectUsers, selectUserStatus, refreshUsers } from '../../store/userSlice';
import { getErrorMessage, titleCaseList } from '../../assets/constants';
import { ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';
import { selectOrganisationLookup } from '../../store/organisationSlice';
import {AkSwitch} from '../../components/Switch-ak/Switch';

function UsersPage() {

    const users = useAppSelector(selectUsers);
    const orgLookup = useAppSelector(selectOrganisationLookup);
    const status = useAppSelector(selectUserStatus);
    const dispatch = useAppDispatch();

    const [data, setData] = useState(users);
    const [allData, setAllData] = useState(users);
    const [checked, setChecked] = useState(false);

    function compare(a: any, b:any) {
        if ( a.firstName.toLowerCase() < b.firstName.toLowerCase() ){
          return -1;
        }
        if ( a.firstName.toLowerCase() > b.firstName.toLowerCase() ){
          return 1;
        }
        return 0;
    }

    useEffect(() => {
        const data = users.map((u) => {
            let org;
            if (u.organisation) {
                org = orgLookup[u.organisation];
            }
            return {
                ...u,
                role: u.roles ? u.roles[0] : "",
                organisation: org?.name || "",
                productCode: org?.plan || "",
            };
        });
        data.sort(compare);
        setAllData(data);
        setData(data.filter((user) => user.enabled === true));
    }, [users])

    useEffect(() => {
        dispatch(refreshUsers());
    }, [])

    const filterUsers = (e: any) => {
        const checked = e?.nativeEvent?.target?.checked;
        setChecked(checked);
        if (checked) {
            setData(allData);
            return;
        }
        const data = users.map((u) => {
            let org;
            if (u.organisation) {
                org = orgLookup[u.organisation];
            }
            return {
                ...u,
                role: u.roles ? u.roles[0] : "",
                organisation: org?.name || "",
                productCode: org?.plan || "",
            };
        });
        data.sort(compare);
        setData(data.filter((user) => user.enabled === true));
        return;
    }

    const handleSearch = (event:any) => {
        const search = event.target.value;
        if (search === '') {
            if (checked) {
                setData(allData);
            }
            else {
                setData(allData.filter((user) => user.enabled === true));
            }
        }
        else {
            setData(allData.filter((user) => (user?.firstName + ' ' + user?.lastName).toLowerCase().includes(search.toLowerCase())));
        }
    }

    const navigate = useNavigate();

    const createUsersTable = () => {
        return status !== 200 ? getErrorMessage(status) : (!users.length ? "No users to display." : (
            <>
            <input style={{width: '25%', height: '25px', border: '1px solid black', borderRadius: '5px', marginBottom: '10px', paddingLeft: '10px'}} id="search" type="text" placeholder='Search Users' onChange={handleSearch} />
            <Table border data={data} onSort={setData} divider={false}>
            <>
                <HeaderRow>
                    <HeaderCell sortKey="productCode"><b>Plan</b></HeaderCell>
                    <HeaderCell sortKey="enabled"><b>Status</b></HeaderCell>
                    <HeaderCell sortKey="firstName"><b>Name</b></HeaderCell>
                    <HeaderCell sortKey="organisation"><b>Organisation</b></HeaderCell>
                    <HeaderCell sortKey="role"><b>Role</b></HeaderCell>
                </HeaderRow>
                {data.map((user, idx) => {
                    return <Row
                        key={idx}
                        onClick={() => {
                            navigate(ROUTES.userDetails.build({id: user.id}));
                        }}
                        divider={true}
                        striped={idx % 2 === 1}
                    >
                        <Cell>{ user.productCode }</Cell>
                        <Cell style={{color: user.enabled ? "green" : "red"}}>{ user.enabled ? "Active": "Disabled" }</Cell>
                        <Cell>{ user.firstName + " " + user.lastName }</Cell>
                        <Cell>{ user.organisation }</Cell>
                        <Cell>{ user.roles ? titleCaseList(user.roles) : "None" }</Cell>
                    </Row>
                })}

        </>
            </Table>
            </>
        ))
    }

    const showUsrLength = () => {
        return data.length;
    }

    return (
        <Page title={{page: "Users", icon: ['far', 'user']}}>
            <div style={{overflowX: "hidden"}}>
                <div style={{display: "flex"}}>
                    <h2>Users ({showUsrLength()})</h2>
                    <div style={{display: 'flex', gap:10, marginLeft: "auto", marginTop: "auto", marginBottom: "10px"}}>
                        <AkSwitch label='Show Disabled' onChange={filterUsers}/>
                        <Button
                            disabled={status !== 200}
                            title="New User"
                            icon={['far', 'plus-circle']}
                            iconPosition="left"
                            onClick={() => {
                                navigate(ROUTES.createUser.build());
                            }}
                        />
                    </div>
                </div>
                <div style={{overflowX: "scroll"}}>
                    <div style={{minWidth: "500px"}}>
                        {createUsersTable()}
                    </div>
                </div>
            </div>
        </Page>
    );
}

export default UsersPage;
