import { CardContainer, createForm } from '@application-production-system/react';
import { useNavigate } from 'react-router-dom';
import { AdminService } from '../../api/doc';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { createPlan } from '../../store/planSlice';
import { selectProductOptions } from '../../store/productSlice';
import { handleError } from '../../assets/constants';
import { CancelSubmitRow } from '../../components';
import { ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';
import { useState } from 'react';

interface CreatePlanForm {
    name: string;
    code: string;
    productCode: string;
    emailEnabled: boolean;
    cloudStorageEnabled: boolean;
    active: boolean;
}

const Form = createForm<CreatePlanForm>();

function CreatePlanPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const productOptions = useAppSelector(selectProductOptions);

    const [formData, setFormData] = useState<CreatePlanForm>({} as CreatePlanForm);

    const onSubmit = () => {
        AdminService.createPlan(formData)
            .then(response => {
                dispatch(createPlan(response.plan));
                navigate(ROUTES.planDetails.build({id: response.plan?.code}));
            })
            .catch(handleError);
    }

    return (
        <Page title={{page: "Plans", subPage: "Create Plan", icon: ['far', 'clock']}}>
            <div style={{maxWidth: "600px"}}>
            <CardContainer title="Create Plan">
                    <Form.Form onSubmit={onSubmit} data={formData} setData={setFormData}>
                        <Form.Entry  required for="name"        label="Name" />
                        <Form.Entry  required for="code"        label="Code" />
                        <Form.Select required for="productCode" label="Product Code" options={productOptions} />
                        <Form.Checkbox for="emailEnabled" label="Email Enabled" />
                        <Form.Checkbox for="cloudStorageEnabled" label="Cloud Storage Enabled" />
                        <Form.Checkbox for="active"      label="Active"/>
                        <CancelSubmitRow />
                    </Form.Form>
                </CardContainer>
            </div>
        </Page>
    );
}

export default CreatePlanPage;
