import { IconProp, SizeProp } from '../../Icon/Icon';
import useLinkStyle from '../../../hooks/useLinkStyle';
import GroupLink from './GroupLink/GroupLink';
import NormalLink from './NormalLink/NormalLink';

export interface LinkProps {
    title: string;
    to?: string;
    icon?: IconProp;
    iconSize?: SizeProp,
    activeIcon?: IconProp;
    onClick?: () => void
}

function Link({
        title,
        icon,
        iconSize,
        activeIcon,
        to,
        onClick=() => {}
    }: LinkProps
) {

    const [_, useSectionStyle, useGroupStyle] = useLinkStyle();

    if (useGroupStyle) {
        return (
            <GroupLink
                title={title}
                to={to}
                activeIcon={activeIcon}
                onClick={onClick}
           />
        );
    }
    else if (useSectionStyle) {
        return (
            <NormalLink
                title={title}
                to={to}
                iconSize={iconSize}
                icon={icon}
                activeIcon={activeIcon}
                onClick={onClick}
            />
        );
    }
    else {
        return (
            <NormalLink
                title={title}
                to={to}
                icon={icon}
                iconSize={iconSize}
                activeIcon={activeIcon}
                onClick={onClick}
            />
        );
    }
}

export default Link;
