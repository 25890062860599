import styles from './Fieldset.module.scss';

import useClasses from '../../utility/useClasses';
import { CSSProperties } from 'react';

export type FieldsetVariant = 'primary' | 'secondary' | 'danger' | 'warning' | 'info' | 'success' | 'ghost';

export interface FieldsetProps {
    title?: string;
    children?: React.ReactNode;
    variant?: FieldsetVariant;
    style?: CSSProperties;
}

function Fieldset({ title, children, variant='ghost', style }: FieldsetProps) {
    
    const classes = useClasses(styles, {
        container: true,
        [variant]: true,
    });

    return (
        <fieldset className={classes} style={style}>
            <legend>{title}</legend>
            {children}
        </fieldset>
    );
}

export default Fieldset;
