import { createContext } from 'react';

export interface TableContextProps {
    sortTable: (sortKey: string | number, reverse: boolean) => void,
    currentSortKey?: string | number
}

const TableContext = createContext<TableContextProps>({
    sortTable: (sortKey: string | number, reverse: boolean) => {},
    currentSortKey: undefined
});

export default TableContext;
