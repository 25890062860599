import styles from './General.module.scss';

interface GeneralProps {
    title?: React.ReactNode;
    children?: React.ReactNode;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    flex?: number;
}

function General({ title, children, header, footer, flex }: GeneralProps) {
    const shouldShowHeader = header;
    const shouldShowContent = title || children || header;

    return (
        <div className={styles['general']}>
            {shouldShowHeader && <div className={styles['header']}>{header}</div>}
            {shouldShowContent && (
                <div className={styles['content']}>
                    <div className={styles['title']}>{title}</div>
                    <div className={styles['children']}>{children}</div>
                    <div className={styles['footer']}>{footer}</div>
                </div>
            )}
        </div>
    );
}

export default General;
