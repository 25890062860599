import { CSSProperties, MouseEventHandler, useContext, useState } from 'react';
import styles from './Group.module.scss';
import { animated, AnimatedProps, useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';
import useThemeAnimation from '../../../hooks/useThemeAnimation';
import SidebarContext from '../SidebarContext';
import GroupContext from './GroupContext';
import { LinkProps } from '../Link/Link';
import { Icon, IconProp } from '../../Icon/Icon';

type LinkNode = React.ReactElement<LinkProps>[] | React.ReactElement<LinkProps>;

export interface GroupProps {
    title: string;
    icon?: IconProp;
    children?: LinkNode;
}

function Group({ title, icon, children }: GroupProps) {
    const { animationProgress, minWidth } = useContext(SidebarContext);
    const [expanded, setExpanded] = useState(false);
    const [contentRef, { height }] = useMeasure();
    const [shouldAnimate, animationConfig] = useThemeAnimation();

    const toggleExpanded: MouseEventHandler<HTMLDivElement> = event => {
        setExpanded(!expanded);

        event.stopPropagation();
    };

    const animatedContentStyle = useSpring({
        to: {
            height: expanded ? height : 0,
        } as AnimatedProps<CSSProperties>,
        config: animationConfig,
        immediate: !shouldAnimate,
    });

    const animatedIconStyle = {
        transform: animationProgress
            .to({
                range: [100, 0],
                output: [0, 50],
            })
            .to(number => `translateX(-${number}%)`),
        left: animationProgress
            .to({
                range: [100, 0],
                // 18px = 8px link padding + 10px icon padding
                output: [18, minWidth - 18],
            })
            .to(number => `${number / 2}px`),
    } as AnimatedProps<CSSProperties>;

    const animatedTitleStyle = {
        opacity: animationProgress.to({
            range: [50, 100],
            output: [0, 1],
        }),
        left: animationProgress
            .to({
                range: [100, 0],
                // link padding + left icon padding + icon + right icon padding
                output: [10 + 8 + 16 + 8, minWidth],
            })
            .to(number => `${number}px`),
    } as AnimatedProps<CSSProperties>;

    return (
        <GroupContext.Provider value={{ expanded: expanded, provider: 'group' }}>
            <div className={styles['group']} onClick={toggleExpanded}>
                <div className={styles['group-header']}>
                    <animated.div className={styles['icon-frame']} style={animatedIconStyle}>
                        <i className={styles['icon']}>{icon && <Icon icon={icon} />}</i>
                    </animated.div>

                    <animated.div className={styles['title-frame']} style={animatedTitleStyle}>
                        <span className={styles['title']}>{title}</span>
                    </animated.div>
                </div>

                <animated.div className={styles['group-content']} style={animatedContentStyle}>
                    <div className={styles['content']} ref={contentRef}>
                        {children}
                    </div>
                </animated.div>
            </div>
        </GroupContext.Provider>
    );
}

export default Group;
