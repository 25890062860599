import { Button, CardContainer, Cell, createForm, HeaderCell, HeaderRow, Icon, maxFileSize, Modal, Row, Table } from '@application-production-system/react';
import styles from "./OrganisationDetailsPage.module.scss";
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AdminService, MonthlyStatsResponse, MonthStats, StatisticsService, UserDetails, UsersService } from '../../api/doc';
import { DetailTable, Loader } from '../../components';
import { Page } from '../../components/Page/Page';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { IdPageParams, ROUTES } from '../../routes';
import { selectOrganisation, updateOrganisation } from '../../store/organisationSlice';
import { selectUsers, selectUserStatus, refreshUsers } from '../../store/userSlice';
import { selectFormatPlan } from '../../store/planSlice';
import { OpenAPI as docOpenApi } from "../../api/doc/core/OpenAPI";
import { LogoView } from '../../components/LogoView/LogoView';
import { handleError } from '../../assets/constants';

interface ChangeLogoForm {
    orgLogo: FileList;
}

const Form = createForm<ChangeLogoForm>();

function OrganisationDetailsPage() {
    const { id } = useParams<IdPageParams>();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const organisation = useAppSelector((state) => selectOrganisation(state, id));
    const plan = useAppSelector((state) => selectFormatPlan(state, organisation?.plan));
    

    const [logoModalVisible, setLogoModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [disableModalVisible, setDisableModalVisible] = useState(false);
    const [enableModalVisible, setEnableModalVisible] = useState(false);
    const [products, setProduct] = useState<any>([]);
    const [totalReports, setReports] = useState('');
    const [totalPages, setPages] = useState('');
    const [users, setUsers] = useState<any>([]);

    function getCurrentMonth(month: number) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        
        return monthNames[month-1];
    }

    const onClick = (key: string) => {
        switch (key) {
            case "Plan":
                plan && navigate(ROUTES.planDetails.build({id: organisation?.plan}));
                break;
        }
    }

    const [formData, setFormData] = useState<ChangeLogoForm>({} as ChangeLogoForm);

    const onSubmitLogo = () => {
        AdminService.uploadLogo(id!, {logo: formData.orgLogo[0]}).then(response => {
            dispatch(updateOrganisation({
                organisationId: id!,
                newDetails: response.organisation!
            }));
        });
    };

    const deleteLogo = () => {
        AdminService.deleteLogo(id!).then(response => {
            if (response.succeeded) {
                dispatch(updateOrganisation({
                    organisationId: id!,
                    newDetails: {...organisation, logoName: undefined}
                }));
            }
        });
    }

    const enableOrg = () => {
        AdminService.updateOrganisation(id!, {enabled: true}).then(response => {
            if (response.succeeded) {
                dispatch(updateOrganisation({
                    organisationId: id!,
                    newDetails: {...organisation, enabled: true}
                }));
            }
        });
    }

    const disableOrg = () => {
        AdminService.updateOrganisation(id!, {enabled: false}).then(response => {
            if (response.succeeded) {
                dispatch(updateOrganisation({
                    organisationId: id!,
                    newDetails: {...organisation, enabled: false}
                }));
            }
        });
    }

    const deleteOrg = () => {
        AdminService.deleteOrganisation(id!).then(response => {
            if (response.succeeded) {
                navigate('/organisations');
            }
        });
    }

    const goToUser = (id: any) => {
        return (event: React.MouseEvent) => {
            if (id == null) {
                alert('User id is '  + id);
                return;
            }
            navigate('/users/' + id);
            event.preventDefault();
        };
    }

    function compare(a: any, b:any) {
        if ( a.firstName.toLowerCase() < b.firstName.toLowerCase() ){
          return -1;
        }
        if ( a.firstName.toLowerCase() > b.firstName.toLowerCase() ){
          return 1;
        }
        return 0;
    }
    
    useEffect(() => {
        StatisticsService.geProductStats()
        .then(response => {
            let products = response as any;
            let org;
            products.organisations.forEach((o: { organisation: { id: string | undefined; }; }) => {
                if (o.organisation.id === id) {
                    org = o;
                }
            })
            products = org;
            setProduct(products);
            let reports = 0;
            let pages = 0;
            products?.stats?.forEach((month: { renderStats: { documentCount: number; pageCount: number; }; }) => {
                reports = reports + (month.renderStats?.documentCount as number);
                pages = pages + (month.renderStats?.pageCount as number);
            });
            setReports(reports.toString());
            setPages(pages.toString());
        })
        .catch(handleError);

        // const data = users.map((u) => {
        //     let org;
        //     return {
        //         ...u,
        //         role: u.roles ? u.roles[0] : "",
        //     };
        // });
        // data.sort(compare);
        // setData(data);
        UsersService.findUsers(undefined, undefined, undefined, undefined, organisation?.id, undefined) 
        .then(response => {
            setUsers(response?.users);
        })
        .catch(handleError);
    }, [organisation])

    const hasLogo = !!organisation?.logoName;
    const logoUrl = docOpenApi.BASE + '/api/docsvc/v1/orgs/' + organisation?.id + '/logo'

    return !organisation ? <Loader/> : (
        <Page title={{page: "Organisation Details", icon: ["far", "flag"]}}>
            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'row', maxWidth: '100%', gap: '3%'}}>
                    <div style={{width: '50%', maxWidth: "50%"}}>
                        <h2>Organisation Details</h2>
                        <DetailTable onClick={onClick} clickable={[plan && "Plan"]} details={{
                            "ID": organisation.id,
                            "Name": organisation.name,
                            "Email": organisation.email,
                            "Address": organisation.address1,
                            "Suburb": organisation.suburb,
                            "Postcode": organisation.postcode,
                            "Region": organisation.region,
                            "State": organisation.state,
                            "Phone": organisation.phone,
                            "Colour": organisation.color,
                            "Logo": organisation.logoName,
                            "Plan": plan ? plan : organisation.plan,
                            "Product Owner": organisation.productOwner ? "Yes" : "No"
                        }}/>
                        <div style={{display: "flex"}}>
                            <div style={{marginRight: 'auto', marginTop: "10px", display: "flex", flexDirection: 'row', gap: '10px'}}>
                                <Button title="Delete"
                                        type="button"
                                        variant="danger"
                                        icon={['far', 'trash']}
                                        iconPosition="left"
                                        onClick={() => setDeleteModalVisible(true)}
                                        style={{display: !organisation.enabled ? 'flex' : 'none'}} />
                            </div>
                            <div style={{marginLeft: "auto", marginTop: "10px", display: "flex", flexDirection: 'row', gap: '10px'}}>
                                <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: organisation.enabled ? '#66A00B' : 'red'}}>{organisation.enabled ? 'Enabled' : 'Disabled'}</span>
                                <Button title="Enable"
                                    type="button"
                                    variant="danger"
                                    icon={['far', 'plus']}
                                    iconPosition="left"
                                    onClick={() => setEnableModalVisible(true)}
                                    style={{display: !organisation.enabled ? 'flex' : 'none', backgroundColor: '#66A00B'}} />
                                <Button title="Disable"
                                    type="button"
                                    variant="danger"
                                    icon={['far', 'cancel']}
                                    iconPosition="left"
                                    onClick={() => setDisableModalVisible(true)}
                                    style={{display: organisation.enabled ? 'flex' : 'none'}} />
                                <Button
                                    title="Edit"
                                    icon={['far', 'edit']}
                                    iconPosition="left"
                                    onClick={() => {
                                        navigate(ROUTES.editOrganisation.build({id: id}));
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{width: '50%', maxWidth: "50%"}}>
                        <h2>Usage Stats</h2>
                        {(products == null || products.length === 0) &&
                            <Loader></Loader>
                        }
                        {products?.stats?.length === 0 &&
                            <span>No usage data found.</span>
                        }
                        {(products?.organisation && products?.stats?.length !== 0) &&
                        (<Table border data={products} divider={false}>
                            <>
                            <HeaderRow>
                                <HeaderCell></HeaderCell>
                                <HeaderCell>Reports ({totalReports})</HeaderCell>
                                <HeaderCell>Pages ({totalPages})</HeaderCell>
                                <HeaderCell>Devices</HeaderCell>
                            </HeaderRow>
                            {products?.stats?.map((product: MonthStats, idx: number) => {
                                return (
                                    <Row
                                        key={idx}
                                        // divider={true}
                                        striped={idx % 2 === 1}
                                    >
                                        <Cell style={{fontWeight: '1000'}}>{getCurrentMonth(product.month as any)} ({product.year})</Cell>
                                        <Cell>{product?.renderStats?.documentCount}</Cell>
                                        <Cell>{product?.renderStats?.pageCount}</Cell>
                                        <Cell>{product?.renderStats?.deviceCount}</Cell>
                                    </Row>
                                );
                            })}
                            </>
                            </Table>)
                        }
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', maxWidth: '100%', gap: '3%'}}>
                    <div style={{width: '50%', maxWidth: "50%", marginTop: 70}}>
                        <CardContainer title="Change Logo">
                            <Form.Form onSubmit={onSubmitLogo} data={formData} setData={setFormData}>
                                <div className={styles.logoRow}>
                                    <LogoView hasLogo={hasLogo} url={logoUrl} />
                                </div>
                                <Form.FileUpload for="orgLogo"
                                            required
                                            validate={[maxFileSize(20, "MB")]}
                                            accept="image/*" />
                                <div className={styles.buttonRow}>
                                    <Button title="Delete"
                                            type="button"
                                            variant="danger"
                                            onClick={() => setLogoModalVisible(true)}
                                            disabled={!hasLogo} />
                                    <Button title="Submit" type="submit" />
                                </div>
                            </Form.Form>
                        </CardContainer>
                    </div>
                    <div style={{width: '50%', maxWidth: "50%"}}>
                        <h2>Users ({users?.length})</h2>
                        {(users == null) &&
                            <Loader></Loader>
                        }
                        {users?.length === 0 &&
                            <span>No users found.</span>
                        }
                        {users?.length !== 0 &&
                        (
                            <>
                                <div style={{maxHeight: '350px', overflow: 'auto'}}>
                                    <Table border data={users} divider={false}>
                                    <>
                                    <HeaderRow>
                                        <HeaderCell>Full Name</HeaderCell>
                                        <HeaderCell>Email</HeaderCell>
                                        <HeaderCell>Role</HeaderCell>
                                        <HeaderCell>User Access</HeaderCell>
                                        <HeaderCell style={{width: '10px'}}></HeaderCell>
                                    </HeaderRow>
                                    {users?.map((user: UserDetails, idx: number) => {
                                        return (
                                            <Row
                                                hover
                                                key={idx}
                                                // divider={true}
                                                striped={idx % 2 === 1}
                                            >
                                                <Cell style={{fontWeight: '1000'}}>{user.firstName + " " + user.lastName}</Cell>
                                                <Cell>{user.email}</Cell>
                                                <Cell>{user.roles}</Cell>
                                                <Cell style={{color: user.enabled ? "green" : "red"}}>{ user.enabled ? "Enabled": "Disabled" }</Cell>
                                                <Cell><button style={{color: 'blue', cursor: 'pointer', background: 'none', border: 'none'}} onClick={goToUser(user?.id)}><Icon icon={'arrow-up-right-from-square'} size='lg'></Icon></button></Cell>
                                            </Row>
                                        );
                                    })}
                                    </>
                                    </Table>
                                </div>
                            </>
                        ) 
                        }
                    </div>
                </div>
            </div>
            
            <Modal title="Confirm delete logo"
                   status="danger"
                   visible={logoModalVisible}
                   onClose={() => setLogoModalVisible(false)}
                   actions={["Confirm", "Cancel"]}
                   actionReducer={(action) => {
                       if (action == "Confirm") {
                           deleteLogo();
                       }
                       setLogoModalVisible(false);
                   }}>
                Are you sure you want to delete this organisation's logo?
            </Modal>
            <Modal title="Confirm delete organisation"
                   status="danger"
                   visible={deleteModalVisible}
                   onClose={() => setDeleteModalVisible(false)}
                   actions={["Confirm", "Cancel"]}
                   actionReducer={(action) => {
                       if (action === "Confirm") {
                           deleteOrg();
                       }
                       setDeleteModalVisible(false);
                   }}>
                Are you sure you want to delete this organisation? (This cannot be undone!)
            </Modal>
            <Modal title="Confirm disable organisation"
                   status="danger"
                   visible={disableModalVisible}
                   onClose={() => setDisableModalVisible(false)}
                   actions={["Confirm", "Cancel"]}
                   actionReducer={(action) => {
                       if (action === "Confirm") {
                           disableOrg();
                       }
                       setDisableModalVisible(false);
                   }}>
                Are you sure you want to disable this organisation? (Doing so will disable <b>ALL</b> users)
            </Modal>
            <Modal title="Confirm enable organisation"
                   status="normal"
                   visible={enableModalVisible}
                   onClose={() => setEnableModalVisible(false)}
                   actions={["Confirm", "Cancel"]}
                   actionReducer={(action) => {
                       if (action === "Confirm") {
                           enableOrg();
                       }
                       setEnableModalVisible(false);
                   }}>
                Are you sure you want to enable this organisation? (Doing so will enable <b>ALL</b> Managers)
            </Modal>
        </Page>
    );
}

export default OrganisationDetailsPage;
