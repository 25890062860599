import { useContext, useEffect } from 'react';
import useClasses from '../../../utility/useClasses';
import usePosition from '../../../hooks/usePosition';
import TabBarContext from '../TabBarContext';
import styles from './Tab.module.scss';
import Icon, { IconProp } from '../../Icon/Icon';

interface TabProps {
    title: string;
    icon?: IconProp;
    disabled?: boolean;
    selected?: boolean;
    children?: React.ReactNode;
}

function Tab({ title, icon, selected = false, disabled, children }: TabProps) {
    const { setCurrent, currentIndex, size } = useContext(TabBarContext);
    const [ref, index] = usePosition<HTMLDivElement>();
    const isSelected = currentIndex === index;

    useEffect(() => {
        if (index != undefined && (selected || isSelected)) {
            setCurrent(index, ref, false, children);
        }
    }, [index]);

    const classes = useClasses(styles, {
        'tab': true,
        ['tab-' + size]: true,
        'tab-selected': isSelected,
        'disabled': disabled
    });

    return (
        <div
            className={classes}
            ref={ref}
            onClick={() => setCurrent(index, ref, true, children)}
        >
            {icon && <Icon icon={icon} />}
            {title}
        </div>
    );
}

export default Tab;
