import { CSSProperties, useContext } from 'react';
import { animated, AnimatedProps } from 'react-spring';
import SidebarContext from '../SidebarContext';

interface SidebarItemProps {
    children: React.ReactElement;
}

function SidebarItem({ children }: SidebarItemProps) {
    const { animationProgress, minWidth } = useContext(SidebarContext);

    const animatedStyle = {
        opacity: animationProgress.to({
            range: [50, 100],
            output: [0, 1],
        }),
        transform: animationProgress
            .to({
                range: [100, 0],
                // link padding + left icon padding + icon + right icon padding
                output: [10, minWidth],
            })
            .to(number => `translateX(${number}px)`),
    } as AnimatedProps<CSSProperties>;

    return (
        <div style={{overflow: "hidden"}}>
            <animated.div style={animatedStyle}>
                {children}
            </animated.div>
        </div>
    );
}

export default SidebarItem;
