import useClasses from "../../utility/useClasses";
import DropdownNavContext from './DropdownNavContext';
import { useEffect, useState } from "react";
import { Location, NavigateFunction } from 'react-router-dom';
import styles from './DropdownNav.module.scss';

interface DropdownNavProps {
    location: Location;
    navigate?: NavigateFunction;
    headerHeight: number;
    children: React.ReactNode;
    collapsed: boolean;
    onCollapse: () => void;
};

function DropdownNav({
        children,
        collapsed,
        onCollapse,
        headerHeight,
        location,
        navigate=() => {}
    }: DropdownNavProps
) {
    const [closing, setClosing] = useState(false);
    const [waiting, setWaiting] = useState(false);

    useEffect(() => {
        if (!waiting && !collapsed) {
            setWaiting(true);
            setTimeout(() => {
                setWaiting(false);
            }, 500);
        }
    }, [collapsed]);

    const closeDropdown = () => {
        if (waiting) {
            return;
        }
        setClosing(true);
        setTimeout(() => {
            onCollapse()
            setClosing(false);
        }, 500);
    }

    const dropdownClasses = useClasses(styles, {
        dropdown: true,
        closed: collapsed || closing,
    });

    const bgClasses = useClasses(styles, {
        background: true,
        closed: collapsed || closing,
    });

    const context = {
        location: location,
        navigate: navigate,
    };

    const containerStyle = {
        transform: "translateY(" + headerHeight + "px)",
        overflow: "hidden",
    }

    return collapsed ? null : (
        <DropdownNavContext.Provider value={context}>
            <div className={bgClasses} onClick={closeDropdown}>
                <div style={containerStyle}>
                    <div className={dropdownClasses}>
                        {children}
                    </div>
                </div>
            </div>
        </DropdownNavContext.Provider>
    );
}

export default DropdownNav;
