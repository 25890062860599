import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, loadIcons } from '@application-production-system/react';
import { Provider } from 'react-redux';

loadIcons(["fas", "far"]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider
                animated
                otherColors={{
                    // 242930
                    'main-menu-dark-background': '#242930',
                    'main-menu-dark-item-background': '#242728',
                    'main-menu-dark-item-hover-background': '#383d3f',
                    'main-menu-dark-item-focused-border': 'rgba(87, 101, 105, 0.2)',
                    'main-menu-dark-item-active-background': '#354145',
                    // 'main-menu-dark-header-background': '#242930',
                    'top-bar-dark-background': '#f6f8fa'
                }}>
                <Provider store={store}>
                    <App />
                </Provider>
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
