import { CardContainer, createForm } from '@application-production-system/react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AdminService, ConsoleIntegration } from '../../api/console';
import { handleError } from '../../assets/constants';
import { CancelSubmitRow } from '../../components';
import { ROUTES } from '../../routes';

interface LocationState {
    integration: ConsoleIntegration;
}

interface CreateIntegrationForm {
    partnerId: string;
    integration: string;
    apiKey: string;
    officeId: string;
    verified: boolean;
}

const Form = createForm<CreateIntegrationForm>();

function EditIntegrationPage() {
    const location = useLocation();
    const navigate = useNavigate();

    const { integration } = location?.state as LocationState;

    const [formData, setFormData] = useState<CreateIntegrationForm>(integration as CreateIntegrationForm);

    const onSubmit = () => {

        const request = { integration: formData };

        AdminService.updateIntegration(integration.id!, request)
            .then(response => {
                navigate(ROUTES.integrationDetails.build({id: response?.integration?.id}));
            })
            .catch(handleError);
    }

    return (
        <div style={{maxWidth: "600px"}}>
            <CardContainer title="Edit Integration">
                <Form.Form onSubmit={onSubmit} data={formData} setData={setFormData}>
                    <Form.Entry required for="partnerId"   label="Partner" />
                    <Form.Entry required for="integration" label="Integration" />
                    <Form.Entry          for="apiKey"      label="API Key" />
                    <Form.Entry          for="officeId"    label="Office ID" />
                    <Form.Checkbox       for="verified"    label="Verified" />
                    <CancelSubmitRow title="Save Changes" />
                </Form.Form>
            </CardContainer>
        </div>
    );
}

export default EditIntegrationPage;
