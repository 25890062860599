// split a string based on camelCase, snake_case, regular space, and kebab-case
function smartSplit(text: string): string[] {
    const results: string[] = [];

    let start: number = 0;
    let end: number = 0;
    let upper: boolean = false;

    for (const char of text) {
        if (char >= "a" && char <= "z") {
            if (upper && end > start + 1) {
                results.push(text.substring(start, end - 1));
                start = end - 1;
            }
            end++;
            upper = false;
        }
        else if (char >= "A" && char <= "Z") {
            if (!upper && end > start + 1) {
                results.push(text.substring(start, end));
                start = end;
            }
            end++;
            upper = true;
        }
        else {
            if (end > start) {
                results.push(text.substring(start, end));
            }
            start = ++end;
        }
    }

    if (end > start) {
        results.push(text.substring(start, end));
    }

    return results.map(s => s.match(/^[A-Z]*$/) ? s : titleCase(s));
}

function titleCase(s: string): string {
    return s[0].toUpperCase() + s.substring(1).toLowerCase();
}

const StringUtil = {
    smartSplit, titleCase
};

export default StringUtil;

