import { useId } from 'react';

interface SliderProps {
    min: number;
    max: number;
    value: number;
    onChange?: (value: number) => void;
}

function Slider({ min, max, value, onChange }: SliderProps) {
    const id = useId();

    return (
        <div>
            <label htmlFor={id}>Slider</label>
            <input type="range" min={min} max={max} value={value} id={id} />;
        </div>
    );
}

export default Slider;
