import { Button } from '@application-production-system/react';
import { useNavigate } from 'react-router-dom';

interface CancelSubmitRowProps {
    title?: string;
    onCancel?: () => void;
    disabled?: boolean;
}

function CancelSubmitRow({title="Submit", onCancel, disabled}: CancelSubmitRowProps) {

    const navigate = useNavigate();

    if (!onCancel) {
        onCancel = () => navigate(-1);
    }

    return (
        <div style={{display: "flex", justifyContent: 'right', marginTop: 25}}>
            <Button title="Cancel" type="button" variant="ghost" onClick={onCancel} />
            <Button title={title} type="submit" disabled={disabled}/>
        </div>
    );

    // return (
    //     <LoadingContext>
    //     { loading =>
    //         <div style={{display: "flex", justifyContent: 'right', marginTop: 25}}>
    //             <Button title="Cancel" variant="ghost" onClick={onCancel} />
    //             <Button title={title} type="submit" disabled={disabled || loading}/>
    //         </div>
    //     }
    //     </LoadingContext>
    // );
}

export default CancelSubmitRow;
