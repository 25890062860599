import styles from './ObjectDetails.module.scss'

interface ObjectDetailsProps {
    title: string;
    details: {[key: string]: string};
}

function ObjectDetails({title, details}: ObjectDetailsProps) {
    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <table className={styles.table}>
                <tbody>
                {
                    Object.entries(details).map(([key, value]) => (
                        <tr key={key}>
                            <td className={styles.key}>{key}</td>
                            <td className={styles.value}>{value}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    );
}

export default ObjectDetails;
