import { useRef, CSSProperties, useState } from 'react';
import { InputProps } from '..';
import styles from './FileUpload.module.scss';
import Icon from '../../Icon/Icon';
import Label from '../Label/Label';
import useClasses from '../../../utility/useClasses';

export interface FileUploadProps extends Omit<InputProps<FileList|undefined>, "value"> {
    accept?: string;
    style?: CSSProperties;
    multiple?: boolean;
}

function FileUpload({
        label,
        disabled,
        error,
        required,
        accept,
        style,
        onChange=() => {},
        ...props
    }: FileUploadProps
) {

    const [files, setFiles] = useState<FileList|undefined>();

    const onChangeHandler = (files: FileList | undefined) => {
        setFiles(files);
        onChange(files);
    }

    const openFileUpload = () => {
        filePicker.current!.click();
    }

    const clearFile = () => {
        filePicker.current!.value = "";
        onChangeHandler(undefined);
    }

    const getFileName = () => {
        if (!files || !files.length) {
            return (
                <span style={{color: "dimgrey"}}>
                    No file chosen
                </span>
            );
        }
        if (files.length == 1) {
            const file = files[0];
            return file.name;
        }
        return files.length + " files selected";
    }

    const filePicker = useRef<HTMLInputElement>(null);

    const classes = useClasses(styles, {
        "file-upload-name": true,
        "error": error
    });

    return (
        <div className={styles["col-stack"]} style={style}>
            { label && <Label text={label} required={required}/> }
            <div style={{display: "flex"}}>
                <div style={{display: "flex"}} className={classes}>
                    {getFileName()}
                    {files &&
                        <div className={styles["right-align-div"]}>
                            <Icon
                                icon={['fas', 'times-circle']}
                                className={styles["remove-file-icon"]}
                                onClick={clearFile}
                            />
                        </div>
                    }
                </div>
                <button
                    disabled={disabled}
                    type="button"
                    onClick={openFileUpload}
                    className={styles["upload-button"]}
                >
                    {<><Icon icon={['far', 'cloud-upload']} /> Upload File</>}
                </button>
                <input
                    {...props}
                    onChange={e => onChangeHandler(e.target.files || undefined)}
                    style={{display: "none"}}
                    type="file"
                    ref={filePicker}
                    accept={accept}
                />
            </div>
            <label className={styles["error-message"]}>
                {error}
            </label>
        </div>
    );
}

export default FileUpload;
