export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const roles = {
    "SUPER": "Super",
    "ADMIN": "Admin",
    "MANAGER": "Manager",
    "INSPECTOR": "Inspector"
};
export const defaultRole = "INSPECTOR";

export const verifyRequired = (value: string) => {
    if (!value) {
        return "This field is required";
    }
}

export const verifyEmail = (value: string) => {
    if (!value) {
        return "This field is required";
    }
    if (!emailRegex.test(value)) {
        return "This field must be a valid email";
    }
}

export const titleCase = (str?: string) => {
    if (!str) {
        return "";
    }
    const words = str.split(" ");
    const output: string[] = [];
    words.forEach((word, _) => {
        output.push(word[0].toUpperCase() + word.substring(1).toLowerCase());
    });
    return output.join(" ");
}

export const formatTimestamp = (timestamp: string) => {
    const date = new Date(timestamp);
    const day = date.getDate();        // starts at 1
    const month = date.getMonth() + 1; // starts at 0
    const year = date.getFullYear().toString().substring(2);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return (day < 10 ? "0" + day : day)
        + "/" + (month < 10 ? "0" + month : month)
        + "/" + year
        + " " + (hours > 12 ? hours - 12 : hours)
        + ":" + (minutes < 10 ? "0" + minutes : minutes)
        + (date.getHours() < 12 ? "am" : "pm");
}

export const getErrorMessage = (status: number) => {
    switch (status) {
        case 0:
            return "Loading...";
        case 403:
            return "You do not have permission to view this content.";
        default:
            return "Something went wrong.";
    }
}

export const titleCaseList = (list: string[]) => {
    let result = "";
    list.forEach((value, idx) => {
        if (idx > 0) {
            result += ", ";
        }
        result += value[0].toUpperCase() + value.substring(1).toLowerCase();
    });
    return result;
}

export const handleError = (error: any) => {
    console.error(error);
    if (error.status == 403) {
        alert("Insufficient permission");
    }
    else {
        alert("Something went wrong");
    }
}
