import { usePageTitle } from '../../hooks';
import styles from './Page.module.scss';
import { PageTitle } from '../../store/sessionSlice';

interface PageProps {
    title?: PageTitle;
    children: React.ReactNode;
};

export function Page({title, children}: PageProps) {
    usePageTitle(title);

    return (
        <div className={styles.page}>
            {children}
        </div>
    );

}
