import { Button, createForm } from '@application-production-system/react';
import styles from './LoginPage.module.scss';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { login, selectLoginError } from '../../store/sessionSlice';
import { AuthenticationService } from '../../api/doc';
import { useState, useEffect } from 'react';

interface LoginForm {
    username: string;
    password: string;
    product: string;
}

const Form = createForm<LoginForm>();

function LoginPage() {
    const dispatch = useAppDispatch();
    const loginError = useAppSelector(selectLoginError);

    const [errorMessage, setErrorMessage] = useState("");

    const [formData, setFormData] = useState<LoginForm>({} as LoginForm);

    const onSubmit = () => {
        AuthenticationService.login(
            formData.product, {login: formData.username, password: formData.password}
        )
        .then(response => {
            dispatch(login({
                productCode: formData.product,
                token:       response.token!,
            }));
        })
        .catch(error => {
            if (error.status == 403) {
                setErrorMessage("Invalid username or password");
            }
            else {
                setErrorMessage("Something went wrong");
            }
        })
    }

    const [productOptions, setProductOptions] = useState<{[key: string]: string}>({});

    useEffect(() => {
        AuthenticationService.findProductCodes()
        .then((response) => {
            setErrorMessage("");
            const productOptions: {[key: string]: string} = {};
            response.codes!.forEach((product, _) => {
                productOptions[product.code!] = product.name!;
            });
            setProductOptions(productOptions);
        })
        .catch((error) => {
            setErrorMessage("Error connecting to server");
            console.error(error);
        })
    }, []);


    return (
        <div className={styles['login-page']}>
            <div className={styles['login-rect']}>
                <h2 className={styles['login-title']}>
                    Login
                </h2>
                <Form.Form onSubmit={onSubmit} data={formData} setData={setFormData}>
                    <Form.Entry required for="username" label="Username" />
                    <Form.Entry required for="password" label="Password"
                        type="password"
                    />
                    <Form.Select required for="product"  label="Product"
                        options={productOptions}
                    />
                    <p className={styles['error-message']}>
                        { errorMessage || loginError }
                    </p>
                    <Button title="Login" type="submit" />
                </Form.Form>
            </div>
        </div>
    );
}

export default LoginPage;
