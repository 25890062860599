import styles from './Container.module.scss';

interface ContainerProps {
    title?: React.ReactNode;
    leftHeader?: React.ReactNode;
    rightHeader?: React.ReactNode;
    children?: React.ReactNode;
}

function Container({ leftHeader, rightHeader, title, children }: ContainerProps) {
    const shouldShowHeader = leftHeader || rightHeader || title;

    return (
        <div className={styles['container']}>
            {shouldShowHeader && (
                <div className={styles['header']}>
                    {leftHeader && <div className={styles['left-header']}>{leftHeader}</div>}
                    {title && <div className={styles['title']}>{title}</div>}
                    {rightHeader && <div className={styles['right-header']}>{rightHeader}</div>}
                </div>
            )}

            <div className={styles['content']}>{children}</div>
        </div>
    );
}

export default Container;
