import { CardContainer, createForm } from '@application-production-system/react';
import { useState } from 'react';
import { CancelSubmitRow } from '../../components';

interface CreateIntegrationForm {
    partnerId: string;
    integrationId: string;
    apiKey: string;
    officeId: string;
    verified: boolean;
}

const Form = createForm<CreateIntegrationForm>();

function CreateIntegrationPage() {
    const [formData, setFormData] = useState<CreateIntegrationForm>({} as CreateIntegrationForm);

    const onSubmit = () => {
        alert("Not implemented");
    }

    return (
        <div style={{maxWidth: "600px"}}>
            <p style={{color: "red"}}>
                This page has not been implemented.
            </p>
            <CardContainer title="Create Integration">
            <Form.Form onSubmit={onSubmit} data={formData} setData={setFormData}>
                <Form.Entry required for="partnerId"     label="Partner ID" />
                <Form.Entry required for="integrationId" label="Integration ID" />
                <Form.Entry          for="apiKey"        label="API Key" />
                <Form.Entry required for="officeId"      label="Office ID" />
                <Form.Checkbox       for="verified"      label="Verified" />
                <CancelSubmitRow />
            </Form.Form>
            </CardContainer>
        </div>
    );
}

export default CreateIntegrationPage;
