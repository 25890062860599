import { isEmail, CardContainer, createForm } from '@application-production-system/react';
import { useNavigate, useParams } from 'react-router-dom';
import { roles, defaultRole, handleError } from '../../assets/constants';
import { UsersService } from '../../api/doc';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { selectUser, updateUser } from '../../store/userSlice';
import { selectOrganisationOptions } from '../../store/organisationSlice';
import { CancelSubmitRow } from '../../components';
import { IdPageParams, ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';
import { useState, useEffect } from 'react';

interface EditUserForm {
    firstName: string;
    lastName: string;
    organisationId: string;
    email: string;
    userId: string;
    password: string;
    role: string;
}

const Form = createForm<EditUserForm>();

function EditUserPage() {
    const navigate = useNavigate();

    const { id } = useParams<IdPageParams>();

    const user = useAppSelector((state) => selectUser(state, id));
    const dispatch = useAppDispatch();
    const organisationOptions = useAppSelector(selectOrganisationOptions);
    const [sortedOrgOptions, setSortedOrgOptions] = useState<any>();

    useEffect(() => {
        const keyValueArray = Object.entries(organisationOptions);
        const sortedArray = keyValueArray.sort((a, b) => a[1].localeCompare(b[1]));
        const sortedObject = Object.fromEntries(sortedArray);
        setSortedOrgOptions(sortedObject);
    }, [organisationOptions])
    

    const getDefaultRole = () => {
        if (user?.roles && user?.roles.length) {
            return user?.roles[0];
        }
        return defaultRole;
    }

    const [formData, setFormData] = useState<EditUserForm>(user as EditUserForm);

    const onSubmit = () => {
        const request = {
            ...formData,
            userId: formData.userId ? formData.userId : formData.email,
            password: formData.password ? formData.password : undefined
        }
        UsersService.updateUser1(user.id!, request)
            .then((response) => {
                dispatch(updateUser({id: user.id, newDetails: response.user}));
                navigate(ROUTES.userDetails.build({id: id}));
            })
            .catch(handleError);
    }

    const userIdLbl = "Login Name (Leave blank to use email)";

    const getUserId = () => (user?.userId === user?.email ? "" : user?.userId);

    return (
        <Page title={{page: "User Details", subPage: "Edit User Details", icon: ["far", "user"]}}>
            <div style={{maxWidth: "600px"}}>
                <CardContainer title="Edit User">
                    <Form.Form onSubmit={onSubmit} data={formData} setData={setFormData}>
                        <Form.Entry required for="firstName"    label="First Name" />
                        <Form.Entry required for="lastName"     label="Last Name" />
                        <Form.Entry required for="email"        label="Email"        validate={isEmail} />
                        <Form.Entry          for="userId"       label={userIdLbl} />
                        <Form.Entry          for="password"     label="Password" />
                        <Form.Select         for="organisationId" label="Organisation" options={sortedOrgOptions} />
                        <Form.Select         for="role"         label="Role" options={roles} />
                        <CancelSubmitRow title="Save Changes" />
                    </Form.Form>
                </CardContainer>
            </div>
        </Page>
    );
}

export default EditUserPage;
