import { useReducer } from 'react';

function useForceUpdate() {
    // the "official" way to force a rerender in a functional component
    // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
    const [ _, forceUpdate ] = useReducer(x => x + 1, 0);
    return forceUpdate;
}

export default useForceUpdate;
