import { Location, NavigateFunction } from 'react-router-dom';
import Breadcrumbs from './Breadcrumbs';
import { HomeCrumb, Crumb } from './Breadcrumbs';
import StringUtil from '../../utility/StringUtil';

export interface UrlBreadcrumb {
    replaceKey?: boolean;
    callback: (state: any, id: string) => string;
};

export type UrlBreadcrumbsConfig = {[key: string]: UrlBreadcrumb};

export interface UrlBreadcrumbsProps {
    config: UrlBreadcrumbsConfig;
    state: {[key: string]: any};
    location: Location;
    navigate: NavigateFunction;
    homeCrumb?: HomeCrumb | null;
    separator?: string;
    onClickHome: () => void;
}

export default function UrlBreadcrumbs({
        config,
        state,
        location,
        navigate,
        homeCrumb=["far", "home"],
        separator="/",
        onClickHome
    }: UrlBreadcrumbsProps
) {
    const crumbs: Crumb[] = [];
    const pathComponents = location.pathname.split("/").slice(1);

    for (let i = 0; i < pathComponents.length; i++) {
        const component = pathComponents[i];

        let title: string;
        if (i < pathComponents.length - 1 && Object.keys(config).includes(component)) {
            const urlBreadcrumb = config[component];

            if (!urlBreadcrumb.replaceKey) {
                title = StringUtil.smartSplit(component).join(" ");
                const path = pathComponents.slice(0, i + 1).join("/");
                crumbs.push({
                    title: title,
                    onClick: () => navigate(path)
                });
            }
            title = urlBreadcrumb.callback(state, pathComponents[++i]);
        }
        else {
            title = StringUtil.smartSplit(component).join(" ");
        }

        const path = pathComponents.slice(0, i + 1).join("/");
        crumbs.push({
            title: title,
            onClick: () => navigate(path)
        });
    }

    return (
        <Breadcrumbs
            crumbs={crumbs}
            homeCrumb={homeCrumb ? homeCrumb : undefined}
            separator={separator}
            onClickHome={onClickHome}
        />
    );
}
