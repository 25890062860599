import { Table, Row, Cell, HeaderRow, HeaderCell, Button } from '@application-production-system/react';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { StatisticsService, RenderLogEntry } from '../../api/doc';
import { formatTimestamp, getErrorMessage } from '../../assets/constants';
import styles from './RenderLogPage.module.scss';
import { ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';

interface CachedEntry {
    entry: RenderLogEntry;
    formattedDate: string;
}

interface FilterConfig {
    entries: CachedEntry[];
    page: number;
    perPage: number;
}

function RenderLogPage() {
    const navigate = useNavigate();

    const [state, setConfig] = useState<FilterConfig>({
        entries: [],
        page: 0,
        perPage: 50,
    });

    useEffect(() => {
        loadPage(state);
    }, []);

    const [message, setMessage] = useState("Loading...");
    const [outOfLogs, setOutOfLogs] = useState(false);
    const [loading, setLoading] = useState(false);

    const loadPage = (config: FilterConfig) => {
        setLoading(true);
        StatisticsService.findRenderLogEntries(
            config.page,
            config.perPage,
        )
        .then((response) => {
            setLoading(false);
            if (response.succeeded) {
                if (response.entries!.length < config.perPage) {
                    setOutOfLogs(true);
                    if (response.entries!.length === 0) {
                        setMessage("No logs available.");
                        return;
                    }
                }

                // react strict mode for some reason does stuff twice
                // gotta make sure its not the same logs being added
                if (config.entries.length
                        && response.entries![response.entries!.length - 1].id
                        == config.entries[config.entries.length - 1].entry.id) {
                    return;
                }

                response.entries!.forEach((entry, _) => {
                    config.entries.push({
                        entry: entry,
                        formattedDate: formatTimestamp(entry.timestamp!)
                    });
                });

                config.page += 1;
                setConfig(config);
            }
            else {
                setMessage("Something went wrong");
                console.error("Error loading logs", response.shortMsg, response.longMsg);
            }
        })
        .catch((reason) => {
            setMessage(getErrorMessage(reason.status));
            console.error("Error loading logs", reason);
            setLoading(false);
        });
    }

    const viewEntry = (entry: RenderLogEntry) => {
        navigate(ROUTES.renderLogDetails.build({id: entry.id}));
    }

    const createRenderLogTable = () => {
        return !state.entries.length ? message : (
            <Table border
                data={state.entries}
                divider={false}
            >
                <>
                <HeaderRow>
                    <HeaderCell><b>Date</b></HeaderCell>
                    <HeaderCell><b>Email</b></HeaderCell>
                    <HeaderCell><b>Template</b></HeaderCell>
                </HeaderRow>
                {state.entries.map((entry: CachedEntry, idx: number) =>
                    <Row
                        key={idx}
                        onClick={() => {viewEntry(entry.entry)}}
                        divider={true}
                        striped={idx % 2 === 1}
                    >
                        <Cell>{ entry.formattedDate }</Cell>
                        <Cell>{ entry.entry.email }</Cell>
                        <Cell>{ entry.entry.templateName }</Cell>
                    </Row>
                )}
                </>
            </Table>
        )
    }

    return (
        <Page title={{page: "Render Logs", icon: ['far', 'print']}}>
            <div style={{overflowX: "hidden"}}>
                <h2>Render Logs</h2>
                <div className={styles["table-container"]}>
                    <div style={{minWidth: "500px"}}>
                        {createRenderLogTable()}
                    </div>
                </div>
                { !state.entries.length ? undefined :
                    <div className={styles["middle-div-container"]}>
                        <div style={{marginTop: 15}}>
                            <Button
                                disabled={loading || outOfLogs}
                                title={loading ? "Loading..." : (outOfLogs ? "No more logs" : "Load More")}
                                iconPosition="left"
                                onClick={() => {
                                    loadPage(state);
                                }}
                            />
                        </div>
                    </div>
                }
            </div>
        </Page>
    );
}

export default RenderLogPage;
