import {
    Table,
    Row,
    Cell,
    HeaderRow,
    HeaderCell,
    Button,
    createForm,
    CardContainer
} from '@application-production-system/react';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { StatisticsService, EmailLogEntry } from '../../api/doc';
import { formatTimestamp, getErrorMessage } from '../../assets/constants';
import styles from './EmailLogPage.module.scss';
import { ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';

interface CachedEntry {
    entry: EmailLogEntry;
    formattedDate: string;
}

interface FilterConfig {
    entries: CachedEntry[];
    page: number;
    perPage: number;
    emailTo?: string;
}

interface FilterForm {
    emailTo?: string;
}

const Form = createForm<FilterForm>();

function EmailLogPage() {
    const navigate = useNavigate();

    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [outOfLogs, setOutOfLogs] = useState(false);
    const [state, setConfig] = useState<FilterConfig>({
        entries: [],
        page: 0,
        perPage: 50,
    });

    const [formData, setFormData] = useState<FilterForm>({} as FilterForm);

    const onSubmit = () => {
        state.page = 0;
        state.entries = [];
        state.emailTo = formData.emailTo;
        setOutOfLogs(false);
        loadPage();
    }

    const loadPage = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        setMessage("Loading...");
        StatisticsService.findEmailLogEntries(
            state.page,
            state.perPage,
            undefined,
            state.emailTo
        )
        .then((response) => {
            setLoading(false);
            setMessage("");
            if (response.succeeded) {
                if (response.entries!.length < state.perPage) {
                    setMessage("No logs available.");
                    setOutOfLogs(true);
                    if (response.entries!.length === 0) {
                        return;
                    }
                }

                // react strict mode for some reason does stuff twice
                // gotta make sure its not the same logs being added
                if (state.entries.length
                        && response.entries![response.entries!.length - 1].id
                        == state.entries[state.entries.length - 1].entry.id) {
                    return;
                }

                let newState = {...state};
                newState.entries=[...state.entries];
                response.entries!.forEach((entry, _) => {
                    newState.entries.push({
                        entry: entry,
                        formattedDate: formatTimestamp(entry.timestamp!)
                    });
                });
                newState.page += 1;
                setConfig(newState);
            }
            else {
                setMessage("Something went wrong");
                console.error("Error loading logs", response.shortMsg, response.longMsg);
            }
        })
        .catch((reason) => {
            setMessage(getErrorMessage(reason.status));
            console.error("Error loading logs", reason);
            setLoading(false);
        });
    }

    const viewEntry = (entry: EmailLogEntry) => {
        navigate(ROUTES.emailLogDetails.build({id: entry.id}));
    }

    const createEmailLogTable = () => {
        return !state.entries.length ? message : (
            <Table border data={state.entries} divider={false}>
                <>
                <HeaderRow>
                    <HeaderCell><b>Date</b></HeaderCell>
                    <HeaderCell><b>From Email</b></HeaderCell>
                    <HeaderCell><b>To Email</b></HeaderCell>
                    <HeaderCell><b>State</b></HeaderCell>
                </HeaderRow>
                {state.entries.map((entry: CachedEntry, idx: number) => {
                    return (
                        <Row
                            key={idx}
                            onClick={() => {viewEntry(entry.entry)}}
                            divider={true}
                            striped={idx % 2 === 1}
                        >
                            <Cell>{ entry.formattedDate }</Cell>
                            <Cell>{ entry.entry.from }</Cell>
                            <Cell>{ entry.entry.to }</Cell>
                            <Cell>{ entry.entry.state }</Cell>
                        </Row>
                    );
                })}
                </>
            </Table>
        )
    }

    const doesNotContainComma = (value: string) => {
        if (value == undefined) {
            console.log("UNDEFINED");
        }
        if (value.includes(",")) {
            return "Value cannot contain a comma";
        }
    }

    return (
        <Page title={{page: "Email Logs", icon: ['far', 'envelope']}}>
            <div style={{overflowX: "hidden"}}>
                <h2>Email Logs</h2>
                <CardContainer title="Filter">
                    <Form.Form onSubmit={onSubmit} data={formData} setData={setFormData}>
                        <div className={styles["form-container"]}>
                            <div>
                                <Form.Entry for="emailTo" label="Email To" validate={doesNotContainComma} />
                            </div>
                        </div>
                        <div className={styles["middle-div-container"]}>
                            <div style={{marginTop: 10}}>
                                <Button
                                  disabled={loading}
                                  title={loading ? "Loading..." : "Apply"}
                                  type="submit"
                                />
                            </div>
                        </div>
                    </Form.Form>
                </CardContainer>

                <div className={styles["table-container"]}>
                    <div style={{minWidth: "500px"}}>
                        {createEmailLogTable()}
                    </div>
                </div>
                { !state.entries.length ? undefined :
                    <div className={styles["middle-div-container"]}>
                        <div style={{marginTop: 15}}>
                            <Button
                                disabled={loading || outOfLogs}
                                title={loading ? "Loading..." : (outOfLogs ? "No more logs" : "Load More")}
                                iconPosition="left"
                                onClick={loadPage}
                            />
                        </div>
                    </div>
                }
            </div>
        </Page>
    );
}

export default EmailLogPage;
