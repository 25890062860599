import { ComponentType, CSSProperties, useContext } from 'react';
import { animated, AnimatedProps } from 'react-spring';
import useClasses from '../../../utility/useClasses';

import SidebarContext from '../SidebarContext';
import styles from './Header.module.scss';

export interface HeaderProps {
    children: React.ReactNode;
    Logo: ComponentType<any>;
    separator?: boolean;
}

function Header({ children, Logo, separator }: HeaderProps) {
    const { animationProgress, headerHeight, minWidth } = useContext(SidebarContext);

    const style: CSSProperties = {
        height: headerHeight || 104,
    };

    const animatedContentStyle = {
        opacity: animationProgress.to({
            range: [50, 100],
            output: [0, 1],
        }),
        left: animationProgress
            .to({
                range: [100, 0],
                output: [40, 100],
            })
            .to(number => `${number}%`),
    } as AnimatedProps<CSSProperties>;

    const animatedLogoStyle = {
        transform: animationProgress
            .to({
                range: [100, 0],
                output: [15, 50],
            })
            .to(number => `translateX(-${number}%)`),
        left: animationProgress
            .to({
                range: [100, 0],
                output: [60, minWidth],
            })
            .to(number => `${number / 2}px`),
    } as AnimatedProps<CSSProperties>;

    const classes = useClasses(styles, {
        header: true,
        separator: separator,
    });

    return (
        <div className={classes} style={style}>
            <div className={styles['frame']}>
                <animated.div className={styles['logo']} style={animatedLogoStyle}>
                    <Logo />
                </animated.div>
                <animated.div className={styles['content']} style={animatedContentStyle}>
                    {children}
                </animated.div>
            </div>
        </div>
    );
}

export default Header;
