import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AuditLogEntry, StatisticsService } from '../api/doc';
import type { RootState } from '.'

interface AuditLogState {
    auditLogs: AuditLogEntry[];
    status: number;
    lookup: {[userId: string]: AuditLogEntry};
}

const initialState: AuditLogState = {
    lookup: {},
    status: 0,
    auditLogs: []
}

export const refreshAuditLogs = createAsyncThunk(
    'auditLog/fetch',
    async (_) => {
        let status = 200;
        const response = await StatisticsService.findAuditLogEntries(undefined, undefined, undefined, undefined, 'ALL')
            .catch(error => {
                status = error.status;
                return {entries: undefined};
            });
        return {
            status: status,
            auditLogs: response.entries,
        }
    }
)

export const auditLogSlice = createSlice({
    name: 'auditLog',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(refreshAuditLogs.fulfilled, (state, action) => {
            state.auditLogs = action.payload.auditLogs || [];
            state.status = action.payload.status;
            state.lookup = {};
            state.auditLogs.forEach((auditLog: AuditLogEntry, _) => {
                state.lookup[auditLog.id!] = auditLog;
            });
        });
    }
});

export const { } = auditLogSlice.actions;

export const formatAuditLog = (auditLog: AuditLogEntry) => {
    return "Audit Log <" + auditLog.timestamp + ">";
}

export const selectAuditLog = (state: RootState, id?: string) => state.auditLog.lookup[id!];

export const selectAuditLogs = (state: RootState) => state.auditLog.auditLogs;

export const selectFormatAuditLog = (state: RootState, id?: string) => {
    if (!id) {
        return undefined;
    }
    const auditLog = state.auditLog.lookup[id];
    if (!auditLog) {
        return undefined;
    }
    return formatAuditLog(auditLog);
}

export default auditLogSlice.reducer;
