import { useContext, useEffect } from "react";
import { KeysMatching, useValidator, Validator } from "..";
import Select, { SelectProps } from "../../Form/Select/Select";
import SchemaFormContext from '../SchemaFormContext';

export interface SchemaSelectProps<T extends {[key: string]: string}, K extends KeysMatching<T, string | undefined>> extends Omit<SelectProps, "value" | "error" | "options"> {
    for: K;
    options: Partial<Record<T[K], string>>;
    validate?: Validator<string> | Validator<string>[];
};

export const selectBuilder = function<T extends {[key: string]: string}>() {
    return function<K extends KeysMatching<T, string | undefined>>(props: SchemaSelectProps<T, K>) {
        return SchemaSelect(props);
    }
}

export function SchemaSelect<T extends {[key: string]: string}, K extends KeysMatching<T, string | undefined>>({"for": key, validate, ...props}: SchemaSelectProps<T, K>)
{
    const context = useContext(SchemaFormContext);
    const value = context.getValue(key);
    const error = context.getError(key)

    const validator = useValidator(validate || [], []);

    useEffect(() => {
        context.register(key, !!props.required, validator);
    }, [key, props.required, validator]);

    useEffect(() => {
        if (error) {
            context.validateSelf(key);
        }
    }, [value]);

    useEffect(() => {
        if (props.options == undefined) {
            if (value != undefined) {
                onChange(undefined);
            }
            return;
        }
        const keys = Object.keys(props.options);
        if (value && keys.includes(value)) {
            return;
        }
        if (props.required && keys.length > 0) {
            onChange(Object.keys(props.options)[0])
        }
        else {
            onChange(undefined);
        }
    }, [props.options])

    const onBlur = () => {
        context.onBlur(key);
        props.onBlur && props.onBlur();
    }

    const onChange = (value?: string) => {
        context.setValue(key, value);
        props.onChange && props.onChange(value);
    }

    return (
        <Select
            {...props}
            error={error}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
        />
    );
}
