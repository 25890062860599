import { Button, createForm, CardContainer } from '@application-production-system/react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AdminService, ConsolePartner } from '../../api/console';
import { handleError } from '../../assets/constants';
import { ROUTES } from '../../routes';

interface LocationState {
    partner: ConsolePartner
}

interface EditPartnerForm {
    partnerId: string;
    partnerCode: string;
    clientId: string;
    secret: string;
    endpoint: string;
    primary: boolean;
}

const Form = createForm<EditPartnerForm>();

function EditPartnerPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const { partner } = location.state as LocationState;

    const [formData, setFormData] = useState<EditPartnerForm>(partner as EditPartnerForm);

    const onSubmit = () => {
        const request = { partner: formData };
        AdminService.updatePartner(partner.id!, request)
            .then(response => {
                navigate(ROUTES.partnerDetails.build({id: response?.partner?.id}));
            })
            .catch(handleError);
    }

    return (
        <div style={{maxWidth: "600px"}}>
        <CardContainer title="Edit Partner">
                <Form.Form onSubmit={onSubmit} data={formData} setData={setFormData}>
                    <Form.Entry required for="partnerId"   label="Partner Id"   />
                    <Form.Entry required for="partnerCode" label="Partner Code" />
                    <Form.Entry required for="clientId"    label="Client Id"    />
                    <Form.Entry required for="secret"      label="Secret"       />
                    <Form.Entry required for="endpoint"    label="Endpoint"     />
                    <Form.Checkbox       for="primary"     label="Primary"      />
                    <div style={{display: "flex", justifyContent: 'right', marginTop: 25}}>
                        <Button title="Cancel" variant="ghost" onClick={() => navigate(-1)} />
                        <Button title="Save Changes" type="submit" />
                    </div>
                </Form.Form>
            </CardContainer>
        </div>
    );
}

export default EditPartnerPage;
