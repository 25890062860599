import { useEffect, useRef, useState } from 'react';

function usePosition<T extends HTMLElement>() {
    const ref = useRef<T>(null);
    const [isLast, setIsLast] = useState<boolean>();
    const [isFirst, setIsFirst] = useState<boolean>();
    const [index, setIndex] = useState<number>(NaN);

    useEffect(() => {
        if (ref.current) {
            if (ref.current.parentElement) {
                // get index of item within the parent
                const index = Array.from(ref.current.parentElement.children).indexOf(ref.current);
                setIndex(index);
                setIsLast(index === ref.current.parentElement.children.length - 1);
                setIsFirst(index === 0);
            }
        }
    }, [ref]);

    return [ref, index, isFirst, isLast] as const;
}

export default usePosition;
