import { isEmail, createForm } from '@application-production-system/react';
import { useNavigate, useParams } from 'react-router-dom';
import { AdminService } from '../../api/doc';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { selectOrganisation, updateOrganisation } from '../../store/organisationSlice';
import { selectPlanOptions } from '../../store/planSlice';
import { handleError } from '../../assets/constants';
import { CancelSubmitRow } from '../../components';
import { IdPageParams, ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';
import { useState } from 'react';
import { COUNTRIES, COUNTRY_OPTIONS } from '../../model/countries';

interface EditOrganisationForm {
    name: string;
    address1: string;
    suburb: string;
    postcode: string;
    phone: string;
    region: string;
    plan: string;
    email: string;
    color: string;
    state: string;
    logoName: string;
    productOwner: boolean;
}

const Form = createForm<EditOrganisationForm>();

export default function EditOrganisationPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { id } = useParams<IdPageParams>();

    const organisation = useAppSelector((state) => selectOrganisation(state, id));
    const planOptions = useAppSelector(selectPlanOptions);

    const [formData, setFormData] = useState<EditOrganisationForm>(organisation as EditOrganisationForm);

    const stateOptions = (COUNTRIES as any)[formData.region] ?? {};
    const stateRequired = Object.keys(stateOptions).length > 0;

    const onSubmit = () => {
        AdminService.updateOrganisation(organisation.id!, formData)
            .then(response => {
                dispatch(updateOrganisation({
                    organisationId: organisation.id,
                    newDetails: response.organisation
                }));
                navigate(ROUTES.organisationDetails.build({id: id}));
            })
            .catch(handleError);
    }

    return (
        <Page title={{page: "Organisations", subPage: "Edit Organisation", icon: ["far", "flag"]}}>
            <div style={{maxWidth: "600px"}}>
                <Form.Form title="Edit Organisation" onSubmit={onSubmit} data={formData} setData={setFormData}>
                    <Form.Entry  required for="name"         label="Name"          />
                    <Form.Entry  required for="email"        label="Email"         />
                    <Form.Entry           for="address1"     label="Address"       />
                    <Form.Entry           for="suburb"       label="Suburb"        />
                    <Form.Entry           for="postcode"     label="Postcode"      />
                    <Form.Select required for="region"       label="Region" options={COUNTRY_OPTIONS} />
                    <Form.Select required={stateRequired}
                                          for="state"        label="State" options={stateOptions}/>
                    <Form.Entry           for="phone"        label="Phone"         />
                    <Form.Entry           for="color"        label="Colour"        />
                    <Form.Select required for="plan"         label="Plan" options={planOptions} />
                    <Form.Checkbox        for="productOwner" label="Product Owner" />
                    <CancelSubmitRow title="Save Changes" />
                </Form.Form>
            </div>
        </Page>
    );
}
