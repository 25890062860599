import { InputProps } from '..';
import { IconProp, Icon } from '../../Icon/Icon';
import Label from '../Label/Label';
import useClasses from '../../../utility/useClasses';
import styles from './Entry.module.scss';

type InputKeyEvent = React.KeyboardEvent<HTMLInputElement>;
type InputChangeEvent = React.ChangeEvent<HTMLInputElement>;

export interface EntryProps extends InputProps<string> {
    placeholder?: string;
    leftIcon?: IconProp;
    rightIcon?: IconProp;
    size?: "s" | "sm";
    error?: string;
    type?: "text" | "password" | "date";
    style?: React.CSSProperties;
    value?: string;
    onKeyUp?: (event: InputKeyEvent) => void;
    onKeyDown?: (event: InputKeyEvent) => void;
    onPressEnter?: (event: InputKeyEvent) => void;
    onPressEscape?: (event: InputKeyEvent) => void;
}

export default function({
        label,
        size="s",
        error,
        leftIcon,
        rightIcon,
        style,
        onPressEnter,
        onPressEscape,
        onKeyUp,
        onKeyDown,
        onChange=() => {},
        ...props
    }: EntryProps
) {
    const keyUpHandler = (onPressEnter || onPressEscape || onKeyUp) && (
        (event: InputKeyEvent) => {
            if (onPressEnter && event.key == "Enter") {
                onPressEnter(event);
            }
            else if (onPressEscape && event.key == "Escape") {
                onPressEscape(event);
            }
            else if (onKeyUp) {
                onKeyUp(event);
            }
        }
    );

    const classes = useClasses(styles, {
        "entry": true,
        "icon-left": leftIcon,
        "icon-right": rightIcon,
        "small": size == "s",
        "small-medium": size == "sm",
        "error": error
    });

    return (
        <div className={styles["col-stack"]} style={style}>
            { label && <Label text={label} required={props.required}/> }
            <div className={styles["row"]}>
                <input
                    {...props}
                    className={classes}
                    onChange={e => onChange(e.target.value)}
                    onKeyDown={onKeyDown}
                    onKeyUp={keyUpHandler}
                />
                {leftIcon &&
                    <div className={styles["icon-container"]
                            + " " + styles["left"]}>
                        <Icon size={size == "s" ? "sm" : undefined}
                              className={styles["icon"]}
                              icon={leftIcon} />
                    </div>
                }
                <div className={styles["icon-container"]
                        + " " + styles["right"]}
                     onClick={() => onChange("")}>
                    <Icon size={size == "s" ? "sm" : undefined}
                          className={styles["icon"]}
                          icon={['fas', 'times-circle']} />
                </div>
                {rightIcon &&
                    <div className={styles["icon-container"] + " "
                            + styles["second-right"] + " " + styles[size]}>
                        <Icon size={size == "s" ? "sm" : undefined}
                              className={styles["icon"]}
                              icon={rightIcon} />
                    </div>
                }
            </div>
            <label className={styles["error-message"]}>
                {error}
            </label>
        </div>
    );
}
