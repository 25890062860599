import {useNavigate, useLocation, useParams} from 'react-router-dom';
import {AuditLogEntry, RenderLogEntry, StatisticsService} from '../../api/doc';
import { DetailTable } from '../../components';
import { formatTimestamp } from '../../assets/constants';
import { useAppSelector } from '../../hooks';
import { findUserByEmail } from '../../store/userSlice';
import { selectFormatProduct } from '../../store/productSlice';
import { selectOrganisationByName } from '../../store/organisationSlice';
import {IdPageParams, ROUTES} from '../../routes';
import { Page } from '../../components/Page/Page';
import {useEffect, useState} from "react";

export default function RenderLogDetailsPage() {
    const location = useLocation();
    const navigate = useNavigate();

    const { id } = useParams<IdPageParams>();

    const [ entry, setEntry ] = useState<RenderLogEntry|undefined>(undefined);

    useEffect(() => {
        StatisticsService.getRenderLogEntry(id!).then((response) => {
            setEntry(response.entry);
        });
    }, []);

    const organisation = useAppSelector((state) => selectOrganisationByName(state, entry?.organisation!));

    const user = useAppSelector((state) => findUserByEmail(state, entry?.email!));

    const product = useAppSelector((state) => selectFormatProduct(state, entry?.productCode));

    const onClick = (key: string) => {
        switch (key) {
            case "Email":
                if (user) {
                    navigate(ROUTES.userDetails.build({id: user.id}));
                }
                break;
            case "Product":
                if (product) {
                    navigate(ROUTES.productDetails.build({id: entry?.productCode}));
                }
                break;
            case "Organisation":
                if (organisation) {
                    navigate(ROUTES.organisationDetails.build({id: organisation.id}));
                }
                break;
        }
    }

    return (
        <Page title={{page: "Render Log Details", icon: ['far', 'print']}}>
            <div style={{maxWidth: "800px"}}>
                <h2>Email Log Entry Details</h2>
                <DetailTable
                    onClick={onClick}
                    clickable={[
                        user && "Email",
                        product && "Product",
                        organisation && "Organisation"
                    ]}
                    details={{
                        "ID": entry?.id,
                        "Date": formatTimestamp(entry?.timestamp!),
                        "Email": entry?.email,
                        "Emailed": entry?.emailed,
                        "Template": entry?.templateName,
                        "Document Size": entry?.documentBytes,
                        "Page Count": entry?.pageCount,
                        "Status": entry?.success
                            ? <span style={{color: "green"}}>Successful</span>
                            : <span style={{color: "red"}}>Failed</span>,
                        "Client Error": entry?.clientError,
                        "Server Error": entry?.serverError,
                        "Organisation": entry?.organisation,
                        "Product": product ? product : entry?.productCode,
                        "Source Device ID": entry?.sourceDeviceId,
                        "Inspection ID": entry?.inspectionId
                    }}
                />
            </div>
        </Page>
    );
}
