import { Modal, isRegex, createForm, CardContainer } from '@application-production-system/react';
import { uploadTemplate } from '../../api/override/doc';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CancelSubmitRow } from '../../components';
import { useAppDispatch } from '../../hooks';
import { createTemplate } from '../../store/templateSlice';
import { ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';

interface CreateTemplateForm {
    templateName: string;
    templateDescription: string;
    templateFile: FileList;
    devMode: boolean;
    isSystemTemplate: boolean;
}

const Form = createForm<CreateTemplateForm>();

function CreateTemplatePage() {
    const regex = /^[a-zA-Z0-9_. -]+\.docx?$/;
    const validTemplateName = isRegex(regex, "The template name must be a valid .doc/.docx file");

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const [errorReport, setErrorReport] = useState<any>();

    const [errorReportJSON, setErrorReportJSON] = useState<string>();

    const downloadBlob = (fileName: string, blob: Blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }

    const [formData, setFormData] = useState<CreateTemplateForm>({devMode: true} as CreateTemplateForm);

    const onSubmit = () => {
        setErrorReport(undefined);
        setErrorReportJSON(undefined);
        setLoading(true);
        uploadTemplate({
            ...formData,
            templateFile: formData.templateFile[0]
        })
        .then((response) => {
            setLoading(false);
            dispatch(createTemplate(response.data.templateDetails));
            navigate(ROUTES.templateDetails.build({id: response.data.templateDetails.name}));
        })
        .catch((reason) => {
            let data = reason.response.data;
            if (data?.type === 'application/json') {
                data.text().then((t: string) => {
                    let json = JSON.parse(t);
                    if (json?.tornadoError) {
                        let tornadoJson = JSON.parse(json.tornadoError);
                        if (tornadoJson?.shortMsg) {
                            setErrorReportJSON(tornadoJson.shortMsg);
                        }
                    } else if (json?.shortMsg) {
                        setErrorReportJSON(json.shortMsg)
                    }
                });
            } else {
                setErrorReport(data);
            }
            setLoading(false);
            setModalVisible(true);
        });
    }

    const downloadSummary = () => {
        downloadBlob("Error Report.pdf", errorReport);
    }

    const onModalAction = (action: string) => {
        switch (action) {
            case "Download Summary":
                downloadSummary();
                break;
        }
    }

    return (
        <Page title={{page: "Templates", subPage: "Create Template", icon: ['far', 'page']}}>
            <div>
                <Modal
                    title="Invalid Template"
                    visible={modalVisible}
                    onClose={()=>setModalVisible(false)}
                    actions={errorReport !== undefined ? ["Download Summary", "Close"] : ["Close"]}
                    actionReducer={onModalAction}
                >
                    <div>The uploaded template contains errors.</div>
                    {
                        errorReportJSON !== undefined &&
                        (
                        <div style={{marginTop: "15px"}}>{errorReportJSON}</div>
                        )
                    }
                </Modal>
                <div style={{maxWidth: "600px"}}>
                    <CardContainer title="Create Template">
                        <Form.Form onSubmit={onSubmit} data={formData} setData={setFormData}>
                            <Form.Entry      required for="templateName"        label="Name" validate={validTemplateName}/>
                            <Form.TextArea            for="templateDescription" label="Description" />
                            <Form.FileUpload required for="templateFile"        label="File Upload" />
                            <Form.Checkbox            for="devMode"             label="Developer Mode" />
                            <Form.Checkbox            for="isSystemTemplate"    label="System Template" />
                            <CancelSubmitRow disabled={loading} />
                        </Form.Form>
                    </CardContainer>
                </div>
            </div>
        </Page>
    );
}

export default CreateTemplatePage;
