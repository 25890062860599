import { Table, Row, Cell, HeaderRow, HeaderCell, Button } from '@application-production-system/react';
import { IoAddCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AdminService, ConsoleIntegration } from '../../api/console';
import { ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';

function IntegrationsPage() {
    const [integrations, setIntegrations] = useState<ConsoleIntegration[]>([]);

    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState("Loading...");

    const navigate = useNavigate();

    useEffect(()=> {
        AdminService.findIntegrations()
        .then(response => {
            setSuccess(true);
            setMessage(response.integrations!.length
                ? "" : "No integrations to display.");
            setIntegrations(response.integrations!);
        })
        .catch(error => {
            setSuccess(false);
            setMessage(error.status == 403
                ? "You do not have permission to view integrations."
                : "Something went wrong."
            );
        })
    }, [])

    const createIntegrationsTable = () => {
        return !integrations.length ? message : (
            <Table border data={integrations} divider={false}>
                <>
                <HeaderRow>
                    <HeaderCell><b>Intergration ID</b></HeaderCell>
                    <HeaderCell><b>Office ID</b></HeaderCell>
                    <HeaderCell><b>Partner ID</b></HeaderCell>
                    <HeaderCell><b>Status</b></HeaderCell>
                </HeaderRow>
                {integrations.map((integration: ConsoleIntegration, idx: number) => {
                    return (
                        <Row
                            key={idx}
                            onClick={() => {
                                navigate(ROUTES.integrationDetails.build({id: integration.id}));
                            }}
                            divider={true}
                            striped={idx % 2 === 1}
                        >
                            <Cell>{ integration.integrationId }</Cell>
                            <Cell>{ integration.partnerId }</Cell>
                            <Cell>{ integration.officeId }</Cell>
                            <Cell>{
                                integration.verified
                                    ? <span style={{color: "green"}}>Verified</span>
                                    : <span style={{color: "red"}}>Unverified</span>
                            }</Cell>
                        </Row>
                    );
                })}
                </>
            </Table>
        )
    }

    return (
        <Page title={{page: "Integrations", icon: ['far', 'shapes']}}>
            <div style={{overflowX: "hidden"}}>
                <div style={{display: "flex"}}>
                    <h2>Integrations</h2>
                    <div style={{marginLeft: "auto", marginTop: "auto", marginBottom: "10px"}}>
                        <Button
                            disabled
                            title="New Integration"
                            icon={['far', 'plus-circle']}
                            iconPosition="left"
                            onClick={() => {
                                navigate(ROUTES.createIntegration.build());
                            }}
                        />
                    </div>
                </div>
                <div style={{overflowX: "scroll"}}>
                    <div style={{minWidth: "500px"}}>
                        {createIntegrationsTable()}
                    </div>
                </div>
            </div>
        </Page>
    );
}

export default IntegrationsPage;
