import { useContext } from 'react';
import ThemeContext from '../components/Theme/ThemeContext';
import { ColorNames } from '../theme/color';

function useThemeColors() {
    const { colors, setColors } = useContext(ThemeContext);

    const setSingleColor = (name: ColorNames, value: string) => {
        setColors({
            ...colors,
            [name]: value,
        });
    };

    return { colors, setSingleColor };
}

export default useThemeColors;
