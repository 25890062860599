import styles from './Badge.module.scss';

interface BadgeProps {
    variant?: 'primary' | 'info' | 'success' | 'warning' | 'danger';
    title?: string;
}

function Badge({ variant = 'primary', title }: BadgeProps) {
    return <div className={`${styles['badge']} ${styles[`badge-${variant}`]}`}>{title}</div>;
}

export default Badge;
