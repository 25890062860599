import React from 'react';
import Modal from '../Modal/Modal';

/*
    This class currently doesn't work when imported :(
*/

interface ErrorBoundaryProps {
    children: React.ReactElement;
    title: string;
    body: React.ReactElement;
    onAcknowledge: () => void;
};

interface ErrorBoundaryState {
    hasError: boolean;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error) {
        return {
            hasError: true
        };
    }

    onModalClose = () => {
        this.props.onAcknowledge();
        this.setState({
            hasError: false,
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <Modal
                    title={this.props.title}
                    status="danger"
                    visible={true}
                    onClose={this.onModalClose}
                    actions={["Ok"]}
                    actionReducer={this.onModalClose}
                >
                    {this.props.body}
                </Modal>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
