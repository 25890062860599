import { CSSProperties } from 'react';
import { InputProps } from '..';
import styles from './TextArea.module.scss';
import Label from '../Label/Label';

export interface TextAreaProps extends InputProps<string> {
    placeholder?: string;
    style?: CSSProperties;
}

function TextArea({
        label,
        error,
        placeholder,
        style,
        onChange=() => {},
        value,
        ...props
    }: TextAreaProps
) {
    return (
        <div className={styles["col-stack"]} style={style}>
            { label &&
                <Label text={label} required={props.required} />
            }

            <textarea
                {...props}
                className={styles['textarea']}
                placeholder={placeholder}
                value={value || ""}
                onChange={e => onChange(e.target.value)}
            />

            <label className={styles["error-message"]}>
                {error}
            </label>
        </div>
    );
}

export default TextArea;
