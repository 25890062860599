import { UrlBreadcrumbs, Breadcrumbs } from '@application-production-system/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectUsers } from '../../../store/userSlice';
// import { NucResponseActions, useAppSelector } from '../../../redux';
import { ROUTES } from '../../../routes';
import { useAppSelector } from '../../../hooks';
import { selectOrganisations } from '../../../store/organisationSlice';
import { selectPlans } from '../../../store/planSlice';
import { selectAuditLogs } from '../../../store/auditLogSlice';

const BREADCRUMBS_CONFIG = {
    "users": {
        callback: (state: any, id: string) => {
            const user = state.users.find((u: any) => u.id == id);
            if (!user) {
                return "User"
            }
            return (user?.firstName || "") + " " + (user?.lastName || "");
        }
    },
    "organisations": {
        callback: (state: any, id: string) => {
            const org = state.organisations.find((o: any) => o.id == id);
            if (!org) {
                return "Organisation"
            }
            return org?.name;
        }
    },
    "plans": {
        callback: (state: any, id: string) => {
            const plan = state.plans.find((p: any) => p.code == id);
            if (!plan) {
                return "Plan"
            }
            return plan?.name;
        }
    },
    "products": {
        callback: (state: any, id: string) => {
            return id;
        }
    },
    "templates": {
        callback: (state: any, id: string) => {
            return id;
        }
    },
    "audit-log": {
        callback: (state: any, id: string) => {
            const auditLog = state.auditLogs.find((a: any) => a.code == id);
            if (!auditLog) {
                return "Details"
            }
            return "yes";
        }
    },
    "email-log": {
        callback: (state: any, id: string) => {
            return "Details"
        }
    },
}

interface AppBreadcrumbsProps {
    // override automatic breadcrumbs
    breadcrumbsOverride?: string[];
}

export function AppBreadcrumbs({breadcrumbsOverride}: AppBreadcrumbsProps) {

    const location = useLocation();
    const navigate = useNavigate();

    const users = useAppSelector(selectUsers);
    const organisations = useAppSelector(selectOrganisations);
    const plans = useAppSelector(selectPlans);
    const auditLogs = useAppSelector(selectAuditLogs);

    console.log("users:", users);

    if (breadcrumbsOverride) {
        const crumbs = breadcrumbsOverride.map(crumb => {
            return {
                title: crumb, onClick: () => {}
            }
        });

        return (
            <Breadcrumbs
                homeCrumb={['far', 'house']}
                crumbs={crumbs}
            />
        );
    }

    // const samples = useAppSelector(NucResponseActions.getSamples);
    // const testEvents = useAppSelector(NucResponseActions.getTestEvents);
    // const labels = useAppSelector(NucResponseActions.getLabelEvents);

    return (
        <div style={{whiteSpace: "nowrap"}}>
            <UrlBreadcrumbs
                location={location}
                navigate={navigate}
                config={BREADCRUMBS_CONFIG}
                state={{users, organisations, plans, auditLogs}}
                onClickHome={() => navigate(ROUTES.dashboard.build())}
            />
        </div>
    );

}
