import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { DocumentService, TemplateDetails } from '../api/doc';
import type { RootState } from '.'

interface TemplateState {
    templates: TemplateDetails[];
    status: number;
    lookup: {[templateName: string]: TemplateDetails};
}

const initialState: TemplateState = {
    lookup: {},
    status: 0,
    templates: []
}

export const refreshTemplates = createAsyncThunk(
    'template/fetch',
    async (_) => {
        let status = 200;
        const response = await DocumentService.findTemplatesV2()
            .catch(error => {
                status = error.status;
                return {templateDetails: undefined};
            });
        return {
            status: status,
            templates: response.templateDetails
        }
    }
)

const removeTemplateFromList = (templates: TemplateDetails[], templateName: string) => {
    return templates.filter(template => template.name !== templateName);
}

const removeTemplateFromLookup = (lookup: {[key: string]: TemplateDetails}, templateName: string) => {
    return Object.fromEntries(
        Object.entries(lookup)
        .filter((entry, _) => entry[0] !== templateName)
    );
}

export const templateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {
        createTemplate: (state, action: PayloadAction<TemplateDetails|undefined>) => {
            if (action.payload) {
                state.templates.push(action.payload);
                state.lookup = {...state.lookup, [action.payload.name!]: action.payload};
            }
        },

        removeTemplate: (state, action: PayloadAction<string|undefined>) => {
            if (action.payload != undefined) {
                state.templates = removeTemplateFromList(state.templates, action.payload);
                state.lookup = removeTemplateFromLookup(state.lookup, action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(refreshTemplates.fulfilled, (state, action) => {
            state.templates = action.payload.templates || [];
            state.status = action.payload.status;
            state.lookup = {};
            state.templates.forEach((template: TemplateDetails, _) => {
                state.lookup[template.name!] = template;
            });
        });
    }
});

export const { createTemplate, removeTemplate } = templateSlice.actions;

export const formatTemplate = (template: TemplateDetails) => {
    return template.name!;
}

export const selectTemplate = (state: RootState, templateName?: string) => state.template.lookup[templateName!];

export const selectTemplateStatus = (state: RootState) => state.template.status;

export const selectTemplates = (state: RootState) => state.template.templates;

export const selectFormatTemplate = (state: RootState, templateName?: string) => {
    return templateName ? formatTemplate(state.template.lookup[templateName]) : "";
}

export const selectTemplateOptions = (state: RootState) => {
    const templateOptions: {[key: string]: string} = {};
    state.template.templates.forEach((template, _) => {
        templateOptions[template.name!] = formatTemplate(template);
    });
    return templateOptions;
};

export default templateSlice.reducer;
