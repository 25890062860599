import { Icon, IconProp } from '@application-production-system/react';
import { useAppSelector } from '../../../hooks';
import { selectPageTitle } from '../../../store/sessionSlice';
import styles from './PageTitle.module.scss';

interface PageIconProps {
    icon?: IconProp;
};

function PageIcon({icon}: PageIconProps) {
    return icon ? <Icon icon={icon} /> : null;
}

export function PageTitle() {
    const title = useAppSelector(selectPageTitle);

    const pageName = title.page || "Nucleotrace";
    const subPageName = title.subPage;
    const pageIcon = title.icon;

    if (subPageName) {
        return (
            <div className={styles.title}>
                <PageIcon icon={pageIcon} />
                <div style={{marginTop: 2, display: "flex", gap: 8}}>
                    <div>{pageName}</div>
                    <div>-</div>
                    <div className={styles.sub_page}>
                        {subPageName}
                    </div>
                </div>
            </div>
        );
    }

    else {
        return (
            <div className={styles.title}>
                <PageIcon icon={pageIcon} />
                <div style={{marginTop: 2}}>
                    {pageName}
                </div>
            </div>
        );
    }
}
