import { ButtonHTMLAttributes, CSSProperties } from 'react';
import styles from './Button.module.scss';
import { Icon, IconProp } from '../Icon/Icon';
import useClasses from '../../utility/useClasses';

export type ButtonVariant = 'primary' | 'secondary' | 'secondary-dark' | 'secondary-light' | 'danger' | 'ghost';

export type ButtonSize = 's' | 'sm' | 'm' | 'l';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: ButtonVariant;
    size?: ButtonSize;
    icon?: IconProp;
    iconPosition?: 'left' | 'right';
}

function Button({
        variant = 'primary',
        size = 'sm',
        icon,
        iconPosition = 'left',
        title,
        ...props
    }: ButtonProps
) {

    const classes = useClasses(styles, {
        [variant + "-" + size]: true,
        "flex-row": iconPosition === "left",
        "flex-row-reverse": iconPosition === "right",
    });

    return (
        <button {...props} className={classes}>
            {icon &&
                <Icon icon={icon} />
            }
            {title &&
                <span>{title}</span>
            }
        </button>
    );
}
export default Button;
