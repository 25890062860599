import { useRef, useState } from 'react';

interface UseSchmittTriggerOptions {
    midpoint?: number;
    hysteresis?: number;
    initial?: boolean;
    inverted?: boolean;
}

enum Direction {
    Left,
    Right,
    None,
}

function useSchmittTrigger({
    midpoint = 0,
    hysteresis = 0,
    initial = false,
    inverted = false,
}: UseSchmittTriggerOptions) {
    const [triggered, setTriggered] = useState<boolean>(initial);
    const leftTrigger = midpoint - hysteresis;
    const rightTrigger = midpoint + hysteresis;
    const previousPosition = useRef<number>();
    const direction = useRef<Direction>(Direction.None);

    const outputFunction = (position: number) => {
        const stepFunctionFactory = (threshold: number) => (t: number) => {
            return t < threshold ? false : true;
        };

        const leftStepFunction = stepFunctionFactory(leftTrigger);
        const rightStepFunction = stepFunctionFactory(rightTrigger);

        const isInHysteresis =
            leftStepFunction(position) !== rightStepFunction(position);
        const hasEnteredHysteresis =
            direction.current === Direction.None && isInHysteresis;

        // initialize previous position if it hasn't been set yet
        if (!previousPosition.current) {
            previousPosition.current = position;
        }

        const deltaIsPositive = previousPosition.current < position;
        const deltaIsNegative = previousPosition.current > position;

        var result = leftStepFunction;

        if (hasEnteredHysteresis) {
            if (deltaIsPositive) {
                direction.current = Direction.Right;
                result = rightStepFunction;
            } else if (deltaIsNegative) {
                direction.current = Direction.Left;
                result = leftStepFunction;
            }
        } else if (isInHysteresis) {
            if (direction.current === Direction.Right) {
                result = rightStepFunction;
            } else if (direction.current === Direction.Left) {
                result = leftStepFunction;
            }
        } else {
            direction.current = Direction.None;
        }

        previousPosition.current = position;

        const shouldSetPosition = result(position) !== triggered;
        if (shouldSetPosition) {
            setTriggered(result(position));
        }

        return result(position);
    };

    return [
        (triggered || inverted) && !(triggered && inverted),
        outputFunction,
    ] as const;
}

export default useSchmittTrigger;
