/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthenticatedURLResponse } from '../models/AuthenticatedURLResponse';
import type { DocmosisResponse } from '../models/DocmosisResponse';
import type { RenderRequest } from '../models/RenderRequest';
import type { TemplateDeleteForm } from '../models/TemplateDeleteForm';
import type { TemplateGetForm } from '../models/TemplateGetForm';
import type { TemplateListForm } from '../models/TemplateListForm';
import type { TemplateResponse } from '../models/TemplateResponse';
import type { TemplatesResponse } from '../models/TemplatesResponse';
import type { TemplateTestForm } from '../models/TemplateTestForm';
import type { TemplateUploadForm } from '../models/TemplateUploadForm';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocumentService {

    /**
     * Render Document
     * @param requestBody
     * @returns string A Rendered Document (May be a zip if multiple formats requested)
     * @throws ApiError
     */
    public static renderDocument1(
        requestBody: RenderRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/render/render',
            body: requestBody,
            mediaType: 'application/json',
            responseHeader: 'X-DocSvc-Pickup-Link',
            errors: {
                400: `Incorrect parameters for render request`,
                403: `Access denied`,
                404: `The template is missing`,
                406: `Errors in document`,
                500: `The document failed to render`,
            },
        });
    }

    /**
     * Delete the named template
     * @param formData
     * @returns DocmosisResponse The delete result
     * @throws ApiError
     */
    public static deleteTemplate(
        formData?: TemplateDeleteForm,
    ): CancelablePromise<DocmosisResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/templates/deleteTemplate',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
        });
    }

    /**
     * Download a template
     * @param formData
     * @returns any The template stream
     * @throws ApiError
     */
    public static downloadTemplate(
        formData?: TemplateGetForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/templates/getTemplate',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
        });
    }

    /**
     * Gets a Template URL for direct download
     * @param formData
     * @returns AuthenticatedURLResponse The signed url for the template
     * @throws ApiError
     */
    public static downloadTemplate1(
        formData?: TemplateGetForm,
    ): CancelablePromise<AuthenticatedURLResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/templates/getTemplateURL',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                404: `The template is missing`,
            },
        });
    }

    /**
     * List available templates
     * @param formData
     * @returns TemplatesResponse The available templates
     * @throws ApiError
     */
    public static findTemplates(
        formData?: TemplateListForm,
    ): CancelablePromise<TemplatesResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/templates/listTemplates',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
        });
    }

    /**
     * Does a dummy render for a template and returns a pdf which will contain any errors.
     * @param formData
     * @returns any Returns the template errors as a pdf
     * @throws ApiError
     */
    public static testTemplate(
        formData?: TemplateTestForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/templates/testTemplate',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
        });
    }

    /**
     * Upload Template
     * Upload a new template. If the template has errors this will return a pdf with the error markup
     * @param formData
     * @returns TemplateResponse Upload a docmosis template
     * @throws ApiError
     */
    public static uploadTemplate(
        formData?: TemplateUploadForm,
    ): CancelablePromise<TemplateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/templates/uploadTemplate',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                403: `Access Denied`,
                406: `The pdf containing the errors`,
                500: `An error occurred`,
            },
        });
    }

    /**
     * Delete the named template
     * @param requestBody
     * @returns DocmosisResponse The delete result
     * @throws ApiError
     */
    public static deleteTemplateV2(
        requestBody?: TemplateDeleteForm,
    ): CancelablePromise<DocmosisResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/docsvc/v2/templates/deleteTemplate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Download a template
     * @param templateName
     * @param systemTemplate
     * @returns any The template stream
     * @throws ApiError
     */
    public static downloadTemplateV2(
        templateName: string,
        systemTemplate: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v2/templates/getTemplate',
            query: {
                'templateName': templateName,
                'systemTemplate': systemTemplate,
            },
        });
    }

    /**
     * Gets a Template URL for direct download
     * @param templateName
     * @param systemTemplate
     * @returns AuthenticatedURLResponse The signed url for the template
     * @throws ApiError
     */
    public static downloadTemplateUrlv2(
        templateName: string,
        systemTemplate: boolean,
    ): CancelablePromise<AuthenticatedURLResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v2/templates/getTemplateURL',
            query: {
                'templateName': templateName,
                'systemTemplate': systemTemplate,
            },
            errors: {
                404: `The template is missing`,
            },
        });
    }

    /**
     * List available templates
     * @returns TemplatesResponse The available templates
     * @throws ApiError
     */
    public static findTemplatesV2(): CancelablePromise<TemplatesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v2/templates/listTemplatesV2',
        });
    }

    /**
     * Does a dummy render for a template and returns a pdf which will contain any errors.
     * @param templateName
     * @param systemTemplate
     * @returns any Returns the template errors as a pdf
     * @throws ApiError
     */
    public static testTemplateV2(
        templateName: string,
        systemTemplate: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v2/templates/testTemplate',
            query: {
                'templateName': templateName,
                'systemTemplate': systemTemplate,
            },
        });
    }

    /**
     * Upload Template
     * Upload a new template. If the template has errors this will return a pdf with the error markup
     * @param formData
     * @returns TemplateResponse Upload a docmosis template
     * @throws ApiError
     */
    public static uploadTemplateV2(
        formData?: TemplateUploadForm,
    ): CancelablePromise<TemplateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v2/templates/uploadTemplate',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                403: `Access Denied`,
                406: `The pdf containing the errors`,
                500: `An error occurred`,
            },
        });
    }

}
