import { createContext } from 'react';

export interface TabBarContextProps {
    size: 's' | 'm';
    currentIndex: number;
    setCurrent: (index: number, ref: React.RefObject<HTMLElement>, animate: boolean, children?: React.ReactNode) => void;
}

const TabBarContext = createContext<TabBarContextProps>({
    size: 's',
    currentIndex: 0,
    setCurrent: (index: number, ref: React.RefObject<HTMLElement>, animate: boolean, children?: React.ReactNode) => {},
});

export default TabBarContext;
