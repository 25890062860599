import { Button } from '@application-production-system/react';
import { useState } from 'react';
import { StatisticsService } from '../../api/doc';
import { formatTimestamp, getErrorMessage } from '../../assets/constants';
import styles from './AuditLogPage.module.scss';
import { Page } from '../../components/Page/Page';
import { AuditLogTable } from './AuditLogTable';
import { AuditLogFilterForm, CachedEntry } from './types';
import { AuditLogForm } from './AuditLogForm';

interface FilterConfig {
    entries: CachedEntry[];
    page: number;
    perPage: number;
    sortBy?: string;
    email?: string;
    emailFrom?: string,
    emailTo?: string,
    inspectionId?: string,
    name?: string;
    authUser?: string;
    user?: string;
}

function AuditLogPage() {

    const [state, setState] = useState<FilterConfig>({
        entries: [],
        page: 0,
        perPage: 50,
    });

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [outOfLogs, setOutOfLogs] = useState(false);

    // from AuditDetailKeys.java:
    // EMAIL_FROM, EMAIL_TO, INSPECTION_ID, FILE_NAME,
    // EMAIL, USER_ID, LOGIN_AS_USER_ID, NAME

    const createFilterString = (config: FilterConfig) => {
        let filters: string[] = [];
        if (config.inspectionId) {
            filters.push("INSPECTION_ID=" + config.emailFrom);
        }
        if (config.emailTo) {
            filters.push("EMAIL_TO=" + config.emailTo);
        }
        if (config.emailFrom) {
            filters.push("EMAIL_FROM=" + config.emailFrom);
        }
        if (config.email) {
            filters.push("EMAIL=" + config.email);
        }
        if (config.name) { filters.push("NAME=" + config.name);
        }
        return filters.join(",");
    }

    const loadPage = (config: FilterConfig) => {
        setLoading(true);
        StatisticsService.findAuditLogEntries(
            config.page,
            config.perPage,
            undefined,
            config.authUser,
            config.user,
            createFilterString(config),
            config.sortBy
        )
        .then((response) => {
            setLoading(false);
            if (response.succeeded!) {
                if (response.entries!.length < config.perPage) {
                    setOutOfLogs(true);
                    if (response.entries!.length === 0) {
                        return;
                    }
                }
                const newEntries: CachedEntry[] = [];
                response.entries!.forEach((entry, _) => {
                    newEntries.push({
                        entry: entry,
                        formattedDate: formatTimestamp(entry.timestamp!)
                    });
                });
                config.entries = [...config.entries, ...newEntries];
                config.page += 1;
                setState(config);
            }
            else {
                alert("Something went wrong");
                console.error("Error loading logs", response.shortMsg, response.longMsg);
            }
        })
        .catch((reason) => {
            setMessage(getErrorMessage(reason.status));
            console.error("Error loading logs", reason);
            setLoading(false);
        });
    }

    const [formData, setFormData] = useState<AuditLogFilterForm>({
        sortBy: "timestamp",
        sortOrder: "desc",
    } as AuditLogFilterForm);

    const onSubmit = () => {
        state.page = 0;
        state.entries = [];

        state.email        = formData.email        && encodeURI(formData.email);
        state.emailFrom    = formData.emailFrom    && encodeURI(formData.emailFrom);
        state.emailTo      = formData.emailTo      && encodeURI(formData.emailTo);
        state.inspectionId = formData.inspectionId && encodeURI(formData.inspectionId);
        state.name         = formData.name         && encodeURI(formData.name);
        state.authUser     = formData.authUser     && encodeURI(formData.authUser);
        state.user         = formData.user         && encodeURI(formData.user);
        state.sortBy       = formData.sortBy + " " + formData.sortOrder;

        loadPage(state);
    }

    return (
        <Page title={{page: "Audit Logs", icon: ['far', 'clipboard']}}>
            <div style={{overflowX: "hidden"}}>
                <h2>Audit Logs</h2>
                <AuditLogForm
                    onSubmit={onSubmit}
                    formData={formData}
                    setFormData={setFormData}
                    loading={loading}
                />
                <AuditLogTable entries={state.entries} message={message} />
                { !state.entries.length ? undefined :
                    <div className={styles["middle-div-container"]}>
                        <div style={{marginTop: 15}}>
                            <Button
                                disabled={loading || outOfLogs}
                                title={loading ? "Loading..." : (outOfLogs ? "No more logs" : "Load More")}
                                iconPosition="left"
                                onClick={() => loadPage(state)}
                            />
                        </div>
                    </div>
                }
            </div>
        </Page>
    );
}

export default AuditLogPage;
