import styles from './Header.module.scss';
import { Button } from '@application-production-system/react';
import { AppBreadcrumbs } from './AppBreadcrumbs/AppBreadcrumbs';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { CollapseSidebarButton }
    from './CollapseSidebarButton/CollapseSidebarButton';
import { OpenDrawerButton }
    from './OpenDrawerButton/OpenDrawerButton';
import { PageTitle } from './PageTitle/PageTitle';
import { logout, selectToken, selectUser, selectProductCode, setSidebarCollapsed, selectSidebarCollapsed } from '../../store/sessionSlice'
import { selectProduct } from '../../store/productSlice'
import { useNavigate } from 'react-router-dom';
import { titleCase } from '../../assets/constants';

function Header() {
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const navigate = useNavigate();
    const productCode = useAppSelector(selectProductCode);

    const user = useAppSelector(selectUser);
    const product = useAppSelector((state) => selectProduct(state, productCode));

    const sidebarCollapsed = useAppSelector(selectSidebarCollapsed);

    const onLogout = () => {
        navigate("/");
        dispatch(logout());
    }

    const hideCollapse = false;

    return (
        <header className={styles.banner}>
            <div className={styles.top_row}>
                <div className={styles.top_left_row}>
                    <div className={styles.collapse_sidebar}>
                        { !hideCollapse &&
                            <CollapseSidebarButton />
                        }
                    </div>
                    <div className={styles.open_drawer}>
                        { !hideCollapse &&
                            <OpenDrawerButton />
                        }
                    </div>
                    <h3 className={styles.title}>
                        PIM Admin Dashboard
                    </h3>
                </div>
                <div style={{display: "flex", gap: 32, marginRight: 24}}>
                    <div className={styles.user_section}>
                        { user &&
                            <p className={styles.user_details}>
                                {titleCase(user!.firstName + " " + user!.lastName)} ({titleCase(user!.roles![0])})
                                <br/>
                                <b>{product ? product.name : productCode}</b>
                            </p>
                        }
                        <div className={styles.logout_button}>
                            <Button
                                title="Logout"
                                variant="primary"
                                onClick={onLogout}
                            />
                        </div>
                    </div>
                    {/*<NucTraceIcon color="var(--neutral-10)" height={40} />*/}
                    {/*<NetworkIndicator />*/}
                </div>
            </div>
            <div className={styles.bottom_row}>
                <PageTitle />
                <div className={styles.breadcrumbs}>
                    <AppBreadcrumbs
                        breadcrumbsOverride={undefined}
                    />
                </div>
            </div>
        </header>

    );
}

export default Header;
