import { Table, Row, Cell, HeaderRow, HeaderCell} from '@application-production-system/react';
import { ROUTES } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { AuditLogEntry } from '../../api/doc';
import { CachedEntry } from './types';
import styles from './AuditLogPage.module.scss';

interface AuditLogTableProps {
    entries: CachedEntry[];
    message: string;
}

export function AuditLogTable({entries, message}: AuditLogTableProps) {
    const navigate = useNavigate();

    const viewEntry = (entry: AuditLogEntry) => {
        navigate(ROUTES.auditLogDetails.build({id: entry.id}));
    }

    let contents;
    if (!entries.length) {
        contents = message;
    }
    else {
        contents = (
            <Table border data={entries} divider={false}>
                <>
                    <HeaderRow>
                        <HeaderCell><b>Date</b></HeaderCell>
                        <HeaderCell><b>User</b></HeaderCell>
                        <HeaderCell><b>Organisation</b></HeaderCell>
                        <HeaderCell><b>Description</b></HeaderCell>
                    </HeaderRow>
                {entries.map((entry, idx) => {
                    return (
                        <Row
                            key={idx}
                            onClick={() => {viewEntry(entry.entry)}}
                            divider={true}
                            striped={idx % 2 === 1}
                        >
                            <Cell>{ entry.formattedDate }</Cell>
                            <Cell>{ entry.entry.id }</Cell>
                            <Cell>{ entry.entry.organisation }</Cell>
                            <Cell>{ entry.entry.description }</Cell>
                        </Row>
                    );
                })}
                </>
            </Table>
        );
    }

    return (
        <div className={styles["table-container"]}>
            <div style={{minWidth: "500px"}}>
                {contents}
            </div>
        </div>
    );
}
