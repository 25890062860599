import { Table, Row, Cell, Button, HeaderRow, HeaderCell } from '@application-production-system/react';
import { IoAddCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { PlanDetails } from '../../api/doc';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { selectPlans, selectPlanStatus, refreshPlans } from '../../store/planSlice';
import { useEffect } from 'react';
import { getErrorMessage } from '../../assets/constants';
import { ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';


export default function PlansPage() {
    const plans = useAppSelector(selectPlans);
    const status = useAppSelector(selectPlanStatus);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(refreshPlans());
    }, [])

    const createPlansTable = () => {
        return status !== 200 ? getErrorMessage(status) : (!plans.length ? "No plans to display." : (
            <Table border data={plans} divider={false}>
                <>
                <HeaderRow>
                    <HeaderCell><b>Name</b></HeaderCell>
                    <HeaderCell><b>Code</b></HeaderCell>
                    <HeaderCell><b>Product</b></HeaderCell>
                    <HeaderCell><b>Status</b></HeaderCell>
                </HeaderRow>
                {plans.map((plan: PlanDetails, idx: number) => {
                    return (
                        <Row
                            key={idx}
                            onClick={() => {navigate(ROUTES.planDetails.build({id: plan.code}))}}
                            divider={true}
                            striped={idx % 2 === 1}
                        >
                            <Cell>{ plan.name }</Cell>
                            <Cell>{ plan.code }</Cell>
                            <Cell>{ plan.productCode }</Cell>

                            <Cell>{
                                plan!.active
                                    ? <span style={{color: "green"}}>Active</span>
                                    : <span style={{color: "red"}}>Inactive</span>
                            }</Cell>
                        </Row>
                    );
                })}
                </>
            </Table>
        ))
    }

    return (
        <Page title={{page: "Plans", icon: ['far', 'clock']}}>
            <div style={{overflowX: "hidden"}}>
                <div style={{display: "flex"}}>
                    <h2>Plans</h2>
                    <div style={{marginLeft: "auto", marginTop: "auto", marginBottom: "10px"}}>
                        <Button
                            disabled={status !== 200}
                            title="New Plan"
                            icon={['far', 'plus-circle']}
                            iconPosition="left"
                            onClick={() => {
                                navigate(ROUTES.createPlan.build());
                            }}
                        />
                    </div>
                </div>
                <div style={{overflowX: "scroll"}}>
                    <div style={{minWidth: "500px"}}>
                        {createPlansTable()}
                    </div>
                </div>
            </div>
        </Page>
    );
}
