import styles from './Notification.module.scss';
import CloseIcon from '../../assets/close.svg';
import useClasses from '../../utility/useClasses';
import { Icon, IconProp } from '../Icon/Icon';

type NotificationVariants = 'danger' | 'info' | 'success' | 'warning';

interface NotificationProps {
    status?: NotificationVariants;
    visible?: boolean;
    children?: React.ReactNode;
    title?: string;
    onClose?: () => void;
}

const icons: {[key in NotificationVariants]: IconProp} = {
    danger: ["fas", "exclamation-circle"],
    warning: ["fas", "exclamation-circle"],
    info: ["fas", "info-circle"],
    success: ["fas", "check-circle"],
}

const defaultOnClose = () => {
    console.info("No notification onClose handler provided");
}

function Notification({ status = 'info', visible = true, children, title, onClose=defaultOnClose }: NotificationProps) {

    const classes = useClasses(styles, {
        ["notification-" + status]: true,
        notification: true,
        hidden: !visible
    });

    return (
        <div className={classes}>
            <div className={styles['icon']}>
                <Icon size="lg" icon={icons[status]} />
            </div>
            <div className={styles['frame']}>
                <div className={styles['header']}>
                    <div className={styles['title']}>{title}</div>
                    <i className={styles['close']} onClick={onClose}>
                        <CloseIcon />
                    </i>
                </div>

                <div className={styles['content']}>{children}</div>
            </div>
        </div>
    );
}

export default Notification;
