import { useState, useReducer, FormEvent } from 'react';
import { FormContext } from './FormContext';
import Container from '../../components/Card/Container/Container';
import styles from './Form.module.scss';
import Loader from '../../components/Loader/Loader';


interface FormProps<T> {
    title?: string;
    children: React.ReactNode;
    depends?: any[];
    onSubmit?: () => void;
    onError?: () => void;
}

interface Field {
    required: boolean;
    error: string;
}

function Form<T>({
        title,
        children,
        onError=() => {},
        onSubmit=() => {},
        depends
    }: FormProps<T>
) {
    return (
        <FormContext.Provider value={{} as any}>
            {children}
        </FormContext.Provider>
    );
}

export default Form;
