import { useEffect, useRef, EffectCallback, DependencyList } from "react"

function useAfterEffect(effect: EffectCallback, deps?: DependencyList) {
    const notInitialRender = useRef(false)
    useEffect(() => {
        if (notInitialRender.current) {
            effect();
        }
        else {
            notInitialRender.current = true
        }
    }, deps);
}

export default useAfterEffect;
