/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CodesResponse } from '../models/CodesResponse';
import type { UserLoginRequest } from '../models/UserLoginRequest';
import type { UserLoginResponse } from '../models/UserLoginResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthenticationService {

    /**
     * Find All Product Codes
     * @returns CodesResponse List of product codes
     * @throws ApiError
     */
    public static findProductCodes(): CancelablePromise<CodesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/docsvc/v1/authenticate/productcodes',
        });
    }

    /**
     * Authenticate as a user within a product
     * @param docProductCode
     * @param requestBody
     * @returns UserLoginResponse The JWT used to authenticate api calls
     * @throws ApiError
     */
    public static login(
        docProductCode?: string,
        requestBody?: UserLoginRequest,
    ): CancelablePromise<UserLoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/docsvc/v1/authenticate/login',
            headers: {
                'Doc-Product-Code': docProductCode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Login failed`,
            },
        });
    }

}
