import Icon from '../Icon/Icon';
import useClasses from '../../utility/useClasses';
import styles from './DotPaginationControls.module.scss'

interface DottedPaginationControlsProps {
    pageName: string;
    page: number;
    numPages: number;
    onChange: (page: number) => void;
}

function DottedPaginationControls({pageName, page, numPages, onChange}: DottedPaginationControlsProps) {
    const createDots = () => {
        const dots: JSX.Element[] = [];

        for (let i = 0; i < numPages; i++) {
            const classes = useClasses(styles, {
                dot: true,
                filled: i <= page
            })
            dots.push(
                <div
                    key={i}
                    className={classes}
                    onClick={() => onClick(i)}
                >
                </div>
            );
        }

        return dots;
    }

    const onClick = (newPage: number) => {
        if (newPage != page && newPage >= 0 && newPage < numPages) {
            onChange(newPage);
        }
    }

    return (
        <div className={styles.column}>
            <div className={styles.page_name}>
                {pageName}
            </div>
            <div className={styles.row}>
                <Icon
                    icon={['far', 'angle-left']}
                    size="lg"
                    style={{marginRight: 10}}
                    onClick={() => onClick(page - 1)}
                    className={useClasses(styles, {
                        pagination_button: true,
                        disabled: page == 0
                    })}
                />
                <div className={styles.gapped_row}>
                { createDots() }
                </div>
                <Icon
                    icon={['far', 'angle-right']}
                    size="lg"
                    style={{marginLeft: 10}}
                    onClick={() => onClick(page + 1)}
                    className={useClasses(styles, {
                        pagination_button: true,
                        disabled: page == numPages - 1
                    })}
                />
            </div>
        </div>
    );
}

export default DottedPaginationControls;
