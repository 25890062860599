/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MediaZipResponse = {
    message?: string;
    succeeded?: boolean;
    shortMsg?: string;
    longMsg?: string;
    url?: string;
    state?: MediaZipResponse.state;
};

export namespace MediaZipResponse {

    export enum state {
        PREPARING = 'PREPARING',
        READY = 'READY',
    }


}

