import styles from './Row.module.scss';
import useClasses from '../../../utility/useClasses';
import { CSSProperties } from 'react';

export interface RowProps {
    children?: React.ReactNode;
    divider?: boolean;
    striped?: boolean;
    hover?: boolean;
    onClick?: () => void;
    style?: CSSProperties;
}

function Row({ children, onClick, divider, hover, striped, style }: RowProps) {
    const classes = useClasses(styles, {
        row: true,
        striped: striped,
        divider: divider,
        hover: hover,
        clickable: onClick
    });

    return (
        <tr className={classes} onClick={onClick} style={style}>
            {children}
        </tr>
    );
}

export default Row;
