import { useNavigate, useParams } from 'react-router-dom';
import { DetailTable } from '../../components';
import { formatTimestamp } from '../../assets/constants';
import { useAppSelector } from '../../hooks';
import { findUserByEmail, findUserByUserId, selectFormatUser, selectUser, selectUsers } from '../../store/userSlice';
import { selectFormatProduct } from '../../store/productSlice';
import { selectOrganisationByName } from '../../store/organisationSlice';
import { IdPageParams, ROUTES } from '../../routes';
import { selectAuditLog } from '../../store/auditLogSlice';
import { AuditLogEntry, StatisticsService } from '../../api/doc';
import { useEffect, useState } from 'react';
import { Page } from '../../components/Page/Page';

function UserDetailsPage() {
    const navigate = useNavigate();

    const { id } = useParams<IdPageParams>();

    const [ entry, setEntry ] = useState<AuditLogEntry|undefined>(undefined);

    useEffect(() => {
        StatisticsService.getAuditLogEntry(id!).then((response) => {
            setEntry(response.entry);
        });
    }, []);

    const user = useAppSelector((state) => findUserByUserId(state, entry?.userId!));
    const authUser = useAppSelector((state) => findUserByUserId(state, entry?.authUserId!));
    const product = useAppSelector((state) => selectFormatProduct(state, entry?.productCode));
    const organisation = useAppSelector((state) => selectOrganisationByName(state, entry?.organisation!));

    const onClick = (key: string) => {
        switch (key) {
            case "Auth User":
                if (authUser) {
                    navigate(ROUTES.userDetails.build({id: authUser?.id}));
                }
                break;
            case "Affected User":
                if (user) {
                    navigate(ROUTES.userDetails.build({id: user?.id}));
                }
                break;
            case "Product":
                if (product) {
                    navigate(ROUTES.productDetails.build({id: entry?.productCode}));
                }
                break;
            case "Organisation":
                if (organisation) {
                    navigate(ROUTES.organisationDetails.build({id: organisation?.id}));
                }
                break;
        }
    }

    return (!entry) ? <div>bruh</div> : (
        <Page title={{page: "Audit Log Details", icon: ['far', 'clipboard']}}>
            <div style={{maxWidth: "800px"}}>
                <h2>Audit Log Entry Details</h2>
                <DetailTable
                        onClick={onClick}
                        clickable={[
                            product && "Product",
                            organisation && "Organisation",
                            authUser && "Auth User",
                            user && "Affected User"
                        ]}
                        details={{
                    "ID": entry.id,
                    "Date": formatTimestamp(entry.timestamp!),
                    "Action": entry.action,
                    "Description": entry.description,
                    "Status": entry.success
                        ? <span style={{color: "green"}}>Successful</span>
                        : <span style={{color: "red"}}>Failed</span>,
                    "Error Message": entry.errorMessage,
                    "Auth User": (authUser?.firstName + " " + authUser?.lastName),
                    "Affected User": (user?.firstName + " " + user?.lastName),
                    "Organisation": organisation ? organisation.name : entry.organisation,
                    "Product": product ? product : entry.productCode,
                    "Detail Record ID": entry.detailRecordId,
                }}/>

                <h3>Additional Details</h3>
                <DetailTable details={entry.detail}/>
            </div>
        </Page>
    );
}

export default UserDetailsPage;
