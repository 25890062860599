import { CSSProperties, useEffect, useState } from 'react';
import { animated, AnimatedProps, useChain, useSpring } from 'react-spring';
import TabBarContext from './TabBarContext';
import styles from './TabBar.module.scss';
import Tab from './Tab/Tab';
import useThemeAnimation from '../../hooks/useThemeAnimation';

interface TabBarProps {
    children: React.ReactElement<typeof Tab>[] | React.ReactElement<typeof Tab>;
    size?: 's' | 'm';
    align?: 'l' | 'c' | 'r';
}

function TabBar({ children, size = 's', align='l'}: TabBarProps) {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [currentChildren, setCurrentChildren] = useState<React.ReactNode|undefined>(undefined);
    const [shouldAnimate, animationConfig] = useThemeAnimation();
    const [width, setWidth] = useState<number>(0);
    const [position, setPosition] = useState<number>(0);
    const [visible, setVisible] = useState<boolean>(false);
    const [animate, setAnimate] = useState(false);

    const animatedHighlightStyle = useSpring({
        to: {
            opacity: visible ? 1 : 0,
            transform: `translateX(${position + width / 2}px) scaleX(${width})`,
        } as AnimatedProps<CSSProperties>,
        config: animationConfig,
        immediate: !shouldAnimate || !animate,
    });

    const setCurrent = (
            index: number,
            ref: React.RefObject<HTMLElement>,
            animate: boolean,
            children?: React.ReactNode
    ) => {
        setCurrentIndex(index);
        setCurrentChildren(children);
        setAnimate(animate);

        if (!!ref && ref.current) {
            setVisible(!!ref.current);
            setWidth(ref.current.offsetWidth);
            setPosition(ref.current.offsetLeft);
        }
    };

    const align_lookup = {
        l: 'left',
        r: 'right',
        c: 'center'
    };

    const style = {
        display: 'flex',
        justifyContent: align_lookup[align],
    };

    return (
        <TabBarContext.Provider value={{ size, currentIndex, setCurrent }}>
            <div style={style}>
                <div className={styles['tab-bar']}>
                    {children}
                    <animated.svg className={styles['highlight']} style={animatedHighlightStyle}>
                        <rect width="100%" height="100%" />
                    </animated.svg>
                </div>
            </div>
            {currentChildren}
        </TabBarContext.Provider>
    );
}

export default TabBar;
