import { ComponentType, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SidebarContext from '../SidebarContext';
import styles from './SidebarImage.module.scss';

interface SidebarImageProps {
    normalSrc: any;
    collapsedSrc: any;
}

function SidebarImage({ normalSrc, collapsedSrc }: SidebarImageProps) {
    const { collapsed } = useContext(SidebarContext);

    return (
        <div>
            <img src={collapsedSrc} className={styles[collapsed ? 'image-collapsed' : 'image-hidden']}/>
            <img src={normalSrc} className={styles[collapsed ? 'image-hidden' : 'image']}/>
        </div>
    );
}

export default SidebarImage;
