import styles from './PageLayout.module.scss';

interface PageLayoutProps {
    sidebar?: React.ReactElement;
    header: React.ReactElement;
    body: React.ReactElement;
    footer?: React.ReactElement;
};

function PageLayout({sidebar, header, body, footer}: PageLayoutProps) {
    return (
        <div className={styles.container}>
            { !sidebar ? null : (
                <div className={styles.sidebar}>
                    {sidebar}
                </div>
            )}
            <div className={styles.header}>
                {header}
            </div>
            <div className={styles.content}>
                {body}
            </div>
            { !footer ? null : (
                <div className={styles.footer}>
                    {footer}
                </div>
            )}
        </div>
    );
}

export default PageLayout;
