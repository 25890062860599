import { DropdownNav } from "@application-production-system/react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectNavCollapsed, setNavCollapsed } from "../../../store/sessionSlice";
// import { useAppSelector, AppActions, useAppDispatch } from "../../redux";
import { DropdownNavLinks } from './DropdownNavLinks';

export function AppDropdownNav() {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const isNavCollapsed = useAppSelector(selectNavCollapsed);

    const toggleNav = () => {
        dispatch(setNavCollapsed(!isNavCollapsed));
    }

    return isNavCollapsed ? null : (
        <DropdownNav
            collapsed={isNavCollapsed}
            onCollapse={toggleNav}
            location={location}
            navigate={navigate}
            headerHeight={64}
        >
            <DropdownNavLinks />
        </DropdownNav>
    );
}
