import { CSSProperties, ReactNode, useContext } from 'react';
import SidebarContext from '../SidebarContext';
import styles from './Section.module.scss';
import { animated, AnimatedProps } from 'react-spring';
import { GroupProps } from '../Group/Group';
import { LinkProps } from '../Link/Link';

export interface SectionProps {
    title?: string;
    collapsedTitle?: string;
    children?: React.ReactElement<GroupProps | LinkProps>[] | React.ReactElement<GroupProps | LinkProps>;
    separator?: boolean;
}

function Section({ title, collapsedTitle, children, separator }: SectionProps) {
    const { animationProgress, minWidth } = useContext(SidebarContext);

    const collapsedTitleStyle = {
        transform: animationProgress
            .to({
                range: [100, 0],
                output: [0, 50],
            })
            .to(number => `translateX(-${number}%)`),
        left: animationProgress
            .to({
                range: [100, 0],
                output: [8, minWidth - 16],
            })
            .to(number => `${number / 2}px`),
    } as AnimatedProps<CSSProperties>;

    if (collapsedTitle != title) {
        collapsedTitleStyle.opacity = animationProgress
            .to({
                range: [100, 0],
                output: [0, 100],
            })
            .to(number => `${number}%`);
    }

    const titleStyle = {} as AnimatedProps<CSSProperties>;
    if (collapsedTitle == title) {
        titleStyle.opacity = "0%";
    }
    else {
        titleStyle.opacity = animationProgress
            .to({
                range: [100, 0],
                output: [100, 0],
            })
            .to(number => `${number}%`);
    }

    return (
        <div className={styles['section']}>
            {separator && <div className={styles['separator']} />}
            <div className={styles['section-title-frame']}>
                <animated.div className={styles['section-title']} style={collapsedTitleStyle}>
                    {collapsedTitle}
                </animated.div>
                <animated.div className={styles['section-title']} style={titleStyle}>
                    {title}
                </animated.div>
            </div>
            <div className={styles['section-body']}>{children}</div>
        </div>
    );
}

export default Section;
