import { useState } from 'react';
import useAfterEffect from '../../hooks/useAfterEffect';
import CloseIcon from '../../assets/close.svg';
import ErrorIcon from '../../assets/error.svg';
import useClasses from '../../utility/useClasses';
import Button from '../Button/Button';
import styles from './Modal.module.scss';

type ModalStatus = 'danger' | 'normal';

interface ModalProps {
    title: string;
    onClose: () => void;
    visible: boolean;
    status?: ModalStatus;
    children?: React.ReactNode;
    destructiveIndex?: number;
    actions?: string[];
    actionReducer?: (action: string) => void;
}

type Timeout = ReturnType<typeof setTimeout>;

function Modal({
    status = 'normal',
    title,
    onClose,
    children,
    actions = [],
    destructiveIndex = 0,
    actionReducer,
    visible,
}: ModalProps) {
    // triggered when the user clicks outside of the modal
    const onLoseFocus = () => {
        console.log('closing modal');
        onClose();
    };

    const [currentlyVisible, setCurrentlyVisible] = useState(false);
    const [currentTimeout, setCurrentTimeout] = useState<Timeout | undefined>(undefined);

    const startTimeout = () => {
        const timeout = setTimeout(() => {
            setCurrentlyVisible(visible);
        }, 250)
        setCurrentTimeout(timeout);
    }

    useAfterEffect(() => {
        if (visible) {
            setCurrentlyVisible(true);
            return;
        }
        if (!currentTimeout) {
            startTimeout();
        }
    }, [visible])


    // triggered when the user clicks on the modal
    const stopEventPropagation = (e: React.SyntheticEvent<HTMLDivElement, Event>) => {
        e.stopPropagation();
    };

    const getButtonVariant = (index: number) => {
        if (index === destructiveIndex) {
            return status === 'danger' ? 'danger' : 'primary';
        }
        return 'ghost';
    };

    const style = {
        display: currentlyVisible ? 'block' : 'none',
    };

    const classes = useClasses(styles, {
        container: true,
        fadeout: !visible,
    });

    return (
        <div className={classes} onClick={onLoseFocus} style={style}>
            <div className={styles['modal']} onClick={stopEventPropagation}>
                <div className={styles['header']}>
                    {title && (
                        <div className={styles['title']}>
                            {status === 'danger' && (
                                <i className={styles['icon']}>
                                    <ErrorIcon />
                                </i>
                            )}

                            {title}
                        </div>
                    )}
                    <i className={styles['icon']} onClick={onClose}>
                        <CloseIcon />
                    </i>
                </div>
                <div className={styles['content']}>{children}</div>
                {actions.length > 0 && (
                    <div className={styles['actions']}>
                        {actions.map((action, index) => (
                            <Button
                                variant={getButtonVariant(index)}
                                title={action}
                                key={index}
                                onClick={() => {
                                    actionReducer && actionReducer(action);
                                }}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Modal;
