import { Icon } from '@application-production-system/react';
import { setSidebarCollapsed, selectSidebarCollapsed } from '../../../store/sessionSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';

export function CollapseSidebarButton() {
    const dispatch = useAppDispatch();

    const sidebarCollapsed = useAppSelector(selectSidebarCollapsed);

    const onClick = () => {
        dispatch(setSidebarCollapsed(!sidebarCollapsed));
    }

    return (
        <Icon
            icon={['far', 'sidebar']}
            size="xl"
            color="var(--neutral-90)"
            onClick={onClick}
            style={{cursor: "pointer"}}
        />
    );
}
