import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AdminService, PlanDetails } from '../api/doc';
import type { RootState } from '.'

interface PlanState {
    status: number;
    plans: PlanDetails[];
    lookup: {[planCode: string]: PlanDetails};
}

const initialState: PlanState = {
    status: 0,
    lookup: {},
    plans: []
}

interface UpdatePlanPayload {
    planCode?: string;
    newDetails?: PlanDetails;
}

export const refreshPlans = createAsyncThunk(
    'plan/fetch',
    async (_) => {
        let status = 200;
        const response = await AdminService.findPlans()
            .catch(error => {
                status = error.status;
                return {plans: undefined};
            });
        return {
            status: status,
            plans: response.plans,
        }
    }
)

const updatePlanInList = (plans: PlanDetails[], payload: UpdatePlanPayload) => {
    if (payload.planCode === undefined || payload.newDetails === undefined) {
        return plans;
    }
    for (let i = 0; i < plans.length; i++) {
        if (plans[i].code === payload.planCode) {
            plans[i] = payload.newDetails;
        }
    }
    return plans;
}

const updatePlanInLookup = (lookup: {[key: string]: PlanDetails}, payload: UpdatePlanPayload) => {
    if (payload.planCode === undefined || payload.newDetails === undefined) {
        return lookup;
    }
    const newLookup = Object.fromEntries(
        Object.entries(lookup)
        .filter((entry, _) => entry[0] !== payload.planCode)
    );
    newLookup[payload.newDetails.code!] = payload.newDetails;
    return newLookup;
}

const removePlanFromList = (plans: PlanDetails[], planCode: string) => {
    return plans.filter(plan => plan.code !== planCode);
}

const removePlanFromLookup = (lookup: {[key: string]: PlanDetails}, planCode: string) => {
    return Object.fromEntries(
        Object.entries(lookup)
        .filter((entry, _) => entry[0] !== planCode)
    );
}

export const planSlice = createSlice({
    name: 'plan',
    initialState,
    reducers: {
        createPlan: (state, action: PayloadAction<PlanDetails|undefined>) => {
            if (action.payload) {
                state.plans.push(action.payload);
                state.lookup = {...state.lookup, [action.payload.code!]: action.payload};
            }
        },

        updatePlan: (state, action: PayloadAction<UpdatePlanPayload>) => {
            state.plans = updatePlanInList(state.plans, action.payload);
            state.lookup = updatePlanInLookup(state.lookup, action.payload);
        },

        removePlan: (state, action: PayloadAction<string|undefined>) => {
            if (action.payload !== undefined) {
                state.plans = removePlanFromList(state.plans, action.payload);
                state.lookup = removePlanFromLookup(state.lookup, action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(refreshPlans.fulfilled, (state, action) => {
            state.plans = action.payload.plans || [];
            state.status = action.payload.status;
            state.lookup = {};
            state.plans.forEach((plan: PlanDetails, _) => {
                state.lookup[plan.code!] = plan;
            });
        });
    }
});

export const { createPlan, updatePlan, removePlan } = planSlice.actions;

export const selectPlan = (state: RootState, planCode?: string) => state.plan.lookup[planCode!];

export const selectPlanStatus = (state: RootState) => state.plan.status;

export const formatPlan = (plan: PlanDetails) => "[" + plan.code! + "] " + plan.name!

export const selectFormatPlan = (state: RootState, planCode?: string) => {
    if (!planCode) {
        return undefined;
    }
    const plan = state.plan.lookup[planCode];
    if (!plan) {
        return undefined;
    }
    return formatPlan(plan);
}

export const selectPlans = (state: RootState) => state.plan.plans;

export const selectPlanOptions = (state: RootState) => {
    const planOptions: {[key: string]: string} = {};
    state.plan.plans.forEach((plan) => {
        planOptions[plan.code!] = formatPlan(plan);
    });
    return planOptions;
}

export default planSlice.reducer;
