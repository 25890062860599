import type { CancelablePromise } from '../api/doc';

export function ServiceWrapper<T>(
        call: (..._: any) => CancelablePromise<T>,
        args: any[],
        successCallback: (response: T) => void)
{
    call(...args)
    .then((response: any) => {
        if (response.succeeded) {
            successCallback(response);
        }
        else {
            alert("Something went wrong");
            console.error(response);
        }
    })
    .catch((error: any) => {
        alert("Something went wrong");
        console.error(error);
    });
}
