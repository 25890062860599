import { CSSProperties } from 'react';
import useClasses from '../../../utility/useClasses';
import styles from './Cell.module.scss';

export interface CellProps {
    children?: React.ReactNode;
    align?: 'left' | 'center' | 'right';
    position?: 'top' | 'middle' | 'bottom';
    colspan?: number;
    style?: CSSProperties;
    divider?: boolean;
}

const flexLookup = {
    left: 'flex-start',
    top: 'flex-start',
    center: 'center',
    middle: 'center',
    right: 'flex-end',
    bottom: 'flex-end'
}

function Cell({
        children,
        align = 'left',
        position = 'top',
        colspan=1,
        style,
        divider
    }: CellProps
) {
    const cellStyle: CSSProperties = {
        alignItems: flexLookup[position],
        justifyContent: flexLookup[align],
        ...style,
    };

    const classes = useClasses(styles, {
        cell: true,
    });

    style = {
        padding: 0,
        borderLeft: divider
            ? "1px solid var(--neutral-50)"
            : undefined,
        ...style
    };

    return (
        <td colSpan={colspan} style={style}>
            <div className={classes} style={cellStyle}>
                {children}
            </div>
        </td>
    );
}

export default Cell;
