import { CSSProperties, useState } from 'react';
import TableContext from './TableContext';
import styles from './Table.module.scss';

interface TableProps<T> {
    data?: Array<T>;
    border?: boolean;
    onSort?: (data: Array<T>) => void;
    divider?: boolean;
    children?: React.ReactNode;
}

function Table<T>({
    data=[],
    children,
    onSort,
    border
}: TableProps<T>) {
    const style: CSSProperties = {
        borderStyle: border ? undefined : 'hidden',
    };

    const [sortKey, setSortKey] = useState<string|number|undefined>(undefined);

    const sortTable = (sortKey: string | number, reverse: boolean) => {
        setSortKey(sortKey);

        if (!data) {
            return;
        }

        const copy = [...data];
        
        copy.sort(
            (a: any, b: any) => {
                if (a[sortKey] < b[sortKey]) {
                    return -1;
                }
                else if (a[sortKey] > b[sortKey]) {
                    return 1;
                }
                return 0;
            }
        );

        if (reverse) {
            copy.reverse();
        }

        onSort && onSort(copy);
    }

    return (
        <TableContext.Provider value={{sortTable: sortTable, currentSortKey: sortKey}}>
            <div className={styles['container']}>
                <div className={styles['table-container']}>
                    <table className={styles['table']} style={style}>
                        <tbody>
                            {children}
                        </tbody>
                    </table>
                </div>
            </div>
        </TableContext.Provider>
    );
}

export default Table;
