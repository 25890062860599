import { useNavigate, useParams } from 'react-router-dom';
import { EmailLogEntry, StatisticsService } from '../../api/doc';
import { DetailTable } from '../../components';
import { formatTimestamp } from '../../assets/constants';
import { useAppSelector } from '../../hooks';
import { findUserByEmail } from '../../store/userSlice';
import { selectFormatProduct } from '../../store/productSlice';
import { selectOrganisationByName } from '../../store/organisationSlice';
import { IdPageParams, ROUTES } from '../../routes';
import { useEffect, useState } from 'react';
import { Page } from '../../components/Page/Page';

export default function EmailLogDetailsPage() {
    const navigate = useNavigate();

    const { id } = useParams<IdPageParams>();

    const [ entry, setEntry ] = useState<EmailLogEntry|undefined>(undefined);

    useEffect(() => {
        StatisticsService.getEmailLogEntry(id!).then((response) => {
            setEntry(response.entry);
        });
    }, []);

    const organisation = useAppSelector((state) => selectOrganisationByName(state, entry?.organisation!));
    const product = useAppSelector((state) => selectFormatProduct(state, entry?.productCode));
    const emailUser = useAppSelector((state) => findUserByEmail(state, entry?.email!));
    const fromUser = useAppSelector((state) => findUserByEmail(state, entry?.from!));
    const toUser = useAppSelector((state) => findUserByEmail(state, entry?.to!));


    const onClick = (key: string) => {
        switch (key) {
            case "Email":
                if (emailUser) {
                    navigate(ROUTES.userDetails.build({id: emailUser.id}));
                }
                break;
            case "From User":
                if (fromUser) {
                    navigate(ROUTES.userDetails.build({id: fromUser.id}));
                }
                break;
            case "To User":
                if (toUser) {
                    navigate(ROUTES.userDetails.build({id: toUser.id}));
                }
                break;
            case "Product":
                if (product) {
                    navigate(ROUTES.productDetails.build({id: entry?.productCode}));
                }
                break;
            case "Organisation":
                if (organisation) {
                    navigate(ROUTES.organisationDetails.build({id: organisation.id}));
                }
                break;
        }
    }

    return (!entry) ? <div>bruh</div> : (
        <Page title={{page: "Email Log Details", icon: ['far', 'envelope']}}>
            <div style={{maxWidth: "800px"}}>
                <h2>Email Log Entry Details</h2>
                <DetailTable
                    onClick={onClick}
                    clickable={[
                        emailUser && "Email",
                        fromUser && "From User",
                        toUser && "To User",
                        product && "Product",
                        organisation && "Organisation"
                    ]}
                    details={{
                        "ID": entry.id,
                        "Date": formatTimestamp(entry.timestamp!),
                        "Email": entry.email,
                        "Type": entry.type,
                        "Status": entry.success
                            ? <span style={{color: "green"}}>Successful</span>
                            : <span style={{color: "red"}}>Failed</span>,
                        "Error Message": entry.error,
                        "From User": entry.from,
                        "To User": entry.to,
                        "Num Attachments": entry.attachmentCount,
                        "Attachments Size": entry.attachmentBytes + " bytes",
                        "Organisation": entry.organisation,
                        "Product": product ? product : entry.productCode,
                        "Message ID": entry.messageId,
                        "Inspection ID": entry.inspectionId
                    }}
                />
            </div>
        </Page>
    );
}
