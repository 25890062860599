import { Button } from '@application-production-system/react';
import { IoCreate } from 'react-icons/io5';
import { ConsolePartner } from '../../api/console';
import { useLocation, useNavigate } from 'react-router-dom';
import { DetailTable } from '../../components';
import { ROUTES } from '../../routes';

interface LocationState {
    partner: ConsolePartner;
}

function PartnerDetailsPage() {
    const location = useLocation();
    const navigate = useNavigate();

    const { partner } = location.state as LocationState;

    return (
        <div style={{maxWidth: "800px"}}>
            <h2>Partner Details</h2>
            <DetailTable details={{
                "ID": partner.id,
                "Partner ID": partner.partnerId,
                "Partner Code": partner.partnerCode,
                "Client ID": partner.clientId,
                "Endpoint": partner.endpoint,
                "Primary": partner.primary
                        ? <span style={{color: "green"}}>Yes</span>
                        : <span style={{color: "red"}}>No</span>,
                "Secret": partner.secret
            }}/>
            <div style={{display: "flex"}}>
                <div style={{marginLeft: "auto", marginTop: "10px"}}>
                    <Button
                        title="Edit"
                        icon={['far', 'edit']}
                        iconPosition="left"
                        onClick={() => {
                            navigate(ROUTES.editPartner.build({id: partner.id}));
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default PartnerDetailsPage;
