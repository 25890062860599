import { Table, Row, Cell } from '@application-production-system/react';
import styles from './DetailTable.module.scss';

interface DetailTableProps {
    details: any;
    clickable?: (string|undefined)[];
    onClick?: (key: string) => void;
}

function DetailTable({ details, clickable, onClick }: DetailTableProps) {
    return (
        <Table border>
            <>
            {Object.keys(details).map((item: any, idx: number) => {
                return (
                    <Row key={idx} striped={idx % 2 === 0} onClick={clickable?.includes(item)
                                ? () => onClick && onClick(item) : undefined} >
                        <Cell style={{width: 150}} /*className={styles["title-cell"]}*/>
                            <b>{item}</b>
                        </Cell>
                        <Cell>{details[item]}</Cell>
                    </Row>
                );
            })}
            </>
        </Table>
    );
}

export default DetailTable;
