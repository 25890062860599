import { createForm, CardContainer, isEmail } from '@application-production-system/react';
import { useNavigate } from 'react-router-dom';
import { AdminService } from '../../api/doc';
import { useAppDispatch } from '../../hooks';
import { createProduct } from '../../store/productSlice';
import { handleError } from '../../assets/constants';
import { CancelSubmitRow } from '../../components';
import { ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';
import { useState } from 'react';

interface CreateProductForm {
    name: string;
    code: string;
    color: string;
    active: boolean;
    emailFrom: string;
    emailEnabled: boolean;
    cloudStorageEnabled: boolean;
    dashboardURL: string;
    linkDomain: string;
    passwordResetURL: string;
    passwordResetEmailSubject: string;
    passwordResetEmailBody: string;
}

const Form = createForm<CreateProductForm>();

function CreateProductPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [formData, setFormData] = useState<CreateProductForm>({} as CreateProductForm);

    const onSubmit = () => {
        AdminService.createProduct(formData)
            .then(response => {
                dispatch(createProduct(response.product));
                navigate(ROUTES.productDetails.build({id: response?.product?.id}));
            })
            .catch(handleError);
    }

    return (
        <Page title={{page: "Products", subPage: "Create Product", icon: ['far', 'shopping-bag']}}>
            <div style={{maxWidth: "600px"}}>
                <h2>Create Product</h2>
                <CardContainer title="Create Product">
                    <Form.Form onSubmit={onSubmit} data={formData} setData={setFormData}>
                        <Form.Entry required for="name" label="Name" />
                        <Form.Entry required for="code" label="Code" />
                        <Form.Entry for="color" label="Colour" />
                        <Form.Entry for="dashboardURL" label="Dashboard URL" />
                        <Form.Entry required for="emailFrom" label="Email From" validate={isEmail} />
                        <Form.Entry for="linkDomain" label="Link Domain" />
                        <Form.Entry for="passwordResetURL" label="Password Reset URL" />
                        <Form.Entry for="passwordResetEmailSubject" label="Password Reset Email Subject" />
                        <Form.TextArea for="passwordResetEmailBody" label="Password Reset Email Body" />
                        <Form.Checkbox for="emailEnabled" label="Email Enabled" />
                        <Form.Checkbox for="cloudStorageEnabled" label="Cloud Storage Enabled" />
                        <Form.Checkbox for="active" label="Active" />
                        <CancelSubmitRow />
                    </Form.Form>
                </CardContainer>
            </div>
        </Page>
    );
}

export default CreateProductPage;
