import { NavbarLink } from '@application-production-system/react';
import { ROUTES } from '../../../routes';

export function DropdownNavLinks() {
    return (
        <>
            <NavbarLink
                title='Home'
                to={ROUTES.dashboard.path}
                icon={['fas', 'house']}
                separator
            />
            <NavbarLink
                title='Users'
                to={ROUTES.users.path}
                icon={['fas', 'person']}
            />
            <NavbarLink
                title='Organisations'
                to={ROUTES.organisations.path}
                icon={['fas', 'flag']}
            />
            <NavbarLink
                title='Plans'
                to={ROUTES.plans.path}
                icon={['fas', 'clock']}
            />
            <NavbarLink
                title='Products'
                to={ROUTES.products.path}
                icon={['fas', 'backpack']}
            />
            <NavbarLink
                title='Templates'
                to={ROUTES.templates.path}
                icon={['fas', 'page']}
                separator
            />

            <NavbarLink
                title='Integrations'
                to={ROUTES.integrations.path}
                icon={['fas', 'shapes']}
            />
            <NavbarLink
                title='Partners'
                to={ROUTES.partners.path}
                icon={['fas', 'beer']}
                separator
            />

            <NavbarLink
                title='Audit Logs'
                to={ROUTES.auditLog.path}
                icon={['fas', 'clipboard']}
            />
            <NavbarLink
                title='Email Logs'
                to={ROUTES.emailLog.path}
                icon={['fas', 'envelope']}
            />
            <NavbarLink
                title='Render Logs'
                to={ROUTES.renderLog.path}
                icon={['fas', 'print']}
            />
        </>
    );
}
