import { useAppSelector } from '../../hooks';
import { selectUser, selectProductCode } from '../../store/sessionSlice'
import { selectProduct } from '../../store/productSlice'
import { useNavigate } from 'react-router-dom';
import { selectOrganisation } from '../../store/organisationSlice';
import { DetailTable } from '../../components';
import { ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';

function HomePage() {
    const productCode = useAppSelector(selectProductCode);
    const user = useAppSelector(selectUser);
    const product = useAppSelector((state) => selectProduct(state, productCode));
    console.log("user...", user);
    const organisation = useAppSelector((state) => selectOrganisation(state, user?.organisation));
    const navigate = useNavigate();

    const onClick = (key: string) => {
        switch (key) {
            case "Organisation":
                if (organisation) {
                    navigate(ROUTES.organisationDetails.build({id: organisation.id}));
                }
                break;
            case "Product":
                if (product) {
                    navigate(ROUTES.productDetails.build({id: product.code}));
                }
                break;
            case "User ID":
            case "Name":
            case "Login":
            case "Email":
                if (user) {
                    navigate(ROUTES.userDetails.build({id: user.id}));
                }
                break;
        }
    }

    return (!user) ? null : (
        <Page title={{page: "Dashboard", icon: ['far', 'house']}}>
            <div>
                <h2>Session Details</h2>
                <DetailTable
                    onClick={onClick}
                    clickable={[
                        organisation && "Organisation",
                        product && "Product",
                        user && "User ID",
                        user && "Name",
                        user && "Login",
                        user && "Email",
                    ]}
                    details={{
                        "User ID": user?.id,
                        "Name": user?.firstName + " " + user?.lastName,
                        "Login": user?.userId,
                        "Email": user?.email,
                        "Product": product ? product.name : user?.productCode,
                        "Organisation": user?.organisation,
                    }}
                />
            </div>
        </Page>
    );
}

export default HomePage;
