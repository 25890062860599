import { Button } from '@application-production-system/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DetailTable, Loader } from '../../components';
import { Page } from '../../components/Page/Page';
import { useAppSelector } from '../../hooks';
import { IdPageParams, ROUTES } from '../../routes';
import { selectPlan } from '../../store/planSlice';
import { selectFormatProduct } from '../../store/productSlice';

function PlanDetailsPage() {
    const location = useLocation();
    const navigate = useNavigate();

    const { id } = useParams<IdPageParams>();

    const plan = useAppSelector((state) => selectPlan(state, id));
    const product = useAppSelector((state) => selectFormatProduct(state, plan?.productCode));

    const onClick = (key: string) => {
        switch (key) {
            case "Product":
                product && navigate(ROUTES.productDetails.build({id: plan.productCode}));
                break;
        }
    }

    return !plan ? <Loader/> : (
        <Page title={{page: "Plan Details", icon: ['far', 'clock']}}>
            <div style={{maxWidth: "800px"}}>
                <h2>Plan Details</h2>
                <DetailTable
                    onClick={onClick}
                    clickable={[product && "Product"]}
                    details={{
                        "ID": plan.id,
                        "Name": plan.name,
                        "Code": plan.code,
                        "Product": product ? product : plan.productCode,
                        "Email Status": plan.emailEnabled
                        ? <span style={{color: "green"}}>Enabled</span>
                        : <span style={{color: "red"}}>Disabled</span>,
                        "Cloud Storage": plan.cloudStorageEnabled
                        ? <span style={{color: "green"}}>Enabled</span>
                        : <span style={{color: "red"}}>Disabled</span>,
                        "Status": plan.active
                                ? <span style={{color: "green"}}>Active</span>
                                : <span style={{color: "red"}}>Inactive</span>
                    }}
                />
                <div style={{display: "flex"}}>
                    <div style={{marginLeft: "auto", marginTop: "10px"}}>
                        <Button
                            title="Edit"
                            icon={['far', 'edit']}
                            iconPosition="left"
                            onClick={() => {
                                navigate(ROUTES.editPlan.build({id: id}));
                            }}
                        />
                    </div>
                </div>
            </div>
        </Page>
    );
}

export default PlanDetailsPage;
