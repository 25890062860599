import { Table, Row, Cell, HeaderRow, HeaderCell, Button } from '@application-production-system/react';
import { IoAddCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { TemplateDetails } from '../../api/doc';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { useEffect } from 'react';
import { selectTemplates, selectTemplateStatus, refreshTemplates } from '../../store/templateSlice';
import { getErrorMessage } from '../../assets/constants';
import { ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';

function TemplatesPage() {

    const templates = useAppSelector(selectTemplates);
    const status = useAppSelector(selectTemplateStatus);

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(refreshTemplates());
    }, [])

    const createTemplatesTable = () => {
        return status !== 200 ? getErrorMessage(status) : (!templates.length ? "No templates to display." : (
            <Table
                data={templates}
                divider={false}
            >
                <>
                <HeaderRow>
                    <HeaderCell><b>Name</b></HeaderCell>
                    <HeaderCell><b>Description</b></HeaderCell>
                </HeaderRow>
                {templates.map((template, idx) => {
                    return <Row
                        key={idx}
                        onClick={() => {
                            navigate(ROUTES.templateDetails.build({id: template.name}));
                        }}
                        divider={true}
                        striped={idx % 2 === 1}
                    >
                        <Cell>{ template.name }</Cell>
                        <Cell>{ template.templateDescription }</Cell>
                    </Row>
                })}
                </>
            </Table>
        ))
    }

    return (
        <Page title={{page: "Templates", icon: ['far', 'page']}}>
            <div style={{overflowX: "hidden"}}>
                <div style={{display: "flex"}}>
                    <h2>Templates</h2>
                    <div style={{marginLeft: "auto", marginTop: "auto", marginBottom: "10px"}}>
                        <Button
                            disabled={status !== 200}
                            title="New Template"
                            icon={['far', 'plus-circle']}
                            iconPosition="left"
                            onClick={() => {
                                navigate(ROUTES.createTemplate.build());
                            }}
                        />
                    </div>
                </div>
                <div style={{overflowX: "scroll"}}>
                    <div style={{minWidth: "500px"}}>
                        {createTemplatesTable()}
                    </div>
                </div>
            </div>
        </Page>
    );
}

export default TemplatesPage;
