import { CardContainer, createForm, isEmail } from '@application-production-system/react';
import { useNavigate } from 'react-router-dom';
import { UsersService } from '../../api/doc';
import { createUserV1 } from '../../api/override/doc';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { createUser } from '../../store/userSlice';
import { createOrganisation } from '../../store/organisationSlice';
import { selectPlanOptions } from '../../store/planSlice';
import { handleError } from '../../assets/constants';
import { CancelSubmitRow } from '../../components';
import { ROUTES } from '../../routes';
import { Page } from '../../components/Page/Page';
import { useState } from 'react';

interface CreateOrgAndUserForm {
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    orgName: string;
    password: string;
    passwordChk: string;
    planCode: string;
    region: string;
    state: string;
}

const Form = createForm<CreateOrgAndUserForm>();

function CreateOrganisationPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const planOptions = useAppSelector(selectPlanOptions);

    const [formData, setFormData] = useState<CreateOrgAndUserForm>({} as CreateOrgAndUserForm);

    const onSubmit = () => {
        console.log("create user formData:", formData);
        createUserV1(formData)
        // UsersService.createUserV1(formData)
        .then(response => {
            if (response.status == 200) {
                UsersService.getUser(formData.email).then(response => {
                    dispatch(createUser(response.user));
                    dispatch(createOrganisation({
                        id: response.user?.organisation,
                        name: formData.orgName,
                        phone: formData.phone,
                        region: formData.region,
                        plan: formData.planCode,
                        email: formData.email,
                        state: formData.state,
                        productOwner: false
                    }));
                    navigate(ROUTES.userDetails.build({id: response.user?.id}));
                });
            }
        })
        .catch(handleError);
    }

    return (
        <Page title={{page: "Organisations", subPage: "Create User and Organisation", icon: ["far", "flag"]}}>
            <div style={{maxWidth: "600px"}}>
                <CardContainer title="Create User and Organisation">
                    <Form.Form onSubmit={onSubmit} data={formData} setData={setFormData}>
                        <Form.Entry  required for="firstName"    label="First Name" />
                        <Form.Entry  required for="lastName"     label="Last Name" />
                        <Form.Entry  required for="email"        label="Email" validate={isEmail} />
                        <Form.Entry           for="phone"        label="Phone" />
                        <Form.Entry  required for="password"     label="Password" />
                        <Form.Entry  required for="passwordChk"  label="Confirm Password" />
                        <Form.Select required for="planCode"     label="Plan" options={planOptions} />
                        <Form.Entry  required for="orgName"      label="Organisation Name" />
                        <Form.Entry  required for="region"       label="Region" />
                        <Form.Entry           for="state"        label="State" />
                        <CancelSubmitRow />
                    </Form.Form>
                </CardContainer>
            </div>
        </Page>
    );
}

export default CreateOrganisationPage;
