import styles from './Accordion.module.scss';
import AccordionContext from './AccordionContext';

interface AccordionProps {
    children: React.ReactNode;
}

function Accordion({ children }: AccordionProps) {
    return (
        <AccordionContext.Provider value={{}}>
            <div className={styles['accordion']}>{children}</div>
        </AccordionContext.Provider>
    );
}

export default Accordion;
