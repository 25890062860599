import HomePage from './pages/HomePage/HomePage';
import LoginPage from './pages/LoginPage/LoginPage';

import CreateUserPage from './pages/User/CreateUserPage';
import EditUserPage from './pages/User/EditUserPage';
import UserDetailsPage from './pages/User/UserDetailsPage';
import UserStatsPage from './pages/User/UserStatsPage';
import UsersPage from './pages/User/UsersPage';

import CreateOrganisationPage from './pages/Organisation/CreateOrganisationPage';
import EditOrganisationPage from './pages/Organisation/EditOrganisationPage';
import CreateOrgAndUserPage from './pages/Organisation/CreateOrgAndUserPage';
import OrganisationDetailsPage from './pages/Organisation/OrganisationDetailsPage';
import OrganisationsPage from './pages/Organisation/OrganisationsPage';

import CreatePlanPage from './pages/Plan/CreatePlanPage';
import EditPlanPage from './pages/Plan/EditPlanPage';
import PlanDetailsPage from './pages/Plan/PlanDetailsPage';
import PlansPage from './pages/Plan/PlansPage';

import CreateProductPage from './pages/Product/CreateProductPage';
import EditProductPage from './pages/Product/EditProductPage';
import ProductDetailsPage from './pages/Product/ProductDetailsPage';
import ProductsPage from './pages/Product/ProductsPage';

import CreateIntegrationPage from './pages/Integration/CreateIntegrationPage';
import EditIntegrationPage from './pages/Integration/EditIntegrationPage';
import IntegrationDetailsPage from './pages/Integration/IntegrationDetailsPage';
import IntegrationsPage from './pages/Integration/IntegrationsPage';

import CreatePartnerPage from './pages/Partner/CreatePartnerPage';
import EditPartnerPage from './pages/Partner/EditPartnerPage';
import PartnerDetailsPage from './pages/Partner/PartnerDetailsPage';
import PartnersPage from './pages/Partner/PartnersPage';

import CreateTemplatePage from './pages/Template/CreateTemplatePage';
import TemplateDetailsPage from './pages/Template/TemplateDetailsPage';
import TemplatesPage from './pages/Template/TemplatesPage';

import AuditLogPage from './pages/AuditLog/AuditLogPage';
import AuditLogDetailsPage from './pages/AuditLog/AuditLogDetailsPage';
import EmailLogPage from './pages/EmailLog/EmailLogPage';
import EmailLogDetailsPage from './pages/EmailLog/EmailLogDetailsPage';
import RenderLogPage from './pages/RenderLog/RenderLogPage';
import RenderLogDetailsPage from './pages/RenderLog/RenderLogDetailsPage';

import { AppRoute } from '@application-production-system/react';
export type { AppRoute };

export type IdPageParams = {
    id?: string;
}

export const ROUTES = {
    dashboard: new AppRoute("/dashboard", <HomePage/>),

    login: new AppRoute("/login", <LoginPage/>),

    users: new AppRoute("/users", <UsersPage/>),
    createUser: new AppRoute("/users/create", <CreateUserPage/>),
    userDetails: new AppRoute<IdPageParams>("/users/:id", <UserDetailsPage/>),
    userStats: new AppRoute<IdPageParams>("/users/:id/stats", <UserStatsPage/>),
    editUser: new AppRoute<IdPageParams>("/users/:id/edit", <EditUserPage/>),

    organisations: new AppRoute("/organisations", <OrganisationsPage/>),
    createOrganisation: new AppRoute("/organisations/create", <CreateOrganisationPage/>),
    createOrgAndUserPage: new AppRoute("/organisations/create-with-user", <CreateOrgAndUserPage/>),
    organisationDetails: new AppRoute<IdPageParams>("/organisations/:id", <OrganisationDetailsPage/>),
    editOrganisation: new AppRoute<IdPageParams>("/organisations/:id/edit", <EditOrganisationPage/>),

    plans: new AppRoute("/plans", <PlansPage/>),
    createPlan: new AppRoute("/plans/create", <CreatePlanPage/>),
    planDetails: new AppRoute<IdPageParams>("/plans/:id", <PlanDetailsPage/>),
    editPlan: new AppRoute<IdPageParams>("/plans/:id/edit", <EditPlanPage/>),

    products: new AppRoute("/products", <ProductsPage/>),
    createProduct: new AppRoute("/products/create", <CreateProductPage/>),
    productDetails: new AppRoute<IdPageParams>("/products/:id", <ProductDetailsPage/>),
    editProduct: new AppRoute<IdPageParams>("/products/:id/edit", <EditProductPage/>),

    integrations: new AppRoute("/integrations", <IntegrationsPage/>),
    createIntegration: new AppRoute("/integrations/create", <CreateIntegrationPage/>),
    integrationDetails: new AppRoute<IdPageParams>("/integrations/:id", <IntegrationDetailsPage/>),
    editIntegration: new AppRoute<IdPageParams>("/integrations/:id/edit", <EditIntegrationPage/>),

    partners: new AppRoute("/partners", <PartnersPage/>),
    createPartner: new AppRoute("/partners/create", <CreatePartnerPage/>),
    partnerDetails: new AppRoute<IdPageParams>("/partners/:id", <PartnerDetailsPage/>),
    editPartner: new AppRoute<IdPageParams>("/partners/:id/edit", <EditPartnerPage/>),

    templates: new AppRoute("/templates", <TemplatesPage/>),
    createTemplate: new AppRoute("/templates/create", <CreateTemplatePage/>),
    templateDetails: new AppRoute<IdPageParams>("/templates/:id", <TemplateDetailsPage/>),

    auditLog: new AppRoute("/audit-log", <AuditLogPage/>),
    auditLogDetails: new AppRoute<IdPageParams>("/audit-log/:id", <AuditLogDetailsPage/>),

    emailLog: new AppRoute("/email-log", <EmailLogPage/>),
    emailLogDetails: new AppRoute<IdPageParams>("/email-log/:id", <EmailLogDetailsPage/>),

    renderLog: new AppRoute("/render-log", <RenderLogPage/>),
    renderLogDetails: new AppRoute<IdPageParams>("/render-log/:id", <RenderLogDetailsPage/>),
};
