export default class AppRoute<T> {
    path: string;
    element: any;
    params: string[] = [];

    constructor(path: string, element: any) {
        this.path = path;
        this.element = element;
        this.parseUrl();
    }

    private parseUrl() {
        if (this.path == "/") {
            return;
        }

        if (!this.path.startsWith("/")) {
            throw Error("Path must start with a slash");
        }

        const pathComponents = this.path.split("/").slice(1);

        for (const component of pathComponents) {
            if (component.startsWith(":")) {
                const param = component.substring(1);
                if (!param.match(/^[a-zA-Z][a-zA-Z0-9_]*$/)) {
                    throw Error("Invalid path param: '" + param + "'");
                }
                this.params.push(param);
            }
            else if (!component.match(/^[a-zA-Z0-9_-]+$/)) {
                throw Error("Invalid url subdirectory: '" + component + "'");
            }
        }
    }

    // ...args: undefined extends T ? [] : [T]
    // this allows us to have an optional template argument
    // see: https://github.com/microsoft/TypeScript/issues/12400#issuecomment-980491734
    build(...args: undefined extends T ? [] : [T]) {
        let params: any;
        if (args) {
            params = args[0];
        }
        let url = this.path;
        for (const param of this.params) {
            if (!params || !this.params.includes(param)) {
                throw Error(
                    "The AppRoute '" + this.path + "' requires the '"
                    + param + "' path param, but none was provided"
                );
            }
            url = url.replaceAll(":" + param, params[param]);
        }
        return url;
    };
}
