import { createContext } from 'react';
import { Validator } from '.';

export interface ContextTypes<T extends object, K extends keyof T> {
    register: (key: keyof T, required: boolean, validators: Validator<any>[]) => void;
    getValue: (key: keyof T) => any;
    setValue: (key: keyof T, value: any) => void;
    getError: (key: keyof T) => string;
    onBlur: (key: keyof T) => void;
    validateSelf: (key: keyof T) => void;
}

const DefaultContextData: ContextTypes<any, any> = {
    register: (key, required, validators) => {},
    getValue: (key) => "",
    setValue: (key, value) => {},
    getError: (key) => "",
    onBlur: (key) => {},
    validateSelf: (key) => {},
}

export const SchemaFormContext = createContext(DefaultContextData);

export default SchemaFormContext;
