import { CSSProperties } from 'react';
import { InputProps } from '..';
import styles from './Checkbox.module.scss';
import CheckedIcon from '../../../assets/checked.svg';

export interface CheckboxProps extends InputProps<boolean> {
    style?: CSSProperties;
}

function Checkbox({
        value=false,
        label,
        style,
        error,
        onChange=() => {},
        ...props
    }: CheckboxProps
) {

    return (
        <div className={styles["col-stack"]} style={style}>
            <div className={styles["checkbox-container"]}>
                <label className={styles["checkbox-label"]}>
                    <input
                        {...props}
                        style={{display: "none"}}
                        type="checkbox"
                        checked={value || false}
                        onChange={e => onChange(e.target.checked)}
                    />
                    <div className={styles["checkbox"]}>
                        <CheckedIcon />
                    </div>
                    <span>
                        {label}
                    </span>
                </label>
            </div>
            <label className={styles["error-message"]}>
                {error}
            </label>
        </div>
    );
}

export default Checkbox;
