import { Header } from "./app/components";
import { Link, Section, Sidebar, Header as SidebarHeader, PageLayout } from "@application-production-system/react";
import HeaderContent from "./app/components/HeaderContent/HeaderContent";
import HeaderIcon from "./app/components/HeaderIcon/HeaderIcon";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import styles from "./App.module.scss";
import { useRef, useEffect, useState } from "react";

import LoginPage from "./app/pages/LoginPage/LoginPage";

import { useAppSelector, useAppDispatch } from "./app/hooks";

import { selectLoggedIn, selectToken, login, logout, setUser, selectSidebarCollapsed } from "./app/store/sessionSlice";
import { refreshUsers } from "./app/store/userSlice";
import { refreshOrganisations } from "./app/store/organisationSlice";
import { refreshPlans } from "./app/store/planSlice";
import { refreshProducts } from "./app/store/productSlice";
import { refreshTemplates } from "./app/store/templateSlice";
import { OrganisationDetails, UserDetails, UsersService } from "./app/api/doc";

import { OpenAPI as docOpenApi } from "./app/api/doc/core/OpenAPI";
import { OpenAPI as consoleOpenApi } from "./app/api/console/core/OpenAPI";
import { ROUTES } from "./app/routes";

const url = new URL(window.location.href)
const host = url.hostname;

let prefix = "";

const hostMatch = host.match(/^(.*)-app\.(.*)/)
if (hostMatch) {
    prefix = "https://"+hostMatch[1]+"-api."+hostMatch[2];
    if (!!url.port) {
        prefix+=url.port;
    }
} else if (host === "localhost") {
    prefix = "http://localhost";
}

// WglecZ46t075VPor
// prefix = "https://staging-admin.propertyinspectionmanager.com"

docOpenApi.BASE     = prefix;
consoleOpenApi.BASE = prefix;

const checkCookies = () => {
    let token: string | undefined;
    let productCode: string | undefined;
    const cookies = document.cookie;
    cookies.split(";").forEach((cookie, _) => {
        if (cookie) {
            let [key, value] = cookie.split("=");
            value = value.trim();
            key = key.trim();
            if (!value) {
                return;
            }
            switch (key) {
                case "token":
                    token = value;
                    break;
                case "productCode":
                    productCode = value;
                    break;
            }
        }
    });
    return { token: token, productCode: productCode };
}

let timer: NodeJS.Timer | undefined;

function App() {
    const navigate = useNavigate();
    const location = useLocation();

    const loggedIn = useAppSelector(selectLoggedIn);
    const token = useAppSelector(selectToken);
    const dispatch = useAppDispatch();

    const { REACT_APP_VERSION, REACT_APP_NAME } = process.env;

    useEffect(() => {
        const cookies = checkCookies();
        if (cookies.token) {
            dispatch(login({
                token: cookies.token,
                productCode: cookies.productCode,
            }));
        }
    }, []);

    useEffect(() => {
        // if (!loggedIn) {
        //     if (timer) {
        //         clearInterval(timer);
        //         setTimer(undefined);
        //     }
        //     return;
        // }

        UsersService.getCurrentUserDetailsV1()
        .then(response => {

            const user: UserDetails = {
                ...response.userDetails,
                id:           response.userDetails?.id,
                organisation: response.userDetails?.organisationId,
                accessKey:    response.userDetails?.accessKey,
                email:        response.userDetails?.email,
                enabled:      !response.userDetails?.isDisabled,
                firstName:    response.userDetails?.firstName,
                lastName:     response.userDetails?.lastName,
                productCode:  response.userDetails?.productCode,
                roles:        response.userDetails?.roles,
                userId:       response.userDetails?.userId,
            }

            dispatch(setUser(user));
            dispatch(refreshUsers());
            dispatch(refreshOrganisations());
            dispatch(refreshPlans());
            dispatch(refreshProducts());
            dispatch(refreshTemplates());
            // if (timer == undefined) {
            //     timer = setInterval(() => {
            //         console.log("timer tick");
            //         UsersService.getCurrentUserDetailsV1()
            //         .then(response => {
            //             dispatch(setUser(response.userDetails!))
            //         })
            //         .catch(reason => {
            //             clearInterval(timer);
            //             timer = undefined;
            //             dispatch(logout());
            //         });
            //     }, 5 * 1000);
            // }
        })
        .catch(reason => {
            dispatch(logout());
        });
    }, [loggedIn]);


    const [headerHeight, setHeaderHeight] = useState<number>();
    const headerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (headerRef.current) {
            setHeaderHeight(headerRef.current?.clientHeight);
        }
    }, [headerRef]);

    const sidebarCollapsed = useAppSelector(selectSidebarCollapsed);
    console.log(sidebarCollapsed);

    return token ? (
        <PageLayout
            header={
                <Header/>
            }
            sidebar={
                <div className={styles.desktop_only}>
                    <Sidebar collapsed={sidebarCollapsed} location={location} navigator={navigate} headerHeight={105} header={
                        <SidebarHeader Logo={HeaderIcon}>
                            <HeaderContent
                                name="Systemic"
                                version={REACT_APP_VERSION}
                            />
                        </SidebarHeader>
                    }>

                        <Section title="Home" collapsedTitle="Home">
                            <Link
                                title="Dashboard"
                                to={ROUTES.dashboard.path}
                                activeIcon={["fas", "caret-right"]}
                                icon={["fas", "house"]}
                            />
                        </Section>

                        <Section title="Doc" collapsedTitle="Doc">
                            <Link
                                title="Users"
                                to={ROUTES.users.path}
                                activeIcon={["fas", "caret-right"]}
                                icon={["fas", "person"]}
                            />
                            <Link
                                title="Organisations"
                                to={ROUTES.organisations.path}
                                activeIcon={["fas", "caret-right"]}
                                icon={["fas", "flag"]}
                            />
                            <Link
                                title="Plans"
                                to={ROUTES.plans.path}
                                activeIcon={["fas", "caret-right"]}
                                icon={["fas", "clock"]}
                            />
                            <Link
                                title="Products"
                                to={ROUTES.products.path}
                                activeIcon={["fas", "caret-right"]}
                                icon={["fas", "shopping-bag"]}
                            />
                            <Link
                                title="Templates"
                                to={ROUTES.templates.path}
                                activeIcon={["fas", "caret-right"]}
                                icon={["fas", "page"]}
                            />
                        </Section>

                        <Section title="Console" collapsedTitle="Console">
                            <Link
                                title="Integrations"
                                to={ROUTES.integrations.path}
                                activeIcon={["fas", "caret-right"]}
                                icon={["fas", "shapes"]}
                            />
                            <Link
                                title="Partners"
                                to={ROUTES.partners.path}
                                activeIcon={["fas", "caret-right"]}
                                icon={["fas", "beer"]}
                            />
                        </Section>

                        <Section title="Logs" collapsedTitle="Logs">
                            <Link
                                title="Audit Logs"
                                to={ROUTES.auditLog.path}
                                activeIcon={["fas", "caret-right"]}
                                icon={["fas", "clipboard"]}
                            />
                            <Link
                                title="Email Logs"
                                to={ROUTES.emailLog.path}
                                activeIcon={["fas", "caret-right"]}
                                icon={["fas", "envelope"]}
                            />
                            <Link
                                title="Render Logs"
                                to={ROUTES.renderLog.path}
                                activeIcon={["fas", "caret-right"]}
                                icon={["fas", "print"]}
                            />
                        </Section>

                    </Sidebar>
                </div>
            }
            body={
                <Routes>
                    <Route
                        path="/"
                        element={<Navigate to={ROUTES.dashboard.build()} />}
                    />
                    {Object.values(ROUTES).map((route, i) => {
                        return <Route path={route.path} element={route.element} key={i} />
                    })}
                </Routes>
            }
        />


    ) : (
        <div>
            <Header/>
            <LoginPage/>
        </div>
    );

}

export default App;
