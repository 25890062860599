import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AdminService, OrganisationDetails } from '../api/doc';
import type { RootState } from '.'

interface OrganisationState {
    organisations: OrganisationDetails[];
    status: number;
    lookup: {[organisationId: string]: OrganisationDetails};
}

const initialState: OrganisationState = {
    lookup: {},
    status: 0,
    organisations: []
}

interface UpdateOrganisationPayload {
    organisationId?: string;
    newDetails?: OrganisationDetails;
}

export const refreshOrganisations = createAsyncThunk(
    'organisation/fetch',
    async (_) => {
        let status = 200;
        const response = await AdminService.findOrganisations()
            .catch(error => {
                status = error.status;
                return {organisations: undefined};
            });
        return {
            status: status,
            organisations: response.organisations,
        }
    }
)

const updateOrganisationInList = (organisations: OrganisationDetails[], payload: UpdateOrganisationPayload) => {
    if (payload.organisationId == undefined || payload.newDetails == undefined) {
        return organisations;
    }
    for (let i = 0; i < organisations.length; i++) {
        if (organisations[i].id === payload.organisationId) {
            organisations[i] = payload.newDetails;
        }
    }
    return organisations;
}

const updateOrganisationInLookup = (lookup: {[key: string]: OrganisationDetails}, payload: UpdateOrganisationPayload) => {
    if (payload.organisationId == undefined || payload.newDetails == undefined) {
        return lookup;
    }
    const newLookup = Object.fromEntries(
        Object.entries(lookup)
        .filter((entry, _) => entry[0] !== payload.organisationId)
    );
    newLookup[payload.newDetails.id!] = payload.newDetails;
    return newLookup;
}

const removeOrganisationFromList = (organisations: OrganisationDetails[], organisationId: string) => {
    return organisations.filter(organisation => organisation.id !== organisationId);
}

const removeOrganisationFromLookup = (lookup: {[key: string]: OrganisationDetails}, organisationId: string) => {
    return Object.fromEntries(
        Object.entries(lookup)
        .filter((entry, _) => entry[0] !== organisationId)
    );
}

export const organisationSlice = createSlice({
    name: 'organisation',
    initialState,
    reducers: {
        createOrganisation: (state, action: PayloadAction<OrganisationDetails|undefined>) => {
            if (action.payload) {
                state.organisations.push(action.payload);
                state.lookup = {...state.lookup, [action.payload.id!]: action.payload};
            }
        },

        updateOrganisation: (state, action: PayloadAction<UpdateOrganisationPayload>) => {
            state.organisations = updateOrganisationInList(state.organisations, action.payload);
            state.lookup = updateOrganisationInLookup(state.lookup, action.payload);
        },

        removeOrganisation: (state, action: PayloadAction<string|undefined>) => {
            if (action.payload != undefined) {
                state.organisations = removeOrganisationFromList(state.organisations, action.payload);
                state.lookup = removeOrganisationFromLookup(state.lookup, action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(refreshOrganisations.fulfilled, (state, action) => {
            state.organisations = action.payload.organisations || [];
            state.status = action.payload.status;
            state.lookup = {};
            state.organisations.forEach((organisation: OrganisationDetails, _) => {
                state.lookup[organisation.id!] = organisation;
            });
        });
    }
});

export const { createOrganisation, updateOrganisation, removeOrganisation } = organisationSlice.actions;

export const formatOrganisation = (organisation: OrganisationDetails) => {
    return organisation.name!
}

export const selectFormatOrganisation = (state: RootState, organisationId?: string) => {
    if (!organisationId) {
        return undefined;
    }
    const organisation = state.organisation.lookup[organisationId];
    if (!organisation) {
        return undefined;
    }
    return formatOrganisation(organisation);
}

export const selectOrganisation = (state: RootState, organisationId?: string) => state.organisation.lookup[organisationId!];

export const selectOrganisationStatus = (state: RootState) => state.organisation.status;

export const selectOrganisations = (state: RootState) => state.organisation.organisations;

export const selectOrganisationLookup = (state: RootState) => state.organisation.lookup;

export const selectOrganisationByName = (state: RootState, organisationName: string|undefined) => {
    if (!organisationName) {
        return;
    }
    for (let i = 0; i < state.organisation.organisations.length; i++) {
        if (state.organisation.organisations[i].name === organisationName) {
            return state.organisation.organisations[i];
        }
    }
}

export const selectOrganisationOptions = (state: RootState) => {
    const organisationOptions: {[key: string]: string} = {};
    state.organisation.organisations.forEach((organisation, _) => {
        organisationOptions[organisation.id!] = formatOrganisation(organisation);
    });
    return organisationOptions;
};


export default organisationSlice.reducer;
