import { useContext, useEffect } from "react";
import { KeysMatching, useValidator, Validator } from "..";
import TextArea, { TextAreaProps } from "../../Form/TextArea/TextArea";
import SchemaFormContext from '../SchemaFormContext';

export interface SchemaTextAreaProps<T extends object> extends Omit<TextAreaProps, "value" | "error"> {
    for: KeysMatching<T, string | undefined>;
    validate?: Validator<string> | Validator<string>[];
};

export function SchemaTextArea<T extends object>({"for": key, required, validate, ...props}: SchemaTextAreaProps<T>)
{
    const context = useContext(SchemaFormContext);
    const value = context.getValue(key);
    const error = context.getError(key)

    const validator = useValidator(validate || [], []);

    useEffect(() => {
        context.register(key, !!required, validator);
    }, [key, required, validator]);

    useEffect(() => {
        if (error) {
            context.validateSelf(key);
        }
    }, [value]);

    const onBlur = () => {
        context.onBlur(key);
        props.onBlur && props.onBlur();
    }

    const onChange = (value: string) => {
        context.setValue(key, value);
        props.onChange && props.onChange(value);
    }

    return (
        <TextArea {...props}
            error={error}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
        />
    );
}
