import { createContext } from 'react';

import { Validator } from '.';

export interface ContextTypes<T> {
    getValue: (key: keyof T) => any;
    setValue: (key: keyof T, value: any) => void;
    getError: (key: keyof T) => string;
    register: (key: keyof T, value: any, required: boolean, validators?: Validator<any> | Validator<any>[]) => void;
    loading:  () => boolean;
    exists:   boolean;
}

const DefaultContextData: ContextTypes<any> = {
    getValue: (key) => "",
    setValue: (key, value) => {},
    getError: (key) => "",
    register: (key, value, required, validators) => {},
    loading:  () => false,
    exists:   false,
}

export function createFormContext<T>() {
    return createContext<ContextTypes<T>>(DefaultContextData);
}

export const FormContext = createContext(DefaultContextData);

export default FormContext;
