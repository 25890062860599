import { useContext, useEffect } from "react";
import { KeysMatching, useValidator, Validator } from "..";
import FileUpload, { FileUploadProps } from "../../Form/FileUpload/FileUpload";
import SchemaFormContext from '../SchemaFormContext';

export interface SchemaFileUploadProps<T extends object> extends Omit<FileUploadProps, "value" | "error"> {
    for: KeysMatching<T, FileList|undefined>;
    validate?: Validator<FileList> | Validator<FileList>[];
};

export function SchemaFileUpload<T extends object>({"for": key, validate, ...props}: SchemaFileUploadProps<T>)
{
    const context = useContext(SchemaFormContext);
    const value = context.getValue(key);
    const error = context.getError(key)

    const validator = useValidator(validate || [], []);

    useEffect(() => {
        context.register(key, !!props.required, validator);
    }, [key, props.required, validator]);

    useEffect(() => {
        context.validateSelf(key);
    }, [value]);

    const onBlur = () => {
        context.onBlur(key);
        props.onBlur && props.onBlur();
    }

    const onChange = (value?: FileList) => {
        context.setValue(key, value);
        props.onChange && props.onChange(value);
    }

    return (
        <FileUpload {...props}
            error={error}
            onChange={onChange}
            onBlur={onBlur}
        />
    );
}
