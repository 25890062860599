import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import userReducer from './userSlice';
import sessionReducer from './sessionSlice';
import organisationReducer from './organisationSlice';
import planReducer from './planSlice';
import productReducer from './productSlice';
import templateReducer from './templateSlice';
import auditLogReducer from './auditLogSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    session: sessionReducer,
    organisation: organisationReducer,
    plan: planReducer,
    product: productReducer,
    template: templateReducer,
    auditLog: auditLogReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
