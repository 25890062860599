import styles from './HeaderIcon.module.scss';
import { SystemicLogo } from '@application-production-system/react';

function HeaderIcon() {
    return (
        <div className={styles['header-icon']}>
            <SystemicLogo width={35} />
        </div>
    );
}

export default HeaderIcon;
