import { CSSProperties, useContext, useState } from 'react';
import useClasses from '../../../utility/useClasses';
import TableContext from '../TableContext';
import styles from './HeaderCell.module.scss';
import Icon from '../../Icon/Icon';

export interface HeaderCellProps {
    children?: React.ReactNode;
    align?: 'left' | 'center' | 'right';
    position?: 'top' | 'middle' | 'bottom';
    sortKey?: string | number;
    style?: CSSProperties;
    divider?: boolean;
    colspan?: number;
}

const flexLookup = {
    left: 'flex-start',
    top: 'flex-start',
    center: 'center',
    middle: 'center',
    right: 'flex-end',
    bottom: 'flex-end'
}

function HeaderCell({
        children,
        align = 'left',
        position = 'top',
        sortKey,
        style,
        divider,
        colspan=1,
    }: HeaderCellProps
) {
    const { sortTable, currentSortKey } = useContext(TableContext);
    const [ reversed, setReversed ] = useState(false);

    const isSorted = () => {
        return sortKey == currentSortKey;
    }

    const canSort = () => {
        return sortKey != undefined;
    }

    const sort = () => {
        if (sortKey == undefined) {
            return;
        }

        if (isSorted()) {
            setReversed(!reversed);
            sortTable(sortKey, !reversed);
        }
        else {
            setReversed(false);
            sortTable(sortKey, false);
        }
    }

    const clickable = useClasses(styles, {
        sortable: canSort()
    });

    const cellStyle: CSSProperties = {
        alignItems: flexLookup[position],
        justifyContent: flexLookup[align],
        ...style,
    };

    const classes = useClasses(styles, {
        cell: true,
        divider: divider,
    });

    return (
        <th className={classes} style={cellStyle} colSpan={colspan}>
            <div className={styles['row']}>
                <div style={{flex: 1}}>
                    {children}
                </div>
                {canSort() &&
                    <div className={clickable} style={{position: "relative", marginLeft: 16}} onClick={sort}>
                        <div className={styles["sort-button"]}>
                            <div style={{position: 'absolute'}}>
                                <Icon icon={['fas', 'sort']}></Icon>
                            </div>
                        </div>
                        { isSorted() &&
                            <div className={styles["sort-button-active"]}>
                                <div style={{position: 'absolute'}}>
                                    <Icon icon={['fas', reversed ? 'sort-down' : 'sort-up']}></Icon>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </th>
    );
}

export default HeaderCell;
