import { library } from '@fortawesome/fontawesome-svg-core';

import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

const iconPacks = {
    fas,
    far,
    fal,
    fad,
    fab
};

export type AvailableIconPacks = keyof typeof iconPacks;

export function loadIcons(iconLibrary: AvailableIconPacks[] | AvailableIconPacks) {
    if (Array.isArray(iconLibrary)) {
        iconLibrary.forEach(iconPack => {
            library.add(iconPacks[iconPack]);
        });
    } else {
        library.add(iconPacks[iconLibrary]);
    }
}
