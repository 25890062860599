import styles from "./LogoView.module.scss"

interface LogoViewProps {
    hasLogo: boolean;
    url?: string;
    emptyMsg?: string;
}

export function LogoView({hasLogo, url, emptyMsg="No logo"}: LogoViewProps) {
    let logo;

    if (hasLogo) {
        logo = <img className={styles.logo} src={url} />;
    }
    else {
        logo = <div className={styles.logo}>{emptyMsg}</div>;
    }

    return logo;
}
