import { isEmail, Modal, Button, maxFileSize, createForm, CardContainer } from '@application-production-system/react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserDetails, UsersService, UserResponse, DocAccessKeyResponse} from '../../api/doc';
import { ServiceWrapper } from '../../services/DocServiceWrapper';
import { DetailTable, Loader } from '../../components';
import { resetPassword } from '../../api/override/doc';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { selectUser, updateUser } from '../../store/userSlice';
import { selectFormatProduct } from '../../store/productSlice';
import { selectOrganisation } from '../../store/organisationSlice';
import styles from './UserDetailsPage.module.scss'
import { LogoView } from '../../components/LogoView/LogoView';
import { IdPageParams, ROUTES } from '../../routes';
import { OpenAPI as docOpenApi } from "../../api/doc/core/OpenAPI";
import { Page } from '../../components/Page/Page';

interface ResetPasswordForm {
    email: string;
    resetPasswordViaUrl: boolean;
}

interface ChangeAvatarForm {
    userAvatar: FileList;
}

const AvatarForm = createForm<ChangeAvatarForm>();
const PasswordForm = createForm<ResetPasswordForm>();

function UserDetailsPage() {
    const navigate = useNavigate();

    const { id } = useParams<IdPageParams>();

    const [avatarModalVisible, setAvatarModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const user = useAppSelector((state) => selectUser(state, id));
    const organisation = useAppSelector((state) => selectOrganisation(state, user?.organisation));
    const product = useAppSelector((state) => selectFormatProduct(state, user?.productCode));

    const dispatch = useAppDispatch();

    const enableAccount = () => {
        ServiceWrapper<UserResponse>(UsersService.enableUser, [user.id], (response) => {
            dispatch(updateUser({id: user.id, newDetails: response.user}));
        });
    };

    const disableAccount = () => {
        ServiceWrapper<UserResponse>(UsersService.disableUser, [user.id], (response) => {
            dispatch(updateUser({id: user.id, newDetails: response.user}));
        });
    };

    const deleteAccount = () => {
        UsersService.deleteUser(id!).then(response => {
            console.log(response);
            if (response.succeeded) {
                navigate('/users');
            }
        });
    };

    const generateToken = () => {
        ServiceWrapper<DocAccessKeyResponse>(UsersService.regenerateUserAccessKey, [user.id], (response) => {
            const newDetails: UserDetails = {...user, accessKey: response.accessKey};
            dispatch(updateUser({id: user.id, newDetails: newDetails}));
        });
    };

    const [ modalVisible, setModalVisible ] = useState(false);

    const handleModalAction = (action: string) => {
        setModalVisible(false);
        if (action === "Confirm") {
            disableAccount();
        }
    }

    const [passwordFormData, setPasswordFormData] = useState<ResetPasswordForm>({} as ResetPasswordForm);

    const onSubmitResetPassword = () => {
        if (!passwordFormData.email) {
            passwordFormData.email = user.email!;
        }
        resetPassword(passwordFormData)
        .then(response => {
            alert("Successful, response = " + response);
        })
        .catch(error => {
            if (error.status == 403) {
                console.error(error);
                if (error.status == 403) {
                    alert("Insufficient permission");
                }
                else if (error.status == 404) {
                    alert("User does not exist");
                }
                else if (error.status == 500) {
                    alert("Failed to reset the password");
                }
                else {
                    alert("Something went wrong");
                }
            }
        });
    }

    const onClick = (key: string) => {
        switch (key) {
            case "Product":
                if (product) {
                    navigate(ROUTES.productDetails.build({id: user.productCode}));
                }
                break;
            case "Organisation":
                if (organisation) {
                    navigate(ROUTES.organisationDetails.build({id: organisation.id}));
                }
                break;
        }
    }

    const [avatarFormData, setAvatarFormData] = useState<ChangeAvatarForm>({} as ChangeAvatarForm);

    const onSubmitAvatar = () => {
        UsersService.uploadAvatar(id!, {avatar: avatarFormData.userAvatar[0]}).then(response => {
            dispatch(updateUser({id: id!, newDetails: response.user!}));
        });;
    };

    const deleteAvatar = () => {
        UsersService.deleteAvatar(id!).then(response => {
            if (response.succeeded) {
                dispatch(updateUser({
                    id: id!,
                    newDetails: {...user, avatar: undefined}
                }));
            }
        });
    }

    const hasAvatar = !!user?.avatar;
    const avatarUrl = docOpenApi.BASE + '/api/docsvc/v2/users/' + user?.id + '/avatar';

    return !user ? <Loader/> : (
        <Page title={{page: "User Details", icon: ["far", "user"]}}>
            <div>
                <Modal
                    title="Confirm Disable User"
                    status="danger"
                    visible={modalVisible}
                    onClose={() => handleModalAction("Cancel")}
                    actions={["Confirm", "Cancel"]}
                    actionReducer={handleModalAction}
                >
                    <p>Are you sure you want to disable {user.firstName}'s account?</p>
                </Modal>

                <div style={{maxWidth: "800px"}}>
                    <h2>User Details</h2>
                    <DetailTable onClick={onClick} clickable={[product && "Product", organisation && "Organisation"]} details={{
                        "First Name": user.firstName,
                        "Last Name": user.lastName,
                        "Email": user.email,
                        "Login": user.userId,
                        "Access Key": user.accessKey,
                        "ID": user.id,
                        "Organisation": organisation ? organisation.name : user.organisation,
                        "Role": user.roles![0],
                        "Product": product ? product : user.productCode,
                        "Account Status": user.enabled
                            ? <span style={{color: "green"}}>Enabled</span>
                            : <span style={{color: "red"}}>Disabled</span>
                    }}/>

                    <div style={{display: "flex", marginBottom: 50}}>
                        <div style={{marginRight: 'auto', marginTop: "10px", display: "flex", flexDirection: 'row', gap: '10px'}}>
                            <Button title="Delete"
                                    type="button"
                                    variant="danger"
                                    icon={['far', 'trash']}
                                    iconPosition="left"
                                    onClick={() => setDeleteModalVisible(true)}
                                    style={{display: !user.enabled ? 'flex' : 'none'}} />
                        </div>
                        <div style={{marginLeft: "auto", marginTop: "10px"}}>
                            <Button
                                title="Edit"
                                icon={['fas', 'edit']}
                                iconPosition="left"
                                onClick={() => {
                                    navigate(ROUTES.editUser.build({id: user.id}));
                                }}
                            />
                        </div>
                        <div style={{marginLeft: "10px", marginTop: "10px"}}>
                            <Button
                                title="Stats"
                                icon={['fas', 'chart-bar']}
                                iconPosition="left"
                                onClick={() => navigate(ROUTES.userStats.build({id: id}))}
                            />
                        </div>
                        <div style={{marginLeft: "10px", marginTop: "10px"}}>
                            <Button
                                title="Generate Token"
                                icon={['far', 'cube']}
                                iconPosition="left"
                                onClick={generateToken}
                            />
                        </div>
                        <div style={{marginLeft: "10px", marginTop: "10px"}}>
                            <Button
                                title="Reset Password"
                                icon={['far', 'question']}
                                iconPosition="left"
                            />
                        </div>
                        <div style={{marginLeft: "10px", marginTop: "10px"}}>
                            { user.enabled ?
                                <Button
                                    title="Disable"
                                    variant="danger"
                                    icon={['far', 'times-circle']}
                                    iconPosition="left"
                                    onClick={() => setModalVisible(true)}
                                />
                            :
                                <Button
                                    title="Enable"
                                    icon={['far', 'check-circle']}
                                    iconPosition="left"
                                    onClick={enableAccount}
                                />
                            }
                        </div>
                    </div>

                    <div style={{marginBottom: 50}}>
                        <AvatarForm.Form onSubmit={onSubmitAvatar} data={avatarFormData} setData={setAvatarFormData}>
                            <CardContainer title="Avatar">

                                <div className={styles.avatarRow}>
                                    <LogoView hasLogo={hasAvatar} url={avatarUrl} emptyMsg="No avatar" />
                                </div>
                                <AvatarForm.FileUpload for="userAvatar"
                                            required
                                            validate={[maxFileSize(20, "MB")]}
                                            accept="image/*" />
                                <div className={styles.buttonRow}>
                                    <Button title="Delete"
                                            type="button"
                                            variant="danger"
                                            onClick={() => setAvatarModalVisible(true)}
                                            disabled={!hasAvatar} />
                                    <Button title="Submit" type="submit" />
                                </div>
                            </CardContainer>
                        </AvatarForm.Form>
                    </div>

                    <CardContainer title="Reset Password">
                        <PasswordForm.Form onSubmit={onSubmitResetPassword} data={passwordFormData} setData={setPasswordFormData}>
                            <PasswordForm.Entry for="email" label="Email (Leave blank to use user's email)" validate={isEmail}/>
                            <PasswordForm.Checkbox for="resetPasswordViaUrl" label="Reset Via URL"/>
                            <div style={{display: "flex", justifyContent: 'right', marginTop: 25}}>
                                <Button title="Submit" type="submit"/>
                            </div>
                        </PasswordForm.Form>
                    </CardContainer>
                </div>
            </div>
            <Modal title="Confirm delete avatar"
                   status="danger"
                   visible={avatarModalVisible}
                   onClose={() => setAvatarModalVisible(false)}
                   actions={["Confirm", "Cancel"]}
                   actionReducer={(action) => {
                       if (action == "Confirm") {
                           deleteAvatar();
                       }
                       setAvatarModalVisible(false);
                   }}>
                Are you sure you want to delete this user's avatar?
            </Modal>
            <Modal title="Confirm delete user"
                   status="danger"
                   visible={deleteModalVisible}
                   onClose={() => setDeleteModalVisible(false)}
                   actions={["Confirm", "Cancel"]}
                   actionReducer={(action) => {
                       if (action === "Confirm") {
                           deleteAccount();
                       }
                       setDeleteModalVisible(false);
                   }}>
                Are you sure you want to delete this user? (This cannot be undone!)
            </Modal>
        </Page>
    );
}

export default UserDetailsPage;
