import styles from './HeaderContent.module.scss';

interface HeaderContentProps {
    version?: string;
    name?: string;
}

function HeaderContent({ version, name }: HeaderContentProps) {
    return (
        <div className={styles['header-content']}>
            <div>
                <div>{name}</div>
                <div>{version}</div>
            </div>
        </div>
    );
}

export default HeaderContent;
